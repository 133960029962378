<template>
  <section>
    <div v-if="pageLoaded" class="container-fluid">
      <div class="pricing-table__wrapper">
        <div class="selector-wrapper">
          <p class="mb-4">How many contacts do you have?</p>
          <SliderSelector
            @setSubscriberCount="setSubscriberCount"
            :count="subscriberCount"
            :step="1"
            :min="0"
            :max="markers.length - 1"
          ></SliderSelector>
        </div>
        <div class="toggles-container mb-2">
          <ToggleSwitch
            optionOne="USD"
            optionTwo="GBP"
            :value="currency"
            :onSwitch="toggleCurrency"
          ></ToggleSwitch>
          <ToggleSwitch
            optionOne="annually"
            optionTwo="monthly"
            :value="frequency"
            :onSwitch="toggleFrequency"
          ></ToggleSwitch>
        </div>
        <div class="pricing-tiers__wrapper">

          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Starter"
            plan-description="Perfect for those who need basic features to send occasional emails and build their first subscriber lists."
            :price="getPlanPrice(2)"
            :isDisabled="isPlanDisabled(2)"
            :limitations="['3 Users', calculateEmailSends(2) + ' Email Sends']"
            :features="[
              '3 reply tracking mailboxes',
              '1 Automation',
              '1 Automation Condition',
              'Web Tracking for 1 site',
              'RSS Feed Dynamic Sending'
            ]"
          ></PricingCard>

          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Growth"
            plan-description="For those who need more advanced features and analytics to reach a wider audience and drive engagement."
            :price="getPlanPrice(3)"
            :isDisabled="isPlanDisabled(3)"
            :limitations="['5 Users',calculateEmailSends(3) + ' Email Sends']"
            :features="[
              '5 reply tracking mailboxes',
              'Unlimited Automations',
              '5 Automation Conditions',
              'Web Tracking for 5 sites', 
              '3 Social Campaigns',
              'Custom Template Blocks',            
              'Email Split Tests'
            ]"
          ></PricingCard>

          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Advanced"
            plan-description="Offering advanced tools, automation, and segmentation capabilities to optimise campaigns and maximise ROI."
            :price="getPlanPrice(6)"
            :isDisabled="isPlanDisabled(6)"
            :limitations="['10 Users', calculateEmailSends(6) + ' Email Sends']"
            :features="[
              '15 reply tracking mailboxes',
              'Unlimited Automations',
              '15 Automation Conditions',
              'Web Tracking for 10 sites',
              '10 Social Campaigns',
              '1 Conversations Inbox',
              'Web Chat'
            ]"
          ></PricingCard>

          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Ultimate"
            plan-description="For companies with high-volume email, social, and and marketing requirements with large contact bases."
            :price="getPlanPrice(4)"
            :isDisabled="isPlanDisabled(4)"
            :customPricing="isPlanDisabled(4)"
            :limitations="['Unlimited Users', calculateEmailSends(4) + ' Email Sends']"
            :features="[
              'Unlimited reply mailboxes',
              'Unlimited Automations',
              'Unlimited Automation Conditions',
              'Unlimited Web Tracking',
              'Unlimited Social Campaigns',
              'Unlimited Conversations Inboxes',
              'Web Chat AI Assistant'
            ]"
          ></PricingCard>
        </div>

        <div class="pricing-card pricing-card-freeplan col-xs-12">
          <div class="pricing-card-freeplan_left">
            <h2 class="h5 mb-1">Free</h2>
            <div class="p--sm">
              For those dipping their toe into email marketing with up to 250 contacts and 1 user
            </div>
          </div>
          <div class="pricing-card-freeplan_right">
            <button
              v-on:click="isPlanDisabled('Free') ? null : TrackingHelpers.linkToApp('register?welcome=true')"
              class="btn btn--secondary btn--sm "
              style="margin: 0"
              :disabled="isPlanDisabled('Free')"
            >
              {{ isPlanDisabled('Free') ? 'Plan Unavailable' : 'Get Started' }}
            </button>
          </div>
        </div>
        <!--
        <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Free"
            :price="0"
            :isDisabled="isPlanDisabled('Free')"
            plan-description="All of the basics you need to get started."
            :limitations="['Up to 1 User Login']"
            :features="['Up to 250 Contacts','Up to 1 reply tracking mailbox']"
          ></PricingCard>-->
      </div>
    </div>
  </section>
</template>

<script>
import PricingCard from "./PricingCard";
import ToggleSwitch from "./ToggleSwitch";
import SliderSelector from "./SliderSelector";
import { getPricingData } from "../../dataRequest";
import TrackingHelpers from "@/functions/tracking";
export default {
  //used for ref above
  components: { PricingCard, ToggleSwitch, SliderSelector },
  data() {
    return {
      pricePlans: [],
      currency: "GBP",
      currencySymbol: "£",
      frequency: "annually",
      perFreq: "year",
      subscriberCount: 0,
      pricingLoaded: false,
      isDisabled: false,
      customPricing: false,
      markers: [],
      pageLoaded: false
    };
  },
  ///component has mounted
  created() {
    getPricingData().then((data) => {
      //contact limit, tiers available
      let flattenedPrices = [
        {contactLimit: 250,tiers: [{tier: 0, priceGbp: 0, priceUsd: 0}]}
      ] 
      data.forEach((tier)=>{
        tier.prices.forEach((price)=>{
          if(price.maxUniqueSends > 220000) return;
          const existing = flattenedPrices.find((p)=>p.contactLimit === price.maxUniqueSends);
          if(!existing){
            flattenedPrices.push({contactLimit: price.maxUniqueSends, tiers: [{tier: tier.id, priceGbp: price.monthly.priceGbp, priceUsd: price.monthly.priceUsd}]});
          }
          else
          {
            existing.tiers.push({tier: tier.id, priceGbp: price.monthly.priceGbp, priceUsd: price.monthly.priceUsd});
          }
        })
      })
      //sort by contact limit
      flattenedPrices = flattenedPrices.sort((a,b)=>a.contactLimit - b.contactLimit);
      this.subscriberCount = flattenedPrices[0].contactLimit;
      this.markers = flattenedPrices.map((p)=>p.contactLimit);
      this.pricePlans = data;
      this.pageLoaded = true;
    });
  },
  setup()
  {
    return { TrackingHelpers }
  },
  //functions
  methods: {
    calculateEmailSends(planId){
      let multiplier = 10;
      if(planId == 2) multiplier = 10;
      if(planId == 3) multiplier = 10;
      if(planId == 6) multiplier = 15;
      if(planId == 4) multiplier = 20;
      const pDisabled = this.isPlanDisabled(planId);
      let calcContacts = this.subscriberCount;
      if(pDisabled) {
        //get the max number of contacts in the tier
        calcContacts = this.pricePlans.find((p)=>p.id === planId).prices[this.pricePlans.find((p)=>p.id === planId).prices.filter(f=>f.maxUniqueSends < 200001).length - 1].maxUniqueSends;
      }
      const newVal = (multiplier * calcContacts).toLocaleString();
      return newVal;

    },
    toggleCurrency() {
      this.currency = this.currency === "GBP" ? "USD" : "GBP";
      this.currencySymbol = this.currencySymbol === "£" ? "$" : "£";
    },
    toggleFrequency() {
      this.frequency = this.frequency === "monthly" ? "annually" : "monthly";
      this.perFreq = this.perFreq === "month" ? "year" : "month";
    },
    setSubscriberCount(count) {
      this.subscriberCount = this.markers[count];
    },
    getPlanPrice(id) {
      const plan = this.pricePlans.find((p) => p.id === id);
      if (!plan) return -1;
      let planPrices = plan.prices
        .slice()
        .sort((a, b) => a.maxUniqueSends - b.maxUniqueSends);
      let price = planPrices.find(
        (p) => p.maxUniqueSends >= this.subscriberCount
      );
      if (!price) {
        price = planPrices[planPrices.length - 1];
      }
      const currencyKey = this.currency === "USD" ? "priceUsd" : "priceGbp";
      return price[this.frequency][currencyKey];
    },
    isPlanDisabled(id) {
      const aboveFreeSubscriberLimit = this.subscriberCount > 250;
      if (aboveFreeSubscriberLimit) {
        const plan = this.pricePlans.find((p) => p.id === id);
        if (!plan) return -1;
        let price = plan.prices.find(
          (p) => p.maxUniqueSends >= this.subscriberCount
        );
        if (this.subscriberCount > 219999) {
          return (this.customPricing = true);
        }
        if (!price) {
          return (this.isDisabled = true);
        }
      }
    },
    goRegister() {
      TrackingHelpers.linkToApp('register?welcome=true');
    },
  },
};
</script>
