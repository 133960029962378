<template>
  <svg class="logo logo--mark" :class="[getClass, colorThemeClass]" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.251 2.66667L6.61659 5.33333L2 2.66667L6.61659 0L11.251 2.66667Z"/>
    <path d="M15.886 5.33335L11.2516 8.00002L6.61719 5.33335L11.2516 2.66669L15.886 5.33335Z"/>
    <path d="M20.5198 7.99998L15.8854 10.6666L11.251 7.99998L15.8854 5.33331L20.5198 7.99998Z"/>
    <path d="M25.1536 10.6667L20.5192 13.3333L15.8848 10.6667L20.5192 8L25.1536 10.6667Z"/>
    <path d="M29.7705 13.3333L25.1539 16L20.5195 13.3333L25.1539 10.6666L29.7705 13.3333Z"/>
    <path d="M20.5192 18.6667V24L15.8848 26.6667V21.3334L20.5192 18.6667Z"/>
    <path d="M29.7709 13.3334V18.6667L25.1543 21.3334V16L29.7709 13.3334Z"/>
    <path d="M11.2516 24V29.3333L6.61719 32V26.6667L11.2516 24Z"/>
    <path d="M11.251 13.3333L6.61659 16L2 13.3333L6.61659 10.6666L11.251 13.3333Z"/>
    <path d="M15.886 16L11.2516 18.6667L6.61719 16L11.2516 13.3334L15.886 16Z"/>
    <path d="M20.5198 18.6667L15.8854 21.3333L11.251 18.6667L15.8854 16L20.5198 18.6667Z"/>
    <path d="M6.61659 0V5.33333L2 8V2.66667L6.61659 0Z"/>
    <path d="M6.61659 10.6666V16L2 18.6666V13.3333L6.61659 10.6666Z"/>
    <path d="M11.251 24L6.61659 26.6666L2 24L6.61659 21.3333L11.251 24Z"/>
    <path d="M6.61659 21.3333V26.6666L2 29.3333V24L6.61659 21.3333Z"/>
  </svg>
</template>
<script>
export default {
  name: "LogoMark",
  props: {
    class: {
      type: String
    },
    theme: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {
      colorThemeClass: "",
    }
  },
  mounted() {
    switch(this.theme){
      case "white":
        this.colorThemeClass = "logo--" + "white";
      break;
      case "purple":
        this.colorThemeClass = "logo--" + "purple";
      break;
      default: 
        this.colorThemeClass = "logo--" + "black";
      break
    }
  },
  computed: {
    getClass() {
      return this.class;
    }
  },
  methods: {

  }
}
</script>
