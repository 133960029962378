<template>
  <div>
    <nav :class="['nav', 'nav-container', { 'nav--dark': theme === 'dark' }]">
      <div class="nav__links-container nav__links-container--ppc">
        <router-link to="/">
          <LogoFull
            class="nav__logo"
            :theme="theme == 'dark' ? 'white' : ''"
          ></LogoFull>
        </router-link>

        <div class="nav__links nav__links--ppc">
          <div class="nav__link">
            <a
              href="/pricing"
              class="btn btn--primary btn--sm nav__link--btn"
              title="Try free"
            >
              Try free
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import LogoFull from "@/components/Svgs/Logo/LogoFull";

export default {
  name: "NavigationComponent",
  components: {
    LogoFull
  },
  props: {
    theme: {
      type: String,
    },
  },
};
</script>