<template>
  <div>
    <nav :class="['nav', 'nav-container', { 'nav--dark': theme === 'dark' }]">
      <div class="nav__links-container">
        <router-link to="/">
          <LogoFull
            class="nav__logo"
            :theme="theme == 'dark' ? 'white' : ''"
          ></LogoFull>
        </router-link>
        <ul class="nav__links mobile-nav-element">
          <li v-on:click="toggleMegaMenu('features', $event)" class="nav__link">
            <span class="nav__link-text">
              Features
              <span
                :class="[
                  'nav__link-arrow-container',
                  {
                    active: activeMenu === 'features',
                  },
                ]"
              >
                <Chevron class="nav__link-arrow"></Chevron>
              </span>
            </span>
          </li>

          <li>
            <router-link class="nav__link" to="/pricing" title="Pricing">
              Pricing
            </router-link>
          </li>
          <li>
            <router-link
              class="nav__link"
              to="/integrations"
              title="Integrations"
            >
              Integrations
            </router-link>
          </li>
          <li
            v-on:click="toggleMegaMenu('resources', $event)"
            class="nav__link"
          >
            <span class="nav__link-text">
              Resources
              <span
                :class="[
                  'nav__link-arrow-container',
                  {
                    active: activeMenu === 'resources',
                  },
                ]"
              >
                <Chevron class="nav__link-arrow"></Chevron>
              </span>
            </span>
          </li>
          <span class="nav__secondary-links--mobile">
            <li class="nav__link--btn">
              <a
                href="/pricing"
                class="btn btn--primary btn--sm nav__link--btn"
                title="Sign Up"
              >
                Get started
              </a>
            </li>
            <li>
              <a
                v-on:click="TrackingHelpers.linkToApp()"
                class="nav__link"
                title="Log in"
              >
                Log in!
              </a>
            </li>
          </span>
        </ul>
        <div class="nav__links">
          <a
            class="nav__link"
            v-on:click="TrackingHelpers.linkToApp()"
            title="Log in"
          >
            Log in
          </a>
          <div class="nav__link">
            <a
              href="/pricing"
              class="btn btn--primary btn--sm nav__link--btn"
              title="Sign Up"
            >
              Get started
            </a>
          </div>
        </div>
      </div>
      <div class="hamburger mobile-nav-element" v-on:click="toggleMobileNav()">
        <span class="hamburger__layer hamburger__layer--top"></span>
        <span class="hamburger__layer hamburger__layer--middle"></span>
        <span class="hamburger__layer hamburger__layer--bottom"></span>
      </div>
    </nav>

    <span
      v-on:click="resetMenuState"
      :class="[
        {
          'mega-menu__overlay': activeMenu !== '',
        },
        'mobile-nav-element',
        'mobile-menu__overlay',
      ]"
    ></span>
    <FeaturesMenu :class="{ 'mega-menu--show': activeMenu === 'features' }" />
    <ResourcesMenu :class="{ 'mega-menu--show': activeMenu === 'resources' }" />
    <span
      v-on:click="resetMenuState"
      :class="{
        'mega-menu__overlay': activeMenu !== '',
      }"
    ></span>
    <footer class="mobile-nav-element navigation__mobile-footer p-4">
      <div class="row between-sm">
        <div class="col-xs-12 col-sm-4 mb-4 sm:mb-0">
          <div class="row">
            <router-link class="footer__social-link" to="/terms">
              <TwitterIcon class="footer__social-icon"></TwitterIcon>
            </router-link>
            <router-link class="footer__social-link" to="/terms">
              <FacebookIcon class="footer__social-icon"></FacebookIcon>
            </router-link>
            <router-link class="footer__social-link" to="/terms">
              <InstagramIcon class="footer__social-icon"></InstagramIcon>
            </router-link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="row start-xs end-sm">
            <div class="footer__legal mb-4 mr-4 sm:mb-0">
              © 2022 Transpond. All Rights Reserved.
            </div>
            <div class="footer__legal-links">
              <router-link class="footer__legal-link" to="/terms">
                Terms
              </router-link>
              <router-link class="footer__legal-link" to="/privacy">
                Privacy
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ResourcesMenu from "@/components/navigation/megamenu/Resources";
import FeaturesMenu from "@/components/navigation/megamenu/Features";
import LogoFull from "@/components/Svgs/Logo/LogoFull";
import Chevron from "@/components/Svgs/Icons/chevron";
import TwitterIcon from "../Svgs/TwitterIcon";
import FacebookIcon from "../Svgs/FacebookIcon";
import InstagramIcon from "../Svgs/InstagramIcon";
import TrackingHelpers from "@/functions/tracking";

export default {
  name: "NavigationComponent",
  components: {
    LogoFull,
    Chevron,
    ResourcesMenu,
    FeaturesMenu,
    TwitterIcon,
    FacebookIcon,
    InstagramIcon,
  },
  setup() {
    return { TrackingHelpers };
  },

  props: {
    theme: {
      type: String,
    },
    announcementText: {
      type: String,
    },
    announcementLink: {
      type: Array,
    },
    announcementIcon: {
      type: String,
    },
  },
  data() {
    return {
      activeMenu: "",
      activeMobileNavElements: [],
    };
  },

  computed: {
    isAIPage() {
      return this.$route.path === "/ai-content-assistant";
    },
  },

  methods: {
    resetMenuState() {
      this.activeMenu = "";
      if (this.activeMobileNavElements) {
        this.activeMobileNavElements.forEach((elem) =>
          elem.classList.remove("active")
        );
      }
    },
    toggleMegaMenu(menu) {
      if (this.activeMenu === menu) {
        this.activeMenu = "";
      } else {
        this.activeMenu = menu;
      }
    },

    //Mobile navigation
    setActiveMobileNavElements() {
      this.activeMobileNavElements = Array.from(
        document.getElementsByClassName("mobile-nav-element")
      );
    },
    toggleMobileNav() {
      this.activeMenu = "";
      this.setActiveMobileNavElements();
      this.activeMobileNavElements.forEach((elem) =>
        elem.classList.toggle("active")
      );
    },
  },
};
</script>
