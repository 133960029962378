<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeader
      title="Take your<br/>marketing further"
      subtitle-text="Transpond is the perfect way to power up your marketing. Build beautiful, engaging communications, automate your processes and measure the output with ease. Transpond brings a wide range of powerful features to your fingertips."
      class="mb-0 sm:mb-0"
    >
      <template v-slot:pageLinks>
        <PageHeaderFeatureLinks
          :features="[
            { highlight: 'Create Campaigns', link: '/features#campaigns' },
            { highlight: 'Build Automations', link: '/features#automations' },
            { highlight: 'Get Insights', link: '/features#insights' },
          ]"
        ></PageHeaderFeatureLinks>
      </template>

      <template v-slot:image>
        <PageHeaderImage image="feature-hero"></PageHeaderImage>
      </template>
    </PageHeader>

    <SectionTwoColumnRight
      id="campaigns"
      title="Campaigns and Template Builder"
      content="Create impactful and responsive email templates with Transpond's intuitive drag and drop email template editor. It's easy to design emails from scratch or create one using one of our pre-built templates as a starting point."
      image="campaign-builder"
      button-text="Learn more"
      button-link="CampaignBuilder"
    ></SectionTwoColumnRight>

    <SectionOneColumnCenter
      id="automations"
      title="Automations"
      content="Tailor a range of automations to suit your business. Need something simple or more advanced? No problem! The Transpond automation builder lets you create triggers and sequences for communications so that you can manage every interaction that you need to, like onboarding new customers or delighting them on their Birthday."
      theme="dark"
      image="automation-examples"
      :smallImg="true"
      button-text="Learn more"
      button-link="Automations"
    ></SectionOneColumnCenter>

    <SectionOneColumnLeft
      id="insights"
      title="Insightful Analytics"
      content="See the impact of your marketing in real time as your subscribers open, click and engage with communications. You'll also be able to see where they are in the world, and what device they're using so you can create truly tailored campaigns."
      :overlay="true"
      image="dashboard"
      :rounded="true"
      height="756"
      button-text="Learn more"
      button-link="Analytics"
    ></SectionOneColumnLeft>

    <SectionAccordion></SectionAccordion>

    <SectionTwoColumnRight
      title="Transactional Email"
      content="Set up emails to be sent directly from your website or app for onboarding, order confirmations and much more. Transpond's transaction emails are easy to set up, and deliver a full set of analytics to let you see what's going on."
      image="transaction-email"
      button-text="Learn more"
      button-link="TransactionalEmails"
    ></SectionTwoColumnRight>
  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeader from "@/components/Header/PageHeader";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionOneColumnCenter from "@/components/ContentSections/SectionOneColumnCenter";
import SectionAccordion from "@/components/Accordion/SectionAccordion";
import SectionOneColumnLeft from "@/components/ContentSections/SectionOneColumnLeft";
import PageHeaderFeatureLinks from "@/components/Header/PageHeaderFeatureLinks";
import PageHeaderImage from "@/components/Header/PageHeaderImage";

export default {
  name: "FeaturesPage",
  components: {
    FeaturePageLayout,
    PageHeader,
    SectionTwoColumnRight,
    SectionOneColumnCenter,
    SectionOneColumnLeft,
    SectionAccordion,
    PageHeaderFeatureLinks,
    PageHeaderImage
  },
  data() {
    return {
      meta: {
        title: "Marketing features - Transpond",
        description:
          "Email Campaigns, Automation, Transactional Emails, Data Capture, and more. Find out how Transpond can help your business.",
      },
    };
  },
  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
