<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <navigation></navigation>

  <PageHeader title="Customer Terms"> </PageHeader>

  <div class="container-fluid">
    <div class="row mb-30">
      <div class="col-xs-12 col-md-3 mb-20">
        <TermsNav></TermsNav>
      </div>
      <div class="col-xs-12 col-md-6 col-md-offset-1">
        <div class="longform">
          <p>
            Welcome to MPZMail Limited, a marketing solution designed for
            businesses, cooperates and organisations.
          </p>

          <p>
            Please read these customer terms of use (<strong
              >"Terms and Conditions"</strong
            >) carefully before accessing or using the Transpond Services
            (<strong>"Services"</strong>) provided through our website at
            <a href="https://transpond.io">https://transpond.io</a>
            (<strong>"Website"</strong>).
          </p>

          <p>
            The Website is operated by MPZMail Limited, a private limited
            company registered in England with company number 11234241 whose
            registered office is Level 1, 20 Dale Street, Manchester, M1 1EZ, UK
            (<strong>"we"</strong> or <strong>"us"</strong> or
            <strong>"our"</strong>). Our VAT number is 325273024. These Terms
            and Conditions apply to all Customers who access or use the Service.
          </p>
          <p>
            By clicking on the accept buttons relating to the
            <a href="/terms/">Terms and Conditions</a>,
            <a href="/acceptableuse">Acceptable Use Policy</a>
            (<strong>"AUP"</strong>),
            <a href="/Dpa">Data Processing Agreement</a> and
            <a href="/Privacy">Privacy Policy</a>, you agree to be legally bound
            by these Terms and Conditions, Acceptable Use Policy, Data
            Processing Agreement and Privacy Policy as they may be modified and
            posted on our Website from time to time. In these Terms and
            Conditions, <strong>"you"</strong> refers to the entity you
            represent (<strong>"Customer"</strong>).
          </p>

          <p>
            If you do not agree with these Terms and Conditions, Acceptable Use
            Policy, Data Processing Agreement and Privacy Policy, you may not
            use the Service.
          </p>

          <p>
            You may connect to the Service using an Internet browser supported
            by the Service. You are responsible for obtaining access to the
            Internet and any equipment necessary to use the Service.
          </p>

          <h3>Signup</h3>

          <p>
            The person signing up for an account will be automatically assigned
            as an administrator (<strong>"Administrator"</strong>), the Customer
            contact who has day to day responsibility for the Customer account.
            Your account is only for use by either a single legal entity (e.g. a
            company or a partnership) or an individual user. You must provide
            the Customer legal full name, address, a valid email address, and
            any other information requested in order to complete the signup
            process. The Customer acknowledges that, if the Customer allows a
            third party to open an account on their behalf or designates any of
            the third party's personnel as Administrators of the Customer's
            account, the third party will be able to control account
            information, including Customer Data, and access to the Customer's
            account.
          </p>

          <p>
            You may invite users to access your account
            (<strong>"Users"</strong>) by inviting them to register as a User.
            You confirm that all Users are 16 years old or older.
          </p>

          <p>
            You must not use this service if you are based in the following sanctioned jurisdictions; 
            Cuba, Iran, North Korea, Syria, Russia and the regions of Crimea, Zaporizhzhia, Kherson,
             Donetsk and Luhansk, or are not otherwise prohibited from using the Platform under the 
             laws and regulations of the United States, or any other applicable jurisdiction;
          </p>

          <p>
            You shall not upload or enter any personal data, revealing: (i)
            racial or ethnic origin, political opinions, religious or
            philosophical beliefs, trade-union membership; (ii) data concerning
            health or sex life or sexual orientation; or (iii) genetic data or
            biometric data, (<strong>"Sensitive Personal Data"</strong>) into
            the Service.
          </p>

          <h3>Term</h3>

          <p>
            Use of your account shall commence after signup and shall continue
            until terminated by either party in accordance with the provisions
            of these Terms and Conditions.
          </p>
          <h3>Between Customer, Users and Administrator</h3>
          <p>
            You are responsible for ensuring that all contact, email and name
            details used in connection with the Service are accurate and current
            during the term of this agreement. The Administrators control the
            day to day administration of the Customer account. The
            Administrators can cancel access to the Service, enable or disable
            third party integrations, manage permissions and export settings.
            These actions may result in changes to the access, use, disclosure,
            modification or deletion of certain or all Customer Data.
          </p>

          <p>
            Invited Users may access the Service and submit content or
            information (<strong>"Customer Data"</strong>) subject to the
            provisions of these Customer Terms. You shall ensure that all Users
            comply with these Customer Terms and you shall be liable for any
            breaches of the Customer Terms caused by any acts or omissions of
            Users as if the Customer had carried out such acts or omissions
            itself.
          </p>

          <p>
            Users are not permitted to share their username and password with
            any other person nor with multiple users on a network.
          </p>

          <p>
            You are responsible for any and all activities that occur in your
            account. You will immediately notify us if you become aware of any
            unauthorised use of your account, passwords or any breach of
            security. Where a User misuses your Account you are responsible for
            taking any remedial action to protect your account. The Customer may
            request our help in providing audit activity to help ascertain any
            breaches. We shall act with reasonable care in providing audit
            assistance but shall have no liability for any loss or damage
            arising as a result of providing such audit assistance
          </p>

          <p>
            If a person proven to be acting on behalf of the Customer asks us to
            replace a Administrator we will only make the change i. if there is
            only one Administrator on the Account and, ii. if we are lawfully
            obliged to do so i.e. by court order, or if requested by the
            Customer in circumstances where the Administrator cannot make the
            change i.e. in the event of their death, on us being provided with
            acceptable evidence of the Administrator's inability to make the
            change.
          </p>

          <h3>Customer Data</h3>

          <p>
            You own all content and data you add to your account and you retain
            sole ownership of all rights, title and interest in, and to, your
            Customer Data. You have the sole responsibility for the legality,
            reliability, integrity, accuracy and quality of your Customer Data.
          </p>

          <p>
            You grant us the perpetual right to use Customer Data in an
            anonymized format.
          </p>

          <p>
            We have no responsibility for the way in which you choose to use
            your account to store or process your Customer Data. In particular
            we maintain a directory, (<strong>"Transpond Integrations"</strong
            >), where some non-Transpond products are available for integration
            or you may choose to create your own integration via the application
            programme interface (<strong>"API"</strong>). We do not warrant or
            support these products, and you decide whether or not you wish to
            enable them. If you choose to use any third party integration and we
            become aware of excessive requests to your account via the API we
            reserve the right to suspend access to the API. We shall attempt to
            email you prior to suspension.
          </p>

          <p>
            If Customer Data is being passed from, or to, the Service through an
            API, we will not be responsible for any use, disclosure,
            modification or deletion of Customer Data that is transmitted, or
            accessed by, third party products.
          </p>

          <h3>Ordering, Fees, Invoicing and Payments</h3>

          <p>
            For paying accounts, the Customer agrees to provide us with a valid payment 
            card and authorises us to deduct payments of the recurring monthly or annual 
            fees, as published on our Website. The payment card must be registered in the 
            Customer’s name. Payment cards and payments are subject to validation and 
            authorisation checks of the card issuer. Customers will be billed monthly in
            advance starting on the date you sign up and there will be no refund or credit
            for partial months of Service. Fees are chargeable on the same day each month
            and shall be payable until an account is terminated or cancelled, even if you
            never use the Service.
          </p>
          <p>
            We reserve the right to modify the fees for any plan at any time after the 
            commencement of the Agreement, by giving the Customer at least 30 days prior notice.
            Annual fees will be increased from the renewal date, monthly fees from the next payment date.
          </p>
          <p>
            Where payment of any fee is not received within 14 days of the payment due date, we may, 
            without liability to the Customer, disable the Customer’s password, account and access 
            to all or part of the Services and we shall be under no obligation to provide any or all 
            of the Services while the invoice(s) concerned remains unpaid. 
          </p>
          <p>
            Fees are based upon your contact count. If your number of marketing contacts exceeds your 
            current contact count during the term, you will be automatically upgraded to the next relevant 
            contact count limit and you will receive an immediate prorated charge for the remaining period 
            of the month or year, calculated in accordance with the new contact count limit. Customers will 
            receive warning emails as they get close to their contact count limit, to warn them that they 
            may exceed their current contact limit with information about the charges that will then be 
            applied to their plan for the excess usage. If the number of marketing contacts in an account 
            goes below the current contact limit, the account will not automatically be downgraded to a lower plan. 
          </p>
          <p>
            All contacts, excluding “denylisted” and “hard bounced” contacts, on your Transpond account 
            will be chargeable and count towards your contact count limit. 
          </p>
          <p>
            Free accounts are not required to provide a payment card. If you upgrade from a free account 
            to a fee paying account there is no trial period. You will be charged for the first month 
            in advance immediately following any such upgrade.
          </p>
          <p>
            If you downgrade your account then you may lose content, features or capacity. We do not accept 
            any liability whatsoever for such losses.
          </p>
          <p>
            You can buy email credits to use the Service (“Pay as You Go Credits”), as explained in our app. 
            If you choose to buy Pay as You Go Credits, you will have access to the features of the Service 
            included in the Essentials Marketing package, as described on the pricing page. Pay as You Go 
            Credits cannot be refunded and represent a limited licence to use the Service for the specified 
            volume and type of service.
          </p>
          <p>
            All fees are exclusive of all taxes, charges, levies, assessments and other fees of any kind 
            imposed on use of the Service and such amounts shall be the responsibility of, and payable by, 
            the Customer. If the Customer’s place of business is within the UK we will add UK VAT to our fees 
            at the then current rate. If your place of business is in the EU (excluding the UK) and we are 
            not obliged to charge you VAT (due to current applicable law), we will only exclude VAT from 
            invoices if you provide us with a valid VAT registration number or other proof that you are 
            using the Service for business purposes.
          </p>

          <h3>Suspension & Locking Access</h3>

          <p>
            Only the Customer can ask us to lock all access to your account for
            any reason.
          </p>
          
          <p>
            We may suspend your access to the Service at any time, with or without cause. We will not refund 
            or reimburse any fees whilst your access to the Service is suspended
          </p>

          <p>
            We reserve the right to suspend access to your account if you fail
            to provide valid credit card details that enable us to charge the
            full amount of any outstanding fees and charges due or you fail to
            pay us any sums owed for the Service for any reason. In such circumstances, we will provide
            you with prior notice by email of our intention to suspend your
            Customer account. If, within 14 days of suspending your Customer
            account, the amount of any outstanding fees and charges is not paid
            to us in full, your Customer account will be cancelled.
          </p>

          <h3>Termination & Cancellation</h3>

          <p>
            We may terminate these Terms and Conditions immediately by giving
            you written notice if you or a User commit any breach of theseTerms
            and Conditions. We may terminate these Terms and Conditions for any
            other reason upon giving you 30 days written notice.
          </p>

          <p>
            Termination of these Terms and Conditions for any reason shall not
            affect the accrued rights of the parties arising under these Terms
            and Conditions and all rights which by their nature should survive
            the expiry or termination of these Terms and Conditions shall remain
            in full force and effect.
          </p>

          <p>
            You may cancel your Customer account with immediate effect by
            clicking the ‘Cancel Account' link from within your account settings
            page. Your recurring billing schedule will be ended upon
            cancellation. If you reactivate an Account after cancellation or
            suspension, you will be billed in accordance with the original
            billing cycle.
          </p>

          <p>
            Except for paying accounts, we reserve the right to cancel and
            delete your account if you haven't accessed the Service for more
            than 3 consecutive months. We will provide prior notice by email to
            the Administrator before we do so. Each account will be considered
            as an independent and separate account for the purpose of
            calculating the inactive period. Any one user accessing the account
            will keep the account active.
          </p>

          <p>
            When an account is terminated or cancelled, the account and any
            content left behind in the account will be made inaccessible. For a
            period of 14 days after any account has been cancelled you may
            request that we restore your account. After this period, your
            account and all Customer Data contained within it will be
            permanently deleted and the agreement between us as set out in these
            Terms and Conditions will automatically terminate. Please be aware
            that partial data may reside in our backup and/or archive systems
            for a period of up to 120 days.
          </p>

          <h3>Data Processing</h3>
          <p>
            Each party undertakes to comply with its obligations under relevant
            applicable data protection laws, principles and agreements.
          </p>

          <p>
            To the extent that personal data is processed by us on your behalf
            when you use the Service, you acknowledge that we are a data
            processor and the Customer is a data controller. The terms of the
            Data Processing Agreement shall apply to all Customer Data that we
            process on your behalf.
          </p>

          <p>
            Any information that you provide to us during signup or information
            provided when ordering the Service (such as the Customer's email
            address) will be used by us in accordance with the terms of the
            Privacy Policy.
          </p>

          <p>
            If a third party alleges infringement of its data protection rights,
            we shall be obliged to take measures necessary to prevent the
            infringement of a third party's rights from continuing.
          </p>

          <h3>Availability & Support</h3>
          <p>
            We will endeavour to make the Website and Service available to the
            Customer 24 x 7, however we shall not be liable if for any reason
            the Website or Services are unavailable at any time or for any
            period.
          </p>

          <p>
            We may suspend access to your account temporarily and without notice
            in the case of system failure, maintenance or repair or due to a
            Force Majeure Event (defined below).
          </p>

          <p>
            We will provide technical support to you by email only. Technical
            support will only be provided for bugs or errors that are
            reproducible by us. You agree to provide us with full and accurate
            details of all bugs and errors, on request. You acknowledge that we
            provide no warranty that all or any bugs or errors will be
            corrected.
          </p>

          <h3>Licence</h3>
          <p>
            Subject to the Customer's payment of the Fees, the Customer is
            granted a non-exclusive and non-transferable licence to use the
            Services (including any associated software, Intellectual Property
            Rights and Confidential Information) during the Term. This licence
            shall permit the Customer to make cache copies of software or other
            information as are required for the Customer to receive the Services
            via the Internet. Where open source software is used as part of the
            Services, such software use by the Customer will be subject to the
            terms of the open source licences.
          </p>

          <p>
            No right to modify, adapt, or translate the Services or create
            derivative works from the Services is granted to the Customer.
            Disassembly, decompilation or reverse engineering and other source
            code derivation of the software comprised within the Services is
            prohibited.
          </p>

          <p>
            Unless otherwise specified in this Agreement, the Services are
            provided and may be used solely by the Customer as part of the
            Customer's website/ desktop architecture. Except as specifically
            stated in this Agreement, the Customer may not: (i) lease, loan,
            resell or otherwise distribute the Services save as permitted in
            writing by us; (ii) use the Services to provide ancillary services
            related to the Services; or (iii) permit access to or use of the
            Services by or on behalf of any third party.
          </p>

          <p>
            The Customer warrants and represents that it shall maintain
            reasonable security measures (as may change over time) covering,
            without limitation, confidentiality, authenticity and integrity to
            ensure that the access to the Services granted under this Agreement
            is limited as set out under this Agreement.
          </p>

          <p>
            We may suspend access to the Services, or portion thereof, at any
            time, if in our sole reasonable discretion: (i) the Customer or its
            users is in breach of the AUP; or (ii) the integrity or security of
            the Services is in danger of being compromised by acts of the
            Customer or its users. Where possible, we shall give the Customer 24
            hours written notice, before suspending access to the Services,
            giving specific details of its reasons.
          </p>

          <h3>Intellectual Property Rights</h3>

          <p>
            All intellectual property rights and title to the Service and
            materials on the Website, including without limitation photographs
            and graphical images (save to the extent they incorporate any
            Customer Data or third party owned item) shall remain owned by us
            and our licensors and no interest or ownership in the Service or
            Website is transferred to the Customer. Nothing in these Terms and
            Conditions shall be construed to mean, by inference or otherwise,
            that the Customer has any right to obtain source code for the
            software comprised within the Service or Website.
          </p>

          <p>
            No part of the Website or Service may be reproduced or stored in any
            other website or included in any public or private electronic
            retrieval system or service without our prior written permission.
          </p>

          <p>
            When using the Service, Customers are encouraged to provide us with
            their feedback, suggestions or ideas for changes to the Service
            (<strong>"Feedback"</strong>). The Customer assigns to us all
            rights, title and interest in any Feedback. If for any reason such
            assignment is ineffective, the Customer shall grant us a
            non-exclusive, perpetual, irrevocable, royalty free, worldwide right
            and licence to use, reproduce, disclose, sub-licence, distribute,
            modify and exploit such feedback without restriction.
          </p>

          <p>
            We may take and maintain technical precautions to protect the
            Service and Website from improper or unauthorised use, distribution
            or copying.
          </p>

          <h3>Disclaimer & Customer Warranties</h3>

          <p>
            The material on the Website and the Services is provided "as is",
            without any conditions, warranties or other terms of any kind. While
            we endeavour to ensure that the information provided on the Website
            and the information provided in connection with the Services is
            correct, we do not warrant the accuracy and completeness of such
            material. We may make changes to the material on the Website or to
            the Services, or to the products and prices described on the
            Website, at any time without notice. The material on the Website may
            be out of date, and we make no commitment to update such material.
          </p>

          <p>
            We may share information about our future product plans with you
            from time to time. Please note that any public statement about our
            product plans is an expression of intent and should not be relied
            upon when purchasing the Services. Any decision to purchase the
            Services should be based on the functionality or features we have
            made available today and not on the future delivery of any
            functionality or features.
          </p>

          <p>
            You warrant and represent that: (i) you have authority to enter into
            this agreement and to perform your obligations under these Terms and
            Conditions; (ii) the execution and performance of your obligations
            under these Terms and Conditions does not violate or conflict with
            the terms of any other agreement to which you are a party and is in
            accordance with any applicable laws; (iii) you shall respect all
            applicable laws and regulations, governmental orders and court
            orders, which relate to use of the Services, or Website, and these
            Terms and Conditions; and (iv) you rightfully own the necessary user
            rights, copyrights and ancillary copyrights and permits required for
            you to fulfil your obligations under these Terms and Conditions.
          </p>

          <p>
            Except as expressly stated in these Terms and Conditions, all
            warranties and conditions, whether express or implied by statute,
            common law or otherwise (including but not limited to satisfactory
            quality and fitness for purpose), are hereby excluded to the fullest
            extent permitted by law. No warranty is made regarding the results
            of usage of the Services or Website or that the functionality of the
            Services or Website will meet your requirements or that the Services
            or Website will operate uninterrupted or error free.
          </p>

          <h3>Limitation Of Liability</h3>
          <p>
            We do not exclude or limit our liability to you for fraud, death or
            personal injury caused by any negligent act, omission or wilful
            misconduct by us in connection with the provision of the Website or
            Services, or any liability which cannot be excluded or limited under
            applicable law.
          </p>

          <p>
            In no event shall we be liable to you whether arising under these
            Terms and Conditions or in tort (including negligence or breach of
            statutory duty), misrepresentation or however arising, for any
            indirect or consequential loss or damage which shall include but not
            be limited to pure economic loss, losses incurred by any client of
            yours or other third party, loss of profits (whether categorised as
            direct or indirect loss), losses arising from business interruption,
            loss of business revenue, goodwill or anticipated savings, losses
            whether or not occurring in the normal course of business, wasted
            management or staff time, loss or corruption of data, or inability
            to use or the results of use of the Website or Services, any
            websites linked to the Website or the material on such websites.
          </p>

          <p>
            You acknowledge and agree our total liability to you (whether in
            contract, tort or otherwise) under or in connection with the
            provision of the Website or Services under these Terms and
            Conditions including any claim for indemnity or contribution shall
            not exceed one hundred (100) percent of the total amount (excluding
            any VAT, duty, sales or similar taxes) paid by you to us during the
            preceding twelve (12) month period.
          </p>

          <p>
            The Customer shall defend, indemnify and hold us and our employees,
            sub-contractors or agents harmless from and against any costs,
            losses, liabilities and expenses, including reasonable legal costs
            arising from any claim relating to or resulting directly or
            indirectly from: (i) any claimed infringement by the Customer, User
            or third party of any intellectual property rights with respect to
            use of the Website or Services outside of the scope of these Terms
            and Conditions; or (ii) use by us of any Customer Data or Customer
            or User provided item; or (iii) any access to or use of the Website
            or Services by a User, Client or a third party in breach of these
            Terms and Conditions; or (iv) breaches of data protection law or
            regulations resulting from the us processing data on behalf of and
            in accordance with the instructions of the Customer or User.
          </p>

          <h3>Confidentiality</h3>

          <p>
            Each party may use the confidential information of the other party
            only for the purposes of these Terms and Conditions and must keep
            confidential all confidential information of the other party except
            to the extent (if any) the recipient of any confidential information
            is required by law to disclose the confidential information.
          </p>

          <p>
            Each party may disclose the confidential information of the other
            party to those of its employees and agents who have a need to know
            the confidential information for the purposes of these Terms and
            Conditions but only if the employee or agent executes a
            confidentiality undertaking in a form approved by the other party.
          </p>

          <p>
            The obligations of confidentiality under these Terms and Conditions
            do not extend to information that: (i) was rightfully in the
            possession of the receiving party before the commencement of these
            Terms and Conditions; (ii) is or becomes public knowledge (otherwise
            than as a result of a breach of these Terms and Conditions; or (iii)
            is required by law to be disclosed.
          </p>

          <h3>EU Permitted Customers</h3>
          <p>
            If you are located in the EU you can only use the service if you are
            a business, as evidenced by a registered VAT number or other form of
            proof acceptable to us.
          </p>

          <h3>Assignment</h3>

          <p>
            You may not transfer, assign, charge or otherwise dispose of these
            Terms and Conditions or any of your rights or obligations arising
            hereunder, without our prior written consent.
          </p>

          <p>
            We may transfer, assign, charge, sub-contract or otherwise dispose
            of these Terms and Conditions, or any of our rights or obligations
            arising hereunder, at any time.
          </p>

          <h3>Relationship Between The Parties</h3>
          <p>
            The parties are independent contractors and nothing in these Terms
            and Conditions will be construed as creating an employer-employee
            relationship between us.
          </p>

          <h3>Communicating Between Us</h3>

          <p>
            We will communicate with you in English only. All notices from you
            must be given to us by email at support@mpzmail.com or by recorded
            delivery to 20 Dale Street, Manchester, M1 1EZ, United Kingdom.
            Unless explicitly stated otherwise in these Terms and Conditions we
            shall give all notices to you by email to the email address of the
            Administrator. Notices shall be deemed received and properly served
            24 hours after an email is sent, or for postal letters on receipt.
          </p>

          <h3>Changes</h3>
          <p>
            We maintain the right to change these Terms and Conditions, the
            Acceptable Use Policy, the Data Processing Agreement or Privacy
            Policy at any time, at our sole discretion upon giving you
            reasonable notice of any material change. Any changes will become
            effective on the date stated in such notice. If you or any User
            accesses or uses the Services after the date stated in any notice,
            this will constitute the Customer's acceptance of any changes. If
            you do not accept the changes you should cancel your account before
            the date stated in the notice and stop using the Service.
          </p>

          <p>
            New features in the Services, including new tools and resources,
            will be subject to these Terms and Conditions.
          </p>

          <h3>Force Majeure</h3>
          <p>
            We will not be liable or responsible for any failure to perform, or
            delay in performance of, any of our obligations caused by events
            outside our reasonable control (a
            <strong>"Force Majeure Event"</strong>).
          </p>

          <p>
            A Force Majeure Event includes any act, event, non-happening,
            omission or accident beyond our reasonable control and includes but
            is not limited to: (i) strikes, lock-outs or other industrial
            action; (ii) civil commotion, riot, invasion, terrorist attack or
            threat of terrorist attack, war (whether declared or not) or threat
            or preparation for war; (iii) fire, explosion, storm, flood,
            earthquake, subsidence, epidemic or other natural disaster; (iv)
            impossibility of the use of public or private telecommunications
            networks; (v) the acts, decrees, legislation, regulations or
            restrictions of any government.
          </p>

          <p>
            Our performance of our obligations shall be deemed to be suspended
            for the period that the Force Majeure Event continues, and we will
            have an extension of time for performance for the duration of that
            period. We will use our reasonable endeavours to bring the Force
            Majeure Event to an end or to find a solution by which our
            obligations under these Terms and Conditions may be performed
            despite the Force Majeure Event.
          </p>

          <h3>Miscellaneous</h3>

          <p>
            Should a provision of these Terms and Conditions be invalid or
            become invalid then the legal effect of the other provisions shall
            be unaffected. A valid provision is deemed to have been agreed which
            comes closest to what the parties intended commercially and shall
            replace the invalid provision. The same shall apply to any
            omissions.
          </p>

          <p>
            These Terms and Conditions, together with all documents referred to
            in them constitute the whole agreement and understanding between the
            parties and supersedes all prior agreements, representations,
            negotiations and discussions between the parties relating to the
            subject matter thereof.
          </p>

          <p>
            Nothing contained in these Terms and Conditions is intended to be
            enforceable pursuant to the Contracts (Rights of Third Parties) Act
            1999 or any other similar law in any jurisdiction.
          </p>

          <h3>Governing Law & Jurisdiction</h3>

          <p>
            These Terms and Conditions shall be governed by the laws of England
            and Wales. The courts of England shall have exclusive jurisdiction
            for the settlement of all disputes arising under these Terms and
            Conditions.
          </p>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "TermsAndConditions",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },

  data() {
    return {
      meta: {
        title: "Terms and Conditions - Transpond",
        description:
          "Take a look at the terms and conditions of use of the Transpond Platform",
      },
    };
  },
};
</script>
