<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Schedule 1"> </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1 mb-30">
          <div class="longform">
            <h5>
              List of Parties, Description of Processing and Transfer of
              Personal Data, Competent Supervisory Authority
            </h5>

            <h3>MODULE TWO: CONTROLLER TO PROCESSOR</h3>
            <h4>A. LIST OF PARTIES</h4>

            <p><strong>The Controller</strong> means the Customer.</p>

            <div class="terms-table terms-table--2-col">
              <table cellPadding="10" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>Address:</th>
                    <td>As set out for the Customer in the Agreement.</td>
                  </tr>

                  <tr>
                    <th>
                      Contact person's name, position and contact details:
                    </th>
                    <td>
                      As provided by the Customer in its account and used for
                      notification and invoicing purposes.
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Activities relevant to the data transferred under the
                      SCCs:
                    </th>
                    <td>Use of the Services.</td>
                  </tr>
                  <tr>
                    <th>Signature and date:</th>
                    <td>
                      By entering into the Agreement, the Controller is deemed
                      to have signed the SCCs incorporated into this DPA and
                      including their Annexes, as of the Effective Date of the
                      Agreement.
                    </td>
                  </tr>
                  <tr>
                    <th>Role:</th>
                    <td valign="top">Data Exporter.</td>
                  </tr>
                  <tr>
                    <th>Name of Representative (if applicable):</th>
                    <td valign="top">
                      Any UK or EU representative named in the Controller's
                      privacy policy.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p>
              <strong>The Processor </strong>means the Company: Transpond
              Limited
            </p>

            <div class="terms-table terms-table--2-col">
              <table cellPadding="10" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>Address:</th>
                    <td>
                      Level 1, 20 Dale Street, Manchester, M1 1EZ, England.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Contact person's name, position and contact details:
                    </th>
                    <td>support@mpzmail.com</td>
                  </tr>
                  <tr>
                    <th>
                      Activities relevant to the data transferred under the
                      SCCs:
                    </th>
                    <td>
                      The provision of online marketing solutions to the
                      Controller under which the Processor processes Personal
                      Data upon the instructions of the Controller in accordance
                      with the terms of the Agreement.
                    </td>
                  </tr>
                  <tr>
                    <th>Signature and date:</th>
                    <td>
                      By entering into the Agreement, the Processor is deemed to
                      have signed the SCCs, incorporated into this DPA,
                      including their Annexes, as of the Effective Date of the
                      Agreement.
                    </td>
                  </tr>
                  <tr>
                    <th>Role:</th>
                    <td>Data Importer</td>
                  </tr>
                  <tr>
                    <th>Name of Representative (if applicable):</th>
                    <td>Data Protection Representative Limited</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>B. DESCRIPTION OF PROCESSING AND TRANSFERS</h4>

            <div class="terms-table terms-table--2-col">
              <table>
                <tbody>
                  <tr>
                    <th>Categories of data subjects:</th>
                    <td>
                      Individuals about whom data is uploaded to the Services by
                      (or at the direction of) the Controller or by Users,
                      Affiliates and other participants whom the Controller has
                      granted the right to access the Services in accordance
                      with the provisions of the Agreement including but not
                      limited to:
                      <ul>
                        <li>
                          Prospects, customers, clients, business partners,
                          vendors and suppliers (who are natural persons)
                        </li>
                        <li>
                          Employees, agents, advisors, consultants, freelancers
                          (who are natural persons).
                        </li>
                        <li>
                          Individuals with whom Users communicate by email
                          and/or other messaging media.
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <th>Categories of Personal Data:</th>
                    <td>
                      Data relating to individuals uploaded to the Service by
                      (or at the direction of) the Controller or by Users,
                      Affiliates and other participants whom the Controller has
                      granted the right to access the Services in accordance
                      with the provisions of the Agreement. The Personal Data
                      includes but is not limited to:
                      <ul>
                        <li>Email Address, Phone Number</li>
                        <li>
                          Personal Data held in custom fields defined by the
                          Controller or Users
                        </li>
                        <li>
                          IP address of browsers or email clients that contacts
                          use
                        </li>
                        <li>
                          Geolocation of contacts browser or email clients
                        </li>
                        <li>
                          Information offered by Users as part of support
                          enquiries
                        </li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <th>Sensitive Data:</th>
                    <td>
                      No sensitive data (special category data) will be
                      processed or transferred and shall not be contained in the
                      content of, or attachments to, emails.
                    </td>
                  </tr>

                  <tr>
                    <th>
                      The frequency of the processing and transfer (e.g. whether
                      the data is transferred on a one-off or continuous basis):
                    </th>
                    <td>Continuous basis for the duration of the Agreement.</td>
                  </tr>

                  <tr>
                    <th>Nature of the processing:</th>
                    <td>
                      Processing operations include but are not limited to:
                      providing email campaign &amp; marketing automation
                      services to users of the Services.
                    </td>
                  </tr>

                  <tr>
                    <th>
                      Purpose(s) of the data transfer and further processing:
                    </th>
                    <td>
                      Personal Data is transferred to sub-contractors who need
                      to process some of the Personal Data in order to provide
                      their services to the Processor as part of the Services
                      provided by the Processor to the Controller.
                    </td>
                  </tr>

                  <tr>
                    <th>
                      The period for which the Personal Data will be retained,
                      or, if that is not possible, the criteria used to
                      determine that period:
                    </th>
                    <td>
                      Unless agreed otherwise in writing, for the duration of
                      the Agreement, subject to clause 14 of the DPA.
                    </td>
                  </tr>

                  <tr>
                    <th>
                      For transfers to (Sub-) processors, also specify subject
                      matter, nature and duration of the processing:
                    </th>
                    <td>
                      The
                      <a href="/subprocessors">Sub-processor list</a>
                      sets out the Personal Data processed by each Sub-processor
                      and the services provided by each Sub-processor.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>C. COMPETENT SUPERVISORY AUTHORITY</h4>

            <div class="terms-table terms-table--2-col">
              <table cellPadding="10" cellSpacing="0">
                <tbody>
                  <tr>
                    <th>
                      Identify the competent supervisory authority/ies (e.g. in
                      accordance with Clause 13 of the SCCs)
                    </th>
                    <td>
                      Where the EU GDPR applies, the Irish Data Protection
                      Authority - the Data Protection Commission (DPC).
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Where the UK GDPR applies, the UK Information
                      Commissioner's Office, (ICO).
                    </th>
                    <td>
                      Where the FDPA applies, the Swiss Federal Data Protection
                      and Information Commissioner, (FDPIC).
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4>MODULE THREE: PROCESSOR TO PROCESSOR</h4>
            <h4>A. LIST OF PARTIES</h4>

            <p><strong>The Data Exporter:</strong> is the Company.</p>
            <p>
              <strong>The Data Importers:</strong> are the Sub-processors named
              in <a href="/subprocessors">the Sub-processor list</a> which
              contains the name, address, contact details and activities
              relevant to the data transferred to each Data Importer.
            </p>

            <h4>B. DESCRIPTION OF PROCESSING AND TRANSFERS</h4>
            <p>
              The Sub-processor list includes the information about the
              processing and transfers of the Personal Data, for each Data
              Importer:
            </p>
            <ul>
              <li>categories of Data Subjects</li>
              <li>categories of Personal Data</li>
              <li>the nature of the processing</li>
              <li>the purposes of the processing</li>
            </ul>

            <p>Personal Data is processed by each Sub-processor:</p>

            <ul>
              <li>on a continuous basis</li>
              <li>
                to the extent necessary to provide the Services in accordance
                with the Agreement and the Data Exporter's instructions.
              </li>
              <li>
                for the duration of the Agreement and subject to clause 14 of
                the DPA.
              </li>
            </ul>

            <h4>C. COMPETENT SUPERVISORY AUTHORITY</h4>

            <p>
              The competent Supervisory Authority of the Data Exporter shall be:
            </p>

            <ul>
              <li>
                Where the EU GDPR applies, the Irish Data Protection Authority –
                the Data Protection Commission (DPC).
              </li>
              <li>
                Where the UK GDPR applies, the UK Information Commissioner's
                Office, (ICO).
              </li>
              <li>
                Where the FDPA applies, the Swiss Federal Data Protection and
                Information Commissioner, (FDPIC).
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "DataProcessingAgreementSchedule1",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    TermsNav,
  },

  data() {
    return {
      meta: {
        title: "ta Processing Agreement - Schedule 1 - Transpond",
        description:
          "Take a look at the Transpond Data Processing Agreement - Schedule 1",
      },
    };
  },
};
</script>
