<template>
  <section class="customer-logos container-fluid center-xs" :class="[getClass, isInverted ? 'customer-logos--inverted' : '' ]">
    <div class="row">
      <div class="col-xs-12">
        <p class="p--lg">{{ heading }}</p>
          <div class="customer-logos__wrapper">
            <img
              class="customer-logos__customer-logo"
              src="../assets/ups-black.svg"
              alt="UPS logo"
              title="UPS logo"
              width="40"
            />
            <img
              class="customer-logos__customer-logo"
              src="../assets/gist-black.svg"
              alt="The Gist logo"
              title="The Gist logo"
              width="80"
            />
            <img
              class="customer-logos__customer-logo"
              src="../assets/bunches-black.svg"
              alt="Bunches logo"
              title="Bunches logo"
              width="156"
            />
            <img
              class="customer-logos__customer-logo"
              src="../assets/citroen-black.svg"
              alt="Citroën logo"
              title="Citroën logo"
              width="64"
            />
            <img
              class="customer-logos__customer-logo"
              src="../assets/peugeot-black.svg"
              alt="Peugeot logo"
              title="Peugeot logo"
              width="48"
            />
          </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "CustomerLogos",
  props: {
    class: {
      type: String,
    },
    heading: {
      type: String,
      required: true,
    },
    isInverted: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getClass() {
      return this.class;
    }
  },
  data() {
    return {
      inverted: this.isInverted
    };
  },
};
</script>
