<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Third Party Providers"> </PageHeader>

    <div class="container-fluid">
      <div class="row mb-30">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <h1>Third Party Providers</h1>

            <div class="terms-table">
              <table cellPadding="10" cellSpacing="0">
                <thead>
                  <th>Third Party</th>
                  <th>Purpose</th>
                  <th>Categories of Personal Data</th>
                  <th>Privacy Policy</th>
                </thead>
                <tbody>
                  <tr>
                    <th>Digital Ocean LLC</th>
                    <td>
                      The primary infrastructure used by the Processor to host
                      Service Data submitted to the Services
                    </td>
                    <td>
                      Customer Entered Data (eg Account Details, Contacts, Send
                      Data)
                    </td>
                    <td>
                      <a
                        href="https://www.digitalocean.com/legal/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Google Analytics</th>
                    <td>Analytics and advertising</td>
                    <td>IP Address</td>
                    <td>
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Data Protection Representative Limited</th>
                    <td>EU representative</td>
                    <td>Email Address</td>
                    <td>
                      <a
                        href="https://www.datarep.com/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Stripe</th>
                    <td>Payment card processing</td>
                    <td>IP Address, Name, Payment details</td>
                    <td>
                      <a
                        href="https://stripe.com/en-gb/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Amazon Web Service (AWS)</th>
                    <td>Primary data hosting platform</td>
                    <td>
                      Name, Email address, Invoicing Address, Billing Address
                    </td>
                    <td>
                      <a
                        href="https://aws.amazon.com/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>HelpScout Inc</th>
                    <td>Support emails</td>
                    <td>
                      User name, email address, IP address and support queries
                    </td>
                    <td>
                      <a
                        href="https://www.helpscout.com/company/legal/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>WideFM t/a Juice Broadband</th>
                    <td>
                      Used for email server hosting. Emails scheduled via the
                      Transpond platform are mainly sent through servers hosted
                      by WideFM.
                    </td>
                    <td>Email files containing sender information.</td>
                    <td>
                      <a
                        href="https://www.juicebroadband.co.uk/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Paypal</th>
                    <td>Affiliate payments</td>
                    <td>Paypal Address</td>
                    <td>
                      <a
                        href="https://www.paypal.com/myaccount/privacy/privacyhub"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>YouTube</th>
                    <td>Video playback</td>
                    <td>IP Address</td>
                    <td>
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Google Fonts</th>
                    <td>Font display</td>
                    <td>IP Address</td>
                    <td>
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Wufoo</th>
                    <td>Form capture</td>
                    <td>Name, Email Address, IP Address</td>
                    <td>
                      <a
                        href="https://www.wufoo.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>img.ly GmbH (PhotoEditor SDK)</th>
                    <td>Image Processing</td>
                    <td>Customer images</td>
                    <td>
                      <a
                        href="https://img.ly/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        >Click Here</a
                      >
                    </td>
                  </tr>

                  <tr>
                    <th>CapsuleCRM Inc</th>
                    <td>Customer support services</td>
                    <td>User name, email address, IP address and support queries</td>
                    <td>
                      <a
                        href="https://capsulecrm.com/privacy/"
                        target="_blank"
                        rel="noopener noreferrer">Click Here</a>
                    </td>
                  </tr>

                  <tr>
                    <th>Google Inc</th>
                    <td>Email Services</td>
                    <td>User name, email address, IP address and support queries</td>
                    <td>
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer">Click Here</a>
                    </td>
                  </tr>

                  <tr>
                    <th>Zestia Limited</th>
                    <td>Customer support services</td>
                    <td>User name, email address, IP address and support queries</td>
                    <td>
                      <a
                        href="https://capsulecrm.com/privacy/"
                        target="_blank"
                        rel="noopener noreferrer">Click Here</a>
                    </td>
                  </tr>

                  <tr>
                    <th>OpenAI LLC</th>
                    <td>Chat GPT messaging services.</td>
                    <td>Customer entered data (account details,  content), Content of communications between a customer and user</td>
                    <td>
                      <a
                        href="https://openai.com/policies/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer">Click Here</a>
                    </td>
                  </tr>

                  <tr>
                    <th>PostHog Inc</th>
                    <td>Enables the Processor to better guide customers through the app as well as enhance the overall product experience for customers.</td>
                    <td>Account Name, Account ID, User Id, User Email Address</td>
                    <td>
                      <a
                        href="https://posthog.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer">Click Here</a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "ThirdPartyProviders",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Third Party Providers - Transpond",
        description: "Take a look at the Transpond Third Party Providers",
      },
    };
  },
};
</script>
