<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <div>
    <navigation></navigation>

    <IntegrationHeader></IntegrationHeader>

    <IntegrationBody></IntegrationBody>

    <section class="container-fluid mb-40">
      <div class="row">
        <div class="col-xs-12 col-sm-10 col-sm-offset-1">
          <div class="row">
            <div class="col-xs-12">
              <ImageSlider
                v-if="
                  ourApp.screenshots &&
                  typeof ourApp.screenshots == 'object' &&
                  ourApp.screenshots.length > 0
                "
              >
              </ImageSlider>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-10 col-sm-offset-1">
          <footerCTA></footerCTA>
        </div>
      </div>
    </section>

    <site-footer></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import IntegrationHeader from "@/components/Integrations/IntegrationHeader";
import IntegrationBody from "@/components/Integrations/IntegrationBody";
import ImageSlider from "@/components/ImageSlider/ImageSlider";
import FooterCTA from "@/components/FooterCTA";

import axios from "axios";

export default {
  name: "IntegrationDetails",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    IntegrationHeader,
    IntegrationBody,
    ImageSlider,
    FooterCTA,
  },
  data() {
    return {
      blogData: [],
      ourApp: [],
      dataLoaded: false,
      meta: {
        title: "",
        description: "",
      },
    };
  },

  created() {
    axios
      .get(
        "https://api.transpond.io/api/applications/" + this.$route.params.appId
      )
      .then((response) => {
        if (!response.data.Application) this.$router.push("/integrations");
        this.ourApp = response.data.Application;

        this.dataLoaded = true;
        this.meta.title = `Transpond integration partner - ${this.ourApp.appName}`;
        this.meta.description = `Transpond integration partner - ${this.ourApp.appName}`;
      });
  },
  methods: {
    installApp() {
      window.open(
        "https://app.transpond.io/appconnect?appid=" + this.ourApp.id,
        this.ourApp.id,
        "_self"
      );
    },
  },
};
</script>
