import * as Pages from "../pages";
import routes from "./routes";

class Routes {
  static routes = routes.map((route) => ({
    ...route,
    ...(route.componentName && { component: Pages[route.componentName] }),
  }));
}

export default Routes;
