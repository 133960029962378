<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
    theme="dark"
  >

    <PageHeaderFeature
      title="Let AI bring your email content to life"
      subtitleText="Let AI generate content for you in seconds to help you get started with your email campaigns."
      buttonText="Get started"
      buttonLink="Pricing"
      :kicker="{
        icon: 'ai',
        text: 'AI Content Assistant',
        theme: 'dark'
      }"
    ></PageHeaderFeature>

    <section class="content-section mt-20">
      <div class="container-fluid">
        <div class="row between-xs middle-xs">
          <div class="col-xs-12">
            <div class="ai-emails">
              <h4 class="mb-20">Which email do you think is AI generated?</h4>
              <div class="ai-emails__images">
                <div v-on:click="chooseAi" class="ai-emails__image-container">
                  <div class="ai-emails__letter">A</div>

                  <Image
                  file="ai-email-1"
                  class="ai-emails__image"
                  ></Image>
                </div>

                <div v-on:click="chooseAi" class="ai-emails__image-container">
                  <div class="ai-emails__letter ai-emails__letter--right">B</div>

                  <Image
                  file="ai-email-2"
                  class="ai-emails__image"
                  ></Image>
                  
                  <div :class="'ai-emails__answer ai-emails__answer--' + aiSelected">
                    <img :src="require('@/assets/images/icons/arrow-down-white.svg')" />
                    AI Generated
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content-section mt-20 sm:mt-40">
      <div class="container-fluid">
        <div class="row between-xs middle-xs">
          <div class="first-sm col-xs-12 col-sm-5">
            <h4 class="mb-6">Harness the power of AI from within Transpond</h4>
            <div v-if="accordion" class="accordion mt-10">
              <AccordionItem
                v-for="item in accordion"
                v-bind:key="item.name"
                :active="item.name === selected"
                @setSelected="() => setSelected(item)"
              >
                {{ item.name }}
                <template v-slot:content>
                  {{ item.content }}
                </template>
              </AccordionItem>
            </div>
          </div>
          <div class="first-xs col-xs-12 col-sm-6 mb-10 sm:mb-0">
            <Video file="generator-ai"></Video>
          </div>
        </div>
      </div>
    </section>

    <section class="content-section mt-20 sm:mt-40">
      <div class="container-fluid">
        <div class="row between-xs">
          <div class="col-xs-12 col-md-7 mb-5 md:mb-0">
            <div class="card card--ai card--lg">
              <Image file="ai-prompt"></Image>
              <h5 class="mt-8 md:mt-16 mb-2">AI is here to stay</h5>
              <p>Research by IBM indicates that 77% of businesses are actively incorporating AI into their processes or exploring how to do so. It’s predicted to grow significantly over the next decade.</p>
            </div>
          </div>
          <div class="col-xs-12 col-md-5 flex flex-column">
            <div class="card card--ai mb-5">
              <h5 class="mb-2">Make research a breeze</h5>
              <p>Getting started can often be the hardest part of writing something. Where should you look for inspiration? How long should that take? The Transpond Content Assistant does all of that for you.</p>
            </div>

            <div class="card card--ai grow-0">
              <h5 class="mb-2">Get more done</h5>
              <p>According to a report by Zipia, 64% of businesses that implement AI see an improvement in productivity.</p>
            </div>
          </div>
        </div>
      </div>
    </section>


  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import Video from "@/components/Video.vue";
import Image from "@/components/Image.vue";
import AccordionItem from "@/components/Accordion/AccordionItem.vue";

export default {
  name: "AIContentAssistant",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    Video,
    AccordionItem,
    Image
  },
  data() {
    return {
      meta: {
        title: "AI Content Assistant - Transpond",
        description:
          "Let AI bring your email content to life. Let AI generate content for you in seconds to help you get started with your email campaigns.",
      },
      accordion: [
        {
          name: 'Make a start on longform articles',
          content:
            'Get the ideas flowing by telling the Content Assistant what you need.',
        },
        {
          name: 'Rewrite existing content',
          content:
            'See where you can improve on something you’ve already crafted.',
        },
        {
          name: 'Improve engagement',
          content:
            'Let the Content Assistant write your subject lines, so you can get the best open rates.',
        }
      ],
      selected: "Make a start on longform articles",
      aiSelected: false
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
    setSelected(item) {
      this.selected = item.name;
    },
    chooseAi() {
      this.aiSelected = true
    }
  },
};
</script>