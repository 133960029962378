<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Data Processing Agreement"> </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <p>
              This DPA is entered into between the Controller and the Processor
              and is incorporated into and governed by the terms of the
              Agreement.
            </p>
            <h3>1. Definitions</h3>
            <p>
              Any capitalised term not defined in this DPA shall have the
              meaning given to it in the Terms and Conditions.
</p>
            <div class="terms-table terms-table--2-col">
              <table>
                <tbody>
                  <tr>
                    <th valign="top">“Affiliate”</th>
                    <td valign="top">
                      means any entity that directly or indirectly controls, is
                      controlled by, or is under common control of a party.
                      “Control,” for purposes of this definition, means direct
                      or indirect ownership or control of more than 50% of the
                      voting interests of a party;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Agreement”</th>
                    <td valign="top">
                      means the agreement between the Processor and the Customer
                      for the provision of the Services set out in the Terms and
                      Conditions;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“CCPA”</th>
                    <td valign="top">
                      means the California Consumer Privacy Act of 2018, along
                      with its regulations and as amended from time to time;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Controller”</th>
                    <td valign="top">means the Customer;</td>
                  </tr>
                  <tr>
                    <th valign="top">“Data Protection Law”</th>
                    <td valign="top">
                      means all laws and regulations, including laws and
                      regulations of the European Union, the European Economic
                      Area, their member states and the United Kingdom any
                      amendments, replacements or renewals thereof, applicable
                      to the processing of Personal Data, including where
                      applicable the Data Protection, Privacy and Electronic
                      Communications (Amendments etc.) (EU Exit) Regulations
                      2020, the EU GDPR, the UK GDPR, the FDPA, the UK Data
                      Protection Act 2018, the CCPA and any applicable national
                      implementing laws, regulations and secondary legislation
                      relating to the processing of Personal Data and the
                      privacy of electronic communications, as amended, replaced
                      or updated from time to time, including the Privacy and
                      Electronic Communications Directive (2002/58/EC) and the
                      Privacy and Electronic Communications (EC Directive)
                      Regulations 2003 (SI 2003/2426);
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Data Subject”</th>
                    <td valign="top">
                      shall have the same meaning as in Data Protection Law, or
                      means a “Consumer” as that term is defined in the CCPA;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“DPA”</th>
                    <td valign="top">
                      means this data processing agreement together with its
                      <a href="/dpa_schedule1">Schedule 1</a>
                      <a href="/dpa_schedule2">Schedule 2</a> and
                      <a href="/dpa_schedule3">Schedule 3</a>;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“EEA”</th>
                    <td valign="top">means the European Economic Area;</td>
                  </tr>

                  <tr>
                    <th valign="top">“EU GDPR”</th>
                    <td valign="top">
                      means Regulation (EU) 2016/679 of the European Parliament
                      and of the Council of 27 April 2016 on the protection of
                      natural persons with regard to the processing of personal
                      data and on the free movement of such data, (General Data
                      Protection Regulation);
                    </td>
                  </tr>

                  <tr>
                    <th valign="top">“FDPA”</th>
                    <td valign="top">

                      means the new Swiss Federal Act on Data Protection of 1st of September 2023 and as amended from time to time;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Personal Data”</th>
                    <td valign="top">
                      shall have the same meaning as in Data Protection Law;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Processor”</th>
                    <td valign="top">
                      means us, including as applicable any “Service Provider”
                      as that term is defined by the CCPA;
                    </td>
                  </tr>

                  <tr>
                    <th valign="top">“Restricted Transfer”</th>
                    <td valign="top">
                      <p>means:</p>
                      <p>
                        (i) where the EU GDPR applies, a transfer of Personal
                        Data via the Services from the EEA either directly or
                        via onward transfer, to any country or recipient outside
                        of the EEA not subject to an adequacy determination by
                        the European Commission; and
                      </p>
                      <p>
                        (ii) where the UK GDPR applies, a transfer of Personal
                        Data via the Services from the United Kingdom either
                        directly or via onward transfer, to any country or
                        recipient outside of the UK not based on adequacy
                        regulations pursuant to Section 17A of the United
                        Kingdom Data Protection Act 2018; and
                      </p>
                      <p>
                        (iii) a transfer of Personal Data via the Services from
                        Switzerland either directly or via onward transfer, to
                        any country or recipient outside of the EEA and/or
                        Switzerland not subject to an adequacy determination by
                        the European Commission;
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Services”</th>
                    <td valign="top">
                      means all services and software applications and solutions
                      provided to the Controller by the Processor under and as
                      described in the
                      <a href="/Terms">Terms and Conditions</a>;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“SCCs”</th>
                    <td valign="top">
                      <p>means:</p>
                      <p>
                        (i) where the EU GDPR applies, the contractual clauses
                        annexed to the European Commission's Implementing
                        Decision 2021/914 of 4 June 2021 on standard contractual
                        clauses for the transfer of personal data to third
                        countries, (“<a
                          href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32021D0914&from=EN/"
                          target="_blank"
                          rel="noopener noreferrer"
                          >EU SCCs</a
                        >”); and
                      </p>
                      <p>
                        (ii) where the UK GDPR applies standard data protection
                        clauses adopted pursuant to Article 46(2)(c) as set out
                        in Schedule 3 of this DPA, (“<a
                          href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/international-transfers-after-uk-exit/sccs-after-transition-period/"
                          target="_blank"
                          rel="noopener noreferrer"
                          >UK SCCs</a
                        >”); and
                      </p>
                      <p>
                        (iii) where Personal Data is transferred from
                        Switzerland to outside of Switzerland or the EEA, the EU
                        SCCs as amended in accordance with guidance from the
                        Swiss Data Protection Authority; (“Swiss SCCs”);
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Sub-processor”</th>
                    <td valign="top">
                      means any third party (including Processor Affiliates)
                      engaged directly or indirectly by the Processor (to
                      process Personal Data under this DPA in the provision of
                      the Services to the Controller.
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Supervisory Authority”</th>
                    <td valign="top">
                      means a governmental or government chartered regulatory
                      body having binding legal authority over a party;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“Terms and Conditions”</th>
                    <td valign="top">
                      means the customer terms published at
                      <a href="/Terms">https://transpond.io/Terms</a> as amended
                      from time to time;
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">“UK GDPR”</th>
                    <td valign="top">
                      means the EU GDPR as it forms part of the law of England
                      and Wales, Scotland and Northern Ireland by virtue of
                      section 3 of the European Union (Withdrawal) Act 2018.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3>2. Purpose</h3>
            <p>
              The Processor has agreed to provide the Services to the Controller
              in accordance with the terms of the Agreement. In providing the
              Services, the Processor shall process Customer Data on behalf of
              the Controller. Customer Data may include Personal Data. The
              Processor will process and protect such Personal Data in
              accordance with the terms of this DPA.
            </p>

            <h3>3. Scope</h3>
            <p>
              In providing the Services to the Controller pursuant to the terms
              of the Agreement, the Processor shall process Personal Data only
              to the extent necessary to provide the Services in accordance with
              the terms of the Agreement, this DPA and the Controller's
              instructions documented in the Agreement, as updated from time to
              time.
            </p>
            <p>
              The Controller and Processor shall take steps to ensure that any
              natural person acting under the authority of the Controller or the
              Processor who has access to Personal Data does not process them
              except on the instructions from the Controller unless he or she is
              required to do so by any Data Protection Law.
            </p>

            <h3>4. Processor Obligations</h3>
            <p>
              The Processor may collect, process or use Personal Data only
              within the scope of this DPA.
            </p>
            <p>
              The Processor confirms that it shall process Personal Data on
              behalf of the Controller in accordance with the documented
              instructions of the Controller.
            </p>
            <p>
              The Processor shall promptly inform the Controller, if in the
              Processor's opinion, any of the instructions regarding the
              processing of Personal Data provided by the Controller, breach any
              Data Protection Law.
            </p>
            <p>
              The Processor shall ensure that all employees, agents, officers
              and contractors involved in the handling of Personal Data: (i) are
              aware of the confidential nature of the Personal Data and are
              contractually bound to keep the Personal Data confidential; (ii)
              have received appropriate training on their responsibilities as a
              data processor; and (iii) are bound by the terms of this DPA.
            </p>
            <p>
              The Processor shall implement appropriate technical and
              organisational procedures to protect Personal Data, taking into
              account the state of the art, the costs of implementation and the
              nature, scope, context and purposes of processing as well as the
              risk of varying likelihood and severity for the rights and
              freedoms of natural persons.
            </p>
            <p>
              The Processor shall implement appropriate technical and
              organisational measures to ensure a level of security appropriate
              to the risk, including inter alia as appropriate: (i) the
              pseudonymisation and encryption of Personal Data; (ii) the ability
              to ensure the on-going confidentiality, integrity, availability
              and resilience of processing systems and services; (iii) the
              ability to restore the availability and access to Personal Data in
              a timely manner in the event of a physical or technical incident;
              (iv) a process for regularly testing, assessing and evaluating the
              effectiveness of technical and organisational measures for
              ensuring the security of the processing. In accessing the
              appropriate level of security, account shall be taken in
              particular of the risks that are presented by processing, in
              particular from accidental or unlawful destruction, loss,
              alteration, unauthorised disclosure of, or access to Personal Data
              transmitted, stored or otherwise processed.
            </p>
            <p>
              The technical and organisational measures detailed in
              <a href="/dpa_schedule1">Schedule 1</a>
              shall be at all times adhered to as a minimum security standard.
              The Controller accepts and agrees that the technical and
              organisational measures are subject to development and review and
              that the Processor may use alternative suitable measures to those
              detailed in the attachments to this DPA provided that such updates
              and modifications do not result in the degradation of the overall
              security of the Services.
            </p>
            <p>
              The Controller acknowledges and agrees that, in the course of
              providing the Services to the Controller, it may be necessary for
              the Processor to access the Personal Data to respond to any
              technical problems or Controller queries and to ensure the proper
              working of the Services. All such access by the Processor will be
              limited to those purposes.
            </p>
            <p>
              Taking into account the nature of the processing and the
              information available to the Processor, the Processor shall assist
              the Controller by having in place appropriate technical and
              organisational measures, insofar as this is possible, for the
              fulfilment of the Controller's obligation to respond to requests
              for exercising the Data Subject's rights and the Controller's
              compliance with the Controller's data protection obligations in
              respect of the processing of Personal Data.
            </p>
            <p>
              The Processor may not: (i) sell Personal Data; (ii) retain, use,
              or disclose Personal Data for commercial purposes other than
              providing the Services under the terms of the Agreement; or (iii)
              retain, use, or disclose Personal Data outside of the Agreement.
            </p>

            <h3>5. Controller Obligations</h3>
            <p>
              The Controller represents and warrants that: (i) it shall comply
              with the terms of the <a href="/Terms">Terms and Conditions</a>,
              this DPA and its obligations under Data Protection Law; (ii) it
              has obtained any and all necessary permissions and authorisations
              necessary to permit the Processor, its Affiliates and
              Sub-processors, to execute their rights or perform their
              obligations under this DPA; (iii) all Affiliates of the Controller
              who use the Services shall comply with the obligations of the
              Controller set out in this DPA.
            </p>
            <p>
              The Controller has their own obligations to implement their own
              appropriate technical and organisational measures to protect
              Personal Data, taking into account the state of the art, the costs
              of implementation and the nature, scope, context and purposes of
              processing as well as the risk of varying likelihood and severity
              for the rights and freedoms of natural persons. The Controller
              shall implement appropriate technical and organisational measures
              to ensure a level of security appropriate to the risk, including
              inter alia as appropriate: (i) the pseudonymisation and encryption
              of Personal Data; (ii) the ability to ensure the on-going
              confidentiality, integrity, availability and resilience of
              processing systems and services; (iii) the ability to restore the
              availability and access to Personal Data in a timely manner in the
              event of a physical or technical incident; (iv) a process for
              regularly testing, assessing and evaluating the effectiveness of
              technical and organisational measures for ensuring the security of
              the processing. In accessing the appropriate level of security
              account shall be taken in particular of the risks that are
              presented by processing, in particular from accidental or unlawful
              destruction, loss, alteration, unauthorised disclosure of, or
              access to Personal Data transmitted, stored or otherwise
              processed.
            </p>
            <p>
              The Controller acknowledges and agrees that some instructions from
              the Controller, including the Processor assisting with audits,
              inspections, DPIAs or providing other assistance under this DPA,
              may result in additional fees. In such case the Processor shall
              notify the Controller of its fees for providing such assistance in
              advance and shall be entitled to charge the Controller for its
              reasonable costs and expenses in providing such assistance, unless
              agreed otherwise.
            </p>

            <h3>6. Sub-processors</h3>
            <p>
              The Controller acknowledges and agrees that: (i) Affiliates of the
              Processor may be used as Sub-processors; and (ii) the Processor
              and its Affiliates respectively may engage Sub-processors in
              connection with the provision of the Services.
            </p>
            <p>
              All Sub-processors who process Personal Data in the provision of
              the Services to the Controller shall comply with the obligations
              of the Processor similar to those set out in this DPA.
            </p>
            <p>
              The Controller authorises the Processor to use the Sub-processors
              included in the
              <a href="/subprocessors">list of Sub-Processors</a> to process the
              Personal Data. During the term of this DPA, the Processor shall
              provide the Controller with 30 days prior notification, via email
              (or in-application notice), of any changes to the list of
              Sub-processors before authorising any new or replacement
              Sub-processor to process Personal Data in connection with the
              provision of the Services.
            </p>
            <p>
              The Controller may object to the use of a new or replacement
              Sub-processor, by notifying the Processor promptly in writing
              within ten (10) Business Days after receipt of the Processor's
              notice. If the Controller objects to a new or replacement
              Sub-processor, the Controller may terminate the Agreement with
              respect to those Services which cannot be provided by the
              Processor without the use of the new or replacement Sub-processor.
              The Processor will refund the Controller any prepaid fees covering
              the remainder of the term of the Agreement following the effective
              date of termination with respect to such terminated Services.
            </p>
            <p>
              All Sub-processors who process Personal Data shall comply with the
              obligations of the Processor set out in this DPA. The Processor
              shall prior to the relevant Sub-processor carrying out any
              processing activities in respect of the Personal Data: (i) appoint
              each Sub-processor under a written contract containing materially
              the same obligations to those of the Processor in this DPA
              enforceable by the Processor; and (ii) ensure each such
              Sub-processor complies with all such obligations.
            </p>
            <p>
              The Controller agrees that the Processor and its Sub-processors
              may make Restricted Transfers of Personal Data for the purpose of
              providing the Services to the Controller in accordance with the
              Agreement. The Processor confirms that such Sub-processors: (i)
              are located in a third country or territory recognised by the EU
              Commission or a Supervisory Authority, as applicable, to have an
              adequate level of protection; or (ii) have entered into the
              applicable SCCs with the Processor; or (iii) have other legally
              recognised appropriate safeguards in place.
            </p>

            <h3>7. Restricted Transfers</h3>
            <p>
              The parties agree that, when the transfer of Personal Data from
              the Controller to the Processor or from the Processor to a
              Sub-processor is a Restricted Transfer, it shall be subject to the
              applicable SCCs as outlined
              <a href="/dpa_restrictedtransfers">here</a>.
            </p>
            <p>
              In the event that any provision of this DPA contradicts directly
              or indirectly any SCCs, the provisions of the applicable SCCs
              shall prevail over the terms of the DPA.
            </p>

            <h3>8. Data Subject Access Requests</h3>
            <p>
              The Controller may require correction, deletion, blocking and/or
              making available the Personal Data during or after termination of
              the Agreement. The Controller acknowledges and agrees that the
              Processor will process the request to the extent it is lawful and
              will reasonably fulfil such request in accordance with its
              standard operational procedures to the extent possible.
            </p>
            <p>
              In the event that the Processor receives a request from a Data
              Subject in relation to Personal Data, the Processor will refer the
              Data Subject to the Controller unless otherwise prohibited by law.
              The Controller shall reimburse the Processor for all costs
              incurred resulting from providing reasonable assistance in dealing
              with a Data Subject request. In the event that the Processor is
              legally required to respond to the Data Subject, the Controller
              will fully cooperate with the Processor as applicable.
            </p>

            <h3>9. Audit</h3>
            <p>
              The Processor shall make available to the Controller all
              information reasonably necessary to demonstrate compliance with
              its processing obligations and allow for and contribute to audits
              and inspections.
            </p>
            <p>
              Any audit conducted under this DPA shall consist of examination of
              the most recent reports, certificates and/or extracts prepared by
              an independent auditor bound by confidentiality provisions similar
              to those set out in the Agreement. In the event that provision of
              the same is not deemed sufficient in the reasonable opinion of the
              Controller, the Controller may at its own expense conduct a more
              extensive audit which will be: (i) limited in scope to matters
              specific to the Controller and agreed in advance with the
              Processor; (ii) carried out during the Processor's usual business
              hours and upon reasonable notice which shall be not less than 4
              weeks unless an identifiable material issue has arisen; and (iii)
              conducted in a way which does not interfere with the Processor's
              day-to-day business. The Processor may charge a fee (based on its
              reasonable time and costs) for assisting with any audit. The
              Processor will provide the Controller with further details of any
              applicable fee, and the basis of its calculation, in advance of
              any such audit.
            </p>
            <p>
              This clause shall not modify or limit the rights of audit of the
              Controller, instead it is intended to clarify the procedures in
              respect of any audit undertaken pursuant thereto.
            </p>

            <h3>10. Personal Data Breach</h3>
            <p>
              The Processor shall notify the Controller without undue delay
              after becoming aware of (and in any event within 72 hours of
              discovering) any accidental or unlawful destruction, loss,
              alteration or unauthorised disclosure or access to any Personal
              Data (“Personal Data Breach”).
            </p>
            <p>
              The Processor will take all commercially reasonable measures to
              secure the Personal Data, to limit the effects of any Personal
              Data Breach and to assist the Controller in meeting the
              Controller's obligations under applicable law.
            </p>
            <p>
              The Processor's notification of, or response to, a Personal Data
              Breach under this Section 10 will not be construed as an
              acknowledgement by the Processor of any fault or liability with
              respect to the Personal Data Breach.
            </p>

            <h3>11. Compliance, Cooperation and Response</h3>
            <p>
              The Processor will notify the Controller promptly of any request
              or complaint regarding the processing of Personal Data, which
              adversely impacts the Controller, unless such notification is not
              permitted under applicable law or a relevant court order.
            </p>
            <p>
              The Processor may make copies of and/or retain Personal Data in
              order to comply with any legal or regulatory requirement
              including, but not limited to, retention requirements.
            </p>
            <p>
              The Processor shall reasonably assist the Controller in meeting
              the Controller's obligation to carry out data protection impact
              assessments (DPIAs), taking into account the nature of the
              processing and the information available to the Processor.
            </p>
            <p>
              The Controller shall notify the Processor within a reasonable
              time, of any changes to applicable data protection laws, codes or
              regulations which may affect the contractual duties of the
              Processor. The Processor shall respond within a reasonable
              timeframe in respect of any changes that need to be made to the
              terms of this DPA or to the technical and organisational measures
              to maintain compliance. If the Processor is unable to accommodate
              necessary changes, the Controller may terminate the part or parts
              of the Services which give rise to the non-compliance. To the
              extent that other parts of the Services provided are not affected
              by such changes, the provision of those Services shall remain
              unaffected.
            </p>
            <p>
              The Controller and the Processor and, where applicable, their
              representatives, shall cooperate, on request, with a Supervisory
              Authority in the performance of their respective obligations under
              this DPA and Data Protection Law.
            </p>
            <p>
              The parties agree that the Processor will be entitled to charge
              the Controller additional fees to reimburse the Processor for its
              staff time, costs and expenses in assisting the Controller, when
              the Controller requests the Processor to provide assistance
              pursuant to this DPA. In such cases, the Processor will notify the
              Controller of its fees for providing assistance, in advance.
            </p>

            <h3>12. Liability</h3>
            <p>
              The limitations on liability set out in the Agreement apply to all
              claims made pursuant to any breach of the terms of this DPA.
            </p>
            <p>
              The parties agree that the Processor shall be liable for any
              breaches of this DPA caused by the acts and omissions or
              negligence of its Sub-processors to the same extent the Processor
              would be liable if performing the services of each Sub-processor
              directly under the terms of the DPA, subject to any limitations on
              liability set out in the terms of the Agreement.
            </p>
            <p>
              The parties agree that the Controller shall be liable for any
              breaches of this DPA caused by the acts and omissions or
              negligence of its Affiliates as if such acts, omissions or
              negligence had been committed by the Controller itself.
            </p>
            <p>
              The Controller shall not be entitled to recover more than once in
              respect of the same loss.
            </p>

            <h3>13. Term and Termination</h3>
            <p>
              The Processor will only process Personal Data for the term of the
              DPA. The term of this DPA shall coincide with the commencement of
              the Agreement and this DPA shall terminate automatically together
              with termination or expiry of the Agreement.
            </p>

            <h3>14. Deletion and Return of Personal Data</h3>
            <p>
              The Processor will enable the Controller to delete Personal Data
              using the functionality provided by the Service at any time. For
              certain deletions, a recovery feature is offered by the Processor
              to enable recovery from accidental deletions for up to 30 days.
              This may be overridden by the Controller. After any recovery
              period, the Processor will permanently delete the Personal Data
              from the live systems.
            </p>
            <p>
              On termination, the Controller has the option to request the
              return or deletion of Personal Data. This request must be made
              within 14 days of termination. The Processor will make the data
              available for download by the Controller in a machine readable
              format. The Processor will permanently delete the Personal Data
              from the live systems in any event within 14 days of termination
              of the Agreement.
            </p>
            <p>
              Following permanent deletion from the live systems, partial data
              resides on the Processor's backup systems in accordance with its
              then current data back-up policy. If requested by the Controller,
              the Processor may be able to assist with recovery of partial data
              from these backups during this period. A fee will be charged for
              this service.
            </p>
            <p>
              In addition, where any law, regulation, or government or
              regulatory body requires the Processor to retain any documents or
              materials containing any part or all of the Personal Data, the
              Processor shall be entitled to retain such documents or materials
              for the duration of such retention requirement.
            </p>

            <h3>15. General</h3>
            <p>
              Should a provision of this DPA be invalid or become invalid then
              the legal effect of the other provisions shall be unaffected. A
              valid provision is deemed to have been agreed which comes closest
              to what the parties intended commercially and shall replace the
              invalid provision. The same shall apply to any omissions.
            </p>
            <p>
              Subject to any provisions of the SCCs to the contrary, this DPA
              shall be governed by the laws of England and Wales. The courts of
              England shall have exclusive jurisdiction for the settlement of
              all disputes arising under this DPA.
            </p>
            <p>
              The parties agree that this DPA is incorporated into and governed
              by the terms of the Agreement.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "DataProcessingAgreement",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },

  data() {
    return {
      meta: {
        title: "Data Processing Agreement - Transpond",
        description: "Take a look at the Transpond Data Processing Agreement",
      },
    };
  },
};
</script>
