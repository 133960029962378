<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Achieve more with marketing automations"
      subtitleText="Transpond gives you access to a range of marketing automations, so you can focus on continuing to grow your business. It’s like having a new member of your team."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
      theme="pink"
      video="email-automation"
      title="Unlock the power of automation"
      :steps="[
        {
          title: 'Add a trigger',
          content:
            'Start with a ready-made standard automation like a welcome or Happy Birthday email or create something from scratch.',
        },
        {
          title: 'Set your rules',
          content:
            'You can also set more bespoke conditions for triggers, such as when a contact first joins a particular group or clicks a certain link.',
        },
        {
          title: 'Create an action',
          content:
            'Set what you want to happen once that rule has been activated. Actions could be sending an email or SMS, plus much more.',
        },
        {
          title: 'See the timeline',
          content:
            'You’re good to go in minutes. Once things are running you can watch your automations benefiting your business straight away.',
        },
      ]"
    ></SectionHero>

    <SectionTwoColumnRight
      id="automationexamples"
      title="Automation examples"
      content="Not sure how to get started? Get inspired by these automated workflow templates."
      :accordion="[
        {
          name: 'Post-purchase questionnaire',
          content:
            'Strengthen customer relationships by gathering actionable feedback on products, services, and more.',
          image: 'automation-example-ppq',
        },
        {
          name: 'Birthday discount code',
          content:
            'Create an automated email and delight customers on their birthday with a discount or offer.',
          image: 'automation-example-birthday',
        },
        {
          name: 'New user onboarding',
          content:
            'Schedule a series of welcome emails that help new customers get the most out of your product or service.',
          image: 'automation-example-onboarding',
        },
      ]"
      selectedItem="Post-purchase questionnaire"
    ></SectionTwoColumnRight>

    <SectionTwoColumnHeadingParagraphs
      title="What you should look for when using automations"
      theme="grey"
      :paragraphs="[
        'Some marketing apps make automations difficult to set up and use. If you don’t get an app that is a good match for your business, you could end up investing the time you would save using them in getting them set up, which defeats the purpose. ',
        'That’s why we’ve focussed on making Transpond intuitive and ensuring it guides you through setup. It reduces the need for training, can be widely used across your team and means you can set things up quickly.',
      ]"
    ></SectionTwoColumnHeadingParagraphs>

    <SectionTwoColumnLeft
      id="transactionalemails"
      kicker="Transactional emails"
      title="Send auto-triggered system emails"
      content="Set up emails to be sent directly from your website or app for onboarding, order confirmations and much more. Transpond’s transactional emails are easy to set up, and deliver a full set of analytics to let you see what’s going on."
      button-text="Learn more"
      button-link="TransactionalEmails"
      image="transactional-email"
    ></SectionTwoColumnLeft>
  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnLeft from "@/components/ContentSections/SectionTwoColumnLeft";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionTwoColumnHeadingParagraphs from "@/components/ContentSections/SectionTwoColumnHeadingParagraphs";

export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnLeft,
    SectionTwoColumnRight,
    SectionTwoColumnHeadingParagraphs,
  },
  data() {
    return {
      meta: {
        title: "Automations - Transpond",
        description:
          "Achieve more with marketing automations. Transpond gives you access to a range of marketing automations, so you can focus on continuing to grow your business. It’s like having a new member of your team.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
