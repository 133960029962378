<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader
      title="Contact Us"
      subtitle-text="Need help, have questions or want to suggest a feature?"
    ></PageHeader>

    <section class="section--white">
      <div class="container-fluid">
        <div class="row between-sm pt-10 md:pt-24 pb-40">
          <div class="col-xs-12 col-md-5 mb-10 md:mb-0">
            <form
              id="form19"
              name="form19 "
              class="form__wrapper"
              accept-charset="UTF-8"
              autocomplete="off"
              enctype="multipart/form-data"
              method="post"
              novalidate
              action="https://zestia.wufoo.com/forms/zn6nj6t0nzvdcq/#public"
            >
              <div class="form__field">
                <label class="form__label" id="title3" for="Field3"
                  >Full Name</label
                >
                <div class="text-input">
                  <input
                    id="Field8"
                    name="Field8"
                    type="text"
                    class="text-input__input"
                    v-bind:class="fullNameErr ? 'text-input__input--error' : ''"
                    v-model="fullName"
                    maxlength="255"
                    tabindex="0"
                    placeholder=""
                    required
                  />
                </div>
                <div v-if="fullNameErr" class="form__field-error">
                  {{ fullNameErr }}
                </div>
              </div>

              <div class="form__field">
                <label class="form__label" id="title3" for="Field3"
                  >Email Address</label
                >
                <div class="text-input">
                  <input
                    id="Field3"
                    name="Field3"
                    type="email"
                    spellcheck="false"
                    class="text-input__input"
                    v-bind:class="emailErr ? 'text-input__input--error' : ''"
                    v-model="emailAddress"
                    maxlength="255"
                    tabindex="0"
                    placeholder=""
                    required
                  />
                </div>
                <div v-if="emailErr" class="form__field-error">
                  {{ emailErr }}
                </div>
              </div>

              <div class="form__field">
                <label class="form__label" id="title6" for="Field6">
                  What do you need help with?
                </label>
                <div class="select-input">
                  <select
                    id="Field6"
                    name="Field6"
                    class="select-input__input"
                    v-bind:class="
                      subjectErr ? 'select-input__input--error' : ''
                    "
                    v-model="subject"
                  >
                    <option value="I'm having trouble accessing my account">
                      I'm having trouble accessing my account
                    </option>
                    <option value="Before I sign up I have some questions">
                      Before I sign up I have some questions
                    </option>
                    <option value="I'd like some advice on using Transpond">
                      I'd like some advice on using Transpond
                    </option>
                    <option value="I need help with an Integration">
                      I need help with an Integration
                    </option>
                    <option value="I have a billing question">
                      I have a billing question
                    </option>
                    <option value="I have a great idea for a new feature">
                      I have a great idea for a new feature
                    </option>
                    <option value="I think there's a bug">
                      I think there's a bug
                    </option>
                    <option value="Other">Other</option>
                  </select>
                  <div class="select-input__arrow">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 7L8 11L12 7"
                        stroke="#111111"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="form__field">
                <label class="form__label" id="title4" for="Field4">
                  Your Message <span id="req_4" class="req">*</span>
                </label>
                <div class="text-input">
                  <textarea
                    id="Field4"
                    name="Field4"
                    class="text-input__input"
                    v-bind:class="messageErr ? 'text-input__input--error' : ''"
                    spellcheck="true"
                    rows="10"
                    cols="50"
                    tabindex="0"
                    onkeyup=""
                    placeholder=""
                    required
                    v-model="message"
                  ></textarea>
                </div>
                <div v-if="messageErr" class="form__field-error">
                  {{ messageErr }}
                </div>
              </div>

              <input
                id="saveForm"
                name="saveForm"
                class="btn btn--primary"
                type="button"
                value="Submit"
                v-on:click="goSend()"
              />

              <div style="display: none">
                <textarea
                  name="comment"
                  id="comment"
                  rows="1"
                  cols="1"
                ></textarea>
                <input
                  type="hidden"
                  id="idstamp"
                  name="idstamp"
                  value="N5y7js8I+qh4PRma+/bk2bx7V8KP1AUe43qcoFsxo5o="
                />
                <input
                  type="hidden"
                  id="encryptedPassword"
                  name="encryptedPassword"
                  value=""
                />
              </div>
            </form>
          </div>
          <div class="col-xs-12 col-md-2 mb-10 md:mb-0">
            <h6 class="mb-4">Useful Links</h6>
            <a
              class="text-link mb-4"
              href="https://kb.transpond.io"
              target="_blank"
              >Knowledge Base</a
            >
            <br />
            <a
              class="text-link mb-4"
              v-on:click="TrackingHelpers.linkToApp('apidocs/1')"
              target="_blank"
              >Developer API Docs</a
            >
            <br />
            <router-link class="text-link mb-4" to="/media"
              >Brand Resources</router-link
            >
          </div>

          <div class="col-xs-12 col-md-3 mb-10 md:mb-0">
            <h6 class="mb-4">Transpond HQ</h6>
            <address>
              <p>
                Unit 27 Old St <br />
                Bailie Gate Industrial Estate <br />
                Sturminster Marshall <br />
                Wimborne, Dorset, UK <br />
                BH21 4DB
              </p>
            </address>
          </div>
        </div>
      </div>
    </section>

    <site-footer></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import SiteFooter from "@/components/SiteFooter";
import TrackingHelpers from "@/functions/tracking";
export default {
  name: "ContactUs",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
  },
  setup()
  {
    return { TrackingHelpers }
  },
  data() {
    return {
      emailErr: "",
      subjectErr: "",
      messageErr: "",
      subject: "I'm having trouble accessing my account",
      emailAddress: "",
      message: "",
      fullName: "",
      fullNameErr: "",
      meta: {
        title: "Contact Transpond",
        description: "Get in touch with Transpond",
      },
    };
  },
  methods: {
    goSend() {
      this.emailErr = "";
      this.subjectErr = "";
      this.messageErr = "";
      this.fullNameErr = "";

      let fail = false;

      if (this.fullName == "" || this.fullName.length < 0) {
        this.fullNameErr = "Invalid FullName";
        fail = true;
      }

      if (
        this.emailAddress == "" ||
        this.emailAddress.length < 5 ||
        this.emailAddress.indexOf(".") < 0 ||
        this.emailAddress.indexOf("@") < 0
      ) {
        this.emailErr = "Invalid Email Address";
        fail = true;
      }

      if (this.subject == "" || this.subject.length < 0) {
        this.subjectErr = "Invalid Subject";
        fail = true;
      }

      if (this.message == "" || this.message.length < 3) {
        this.messageErr = "Invalid Message";
        fail = true;
      }

      if (fail) return;

      document.getElementById("form19").submit();
    },
  },
};
</script>
