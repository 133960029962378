<template>
  <svg :class="getClass" viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 2.57515C19.2498 2.90854 18.456 3.12818 17.6438 3.22714C18.4974 2.71594 19.1393 1.9038 19.4492 0.943055C18.6421 1.42569 17.7606 1.76433 16.8425 1.94447C16.4559 1.53007 15.9909 1.2004 15.4758 0.975503C14.9607 0.750606 14.4063 0.635179 13.8463 0.636238C11.5788 0.636238 9.74375 2.48566 9.74375 4.76549C9.74214 5.0826 9.77767 5.39877 9.84958 5.70724C8.22362 5.62929 6.63147 5.20508 5.17499 4.46176C3.71852 3.71844 2.42979 2.67238 1.39125 1.3905C1.02691 2.01867 0.834222 2.7354 0.833333 3.46577C0.833333 4.89759 1.56375 6.16321 2.66667 6.90468C2.01322 6.88882 1.37316 6.71194 0.800833 6.38906V6.4402C0.800833 8.44303 2.2175 10.1092 4.0925 10.4885C3.73991 10.5846 3.37657 10.6333 3.01167 10.6334C2.75274 10.6338 2.4944 10.6081 2.24042 10.5567C2.76167 12.1973 4.27875 13.3905 6.07583 13.4246C4.61557 14.5755 2.82276 15.1976 0.979167 15.193C0.651924 15.1925 0.324986 15.1726 0 15.1334C1.87549 16.3582 4.0556 17.006 6.28083 16.9998C13.8375 16.9998 17.9658 10.7058 17.9658 5.24702C17.9658 5.06804 17.9613 4.88907 17.9529 4.71435C18.7542 4.13142 19.4474 3.40701 20 2.57515Z"/>
  </svg>
</template>

<script>
export default {
  name: "TwitterIcon",
  props: {
    class: {
      type: String
    }
  },
  computed: {
    getClass() {
      return this.class;
    }
  }
}
</script>