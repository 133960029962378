<template>
  <section
    :class="[
      'content-section',
      theme == 'dark'
        ? 'content-section--dark pt-20 sm:pt-40'
        : 'mt-20 sm:mt-40',
      overlay
        ? 'content-section--overlay pb-20 sm:pb-0'
        : theme == 'dark'
        ? 'pb-20 sm:pb-40'
        : 'mb-20 sm:mb-40',
    ]"
  >
    <div class="container-fluid">
      <div class="row between-xs middle-xs">
        <div class="first-sm col-xs-12 col-sm-5">
          <span v-if="kicker" class="heading-kicker mb-2">{{ kicker }}</span>
          <h4 class="mb-6">
            {{ title }}
          </h4>
          <p v-if="content" class="p--lg">{{ content }}</p>
          <router-link
            v-if="buttonLink"
            :to="{ name: buttonLink }"
            title="{{buttonText}}"
            class="btn btn--secondary mt-10"
            >{{ buttonText }}</router-link
          >
        </div>
        <div class="first-xs col-xs-12 col-sm-6 mb-10 sm:mb-0">
          <Image
            :file="image"
            class="content-section__image"
            alt="Test"
            width="610"
            height="456"
          ></Image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";

export default {
  name: "SectionTwoColumnLeft",
  components: { Image },
  props: {
    theme: {
      type: String,
    },
    title: {
      type: String,
      default: "Title",
    },
    kicker: {
      type: String,
    },
    content: {
      type: String,
      default: "Section Content Goes Here",
    },
    buttonText: {
      type: String,
      default: "Button Text",
    },
    buttonLink: {
      type: String,
      default: "",
    },
    image: {
      type: String,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
