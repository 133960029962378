<template>
  <div class="col-xs-12 col-sm-3 page-header__img-container">
    <Image
      :file="image"
      class="page-header__img"
      alt="Test"
      width="395"
      height="728"
      loading="eager"
    ></Image>
  </div>
</template>

<script>
import Image from "../Image";

export default {
  name: "PageHeaderImage",
  components: { Image },
  props: {
    image: {
      type: String,
    },
  },
};
</script>
