<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <div>
    <navigation></navigation>

    <PageHeader
      title="Contact Us"
      subtitle-text="Need help, have questions or want to suggest a feature?"
    ></PageHeader>

    <section class="section--white">
      <div class="container-fluid">
        <div class="row between-sm pt-10 md:pt-24 pb-40">
          <div class="col-xs-12 col-md-5 mb-10 md:mb-0">
            <h2 class="h5 mb-5">Thanks for getting in touch</h2>

            <p>
              <strong
                >Your query has been passed to our support team and you should
                receive a reply shortly.</strong
              >
            </p>

            <p>
              In the meantime, do take a look at our Knowledge Base where you
              can find helpful guides that cover a lot of the most commonly used
              parts of Transpond.
            </p>
          </div>
          <div class="col-xs-12 col-md-2 mb-10 md:mb-0">
            <h6 class="mb-4">Useful Links</h6>
            <a
              class="text-link mb-4"
              href="https://kb.transpond.io/hc/en-us"
              target="_blank"
              >Knowledge Base</a
            >
            <br />
            <a
              class="text-link mb-4"
              v-on:click="TrackingHelpers.linkToApp('apidocs/1')"
              target="_blank"
              >Developer API Docs</a
            >
            <router-link class="text-link mb-4" to="/media"
              >Brand Resources</router-link
            >
          </div>
          <div class="col-xs-12 col-md-3 mb-10 md:mb-0">
            <h6 class="mb-4">Transpond HQ</h6>
            <address>
              <p>
                Unit 27 Old St <br />
                Bailie Gate Industrial Estate <br />
                Sturminster Marshall <br />
                Wimborne, Dorset, UK <br />
                BH21 4DB
              </p>
            </address>
          </div>
        </div>
      </div>
    </section>

    <site-footer></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import SiteFooter from "@/components/SiteFooter";
import TrackingHelpers from "@/functions/tracking";
export default {
  name: "ContactedPage",
  components: { MetaInfo, Navigation, PageHeader, SiteFooter },
  setup()
  {
    return { TrackingHelpers }
  },
  data() {
    return {
      meta: {
        title: `Get In Touch - Transpond`,
        description: "Thanks for getting in touch with Transpond.",
      },
    };
  },
  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
