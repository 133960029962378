<template>
  <section
    :class="[
      'content-section',
      this.theme == 'dark'
        ? 'content-section--dark pt-20 sm:pt-40'
        : 'mt-20 sm:mt-40',
      overlay
        ? 'content-section--overlay pb-20 sm:pb-0'
        : this.theme == 'dark'
        ? 'pb-20 sm:pb-40'
        : 'mb-20 sm:mb-40',
    ]"
  >
    <div class="container-fluid">
      <div class="row between-xs middle-xs">
        <div class="col-xs-12 col-sm-6 mb-10 sm:mb-0">
          <Image
            v-if="image"
            :file="image"
            class="content-section__image"
            alt="Test"
            width="610"
            height="456"
          ></Image>
          <Video v-if="video" :file="video"></Video>

          <div v-for="item in accordion" v-bind:key="item.name">
            <Image
              v-if="item.name === selected"
              :file="item.image"
              class="content-section__image"
              width="610"
            ></Image>
          </div>
        </div>
        <div class="col-xs-12 col-sm-5">
          <span v-if="kicker" class="heading-kicker mb-2">{{ kicker }}</span>
          <h4 class="mb-6">
            {{ title }}
          </h4>
          <p v-if="content" class="p--lg">{{ content }}</p>
          <div v-if="accordion" class="accordion mt-10">
            <AccordionItem
              v-for="item in accordion"
              v-bind:key="item.name"
              class="accordion__item--dark"
              :active="item.name === selected"
              @setSelected="() => setSelected(item)"
            >
              {{ item.name }}
              <template v-slot:content>
                {{ item.content }}
              </template>
            </AccordionItem>
          </div>
          <router-link
            v-if="buttonLink"
            :to="{ name: buttonLink }"
            title="{{buttonText}}"
            class="btn btn--secondary mt-10"
            >{{ buttonText }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";
import Video from "../Video";
import AccordionItem from "../Accordion/AccordionItem";

export default {
  name: "SectionTwoColumnRight",
  components: { Image, AccordionItem, Video },
  props: {
    theme: {
      type: String,
    },
    title: {
      type: String,
      default: "Title",
    },
    kicker: {
      type: String,
    },
    content: {
      type: String,
      default: "Section Content Goes Here",
    },
    buttonText: {
      type: String,
      default: "Button Text",
    },
    buttonLink: {
      type: String,
      default: "",
    },
    image: {
      type: String,
    },
    video: {
      type: String,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    accordion: {
      type: Array,
    },
    selectedItem: {
      type: String,
    },
  },
  data() {
    return {
      selected: this.selectedItem,
    };
  },
  methods: {
    setSelected(item) {
      this.selected = item.name;
    },
  },
};
</script>
