<template>
  <WhiteLabelLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
    theme="dark"
    class="whitelabel"
  >
    <div class="whitelabel-landing pt-40">
      <div class="container-fluid container-fluid--dark">
        <!-- Header -->
        <div class="whitelabel-landing__header">
          <Effect />

          <h1 class="whitelabel-landing__heading text-center text-balance">
            The marketing platform built for agencies
          </h1>

          <p class="whitelabel-landing__subheading mt-4 p--lg text-center">
            White label email marketing so you can build stronger relationships
            with your clients, and grow your agency.
          </p>

          <a
            href="https://tracking.capsulecrm.com/form?am=30494&fid=30425&host=true"
            target="_blank"
            class="mt-10 btn btn--primary whitelabel-landing__cta"
          >
            Get In Touch
          </a>
        </div>

        <!-- Dashboard example -->
        <div class="whitelabel-landing__dashboard mt-20 md:mt-40">
          <img :src="require('@/assets/images/outpost-hq-dashboard.png')" />
        </div>

        <!-- Features -->
        <div class="whitelabel-landing__features mt-20 md:mt-40">
          <h2
            class="whitelabel-landing__features__heading text-center h3 text-balance"
          >
            Everything you need to offer bespoke email marketing to your clients
          </h2>

          <!-- Features grid -->
          <div class="whitelabel-landing__features__grid mt-12">
            <!-- Bespoke billing -->
            <div class="whitelabel-landing__features__card">
              <div class="whitelabel-landing__features__card__text">
                <h3 class="h6">Bespoke Billing</h3>

                <p>
                  Get 20% off the normal price of Transpond, so you can control
                  what you charge for the service.
                </p>
              </div>

              <img
                :src="
                  require('@/assets/images/whitelabel/agency_bespoke-billing.png')
                "
              />
            </div>

            <!-- White Label Domains -->
            <div class="whitelabel-landing__features__card">
              <div class="whitelabel-landing__features__card__text">
                <h3 class="h6">White Label Domains</h3>

                <p>
                  Make Transpond your own by using a custom domain that your
                  clients use when accessing their account.
                </p>
              </div>

              <img
                :src="require('@/assets/images/whitelabel/agency_domains.png')"
              />
            </div>

            <!-- Custom Branding -->
            <div class="whitelabel-landing__features__card">
              <div class="whitelabel-landing__features__card__text">
                <h3 class="h6">Custom Branding</h3>

                <p>
                  Give your clients a consistent experience by customizing the
                  look and feel of Transpond to match your brand.
                </p>
              </div>

              <img
                :src="
                  require('@/assets/images/whitelabel/agency_custom-branding.png')
                "
              />
            </div>
          </div>

          <!-- Subgrid -->
          <div class="whitelabel-landing__features__subgrid mt-14">
            <!-- Account management -->
            <div class="whitelabel-landing__features__subgrid__item">
              <h4 class="p">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.99994 5C9.99994 5.39397 9.92234 5.78407 9.77157 6.14805C9.62081 6.51203 9.39983 6.84274 9.12126 7.12132C8.84268 7.3999 8.51196 7.62087 8.14799 7.77164C7.78401 7.9224 7.3939 8 6.99994 8C6.60597 8 6.21586 7.9224 5.85188 7.77164C5.48791 7.62087 5.15719 7.3999 4.87861 7.12132C4.60004 6.84274 4.37906 6.51203 4.2283 6.14805C4.07753 5.78407 3.99993 5.39397 3.99993 5C3.99993 4.20435 4.31601 3.44129 4.87861 2.87868C5.44122 2.31607 6.20429 2 6.99994 2C7.79558 2 8.55865 2.31607 9.12126 2.87868C9.68386 3.44129 9.99994 4.20435 9.99994 5ZM1.61493 16.428C1.41887 16.3058 1.26139 16.1306 1.1607 15.9227C1.06001 15.7148 1.02022 15.4826 1.04593 15.253C1.23029 13.8041 1.93668 12.4721 3.03275 11.5066C4.12881 10.5412 5.53931 10.0086 6.99994 10.0086C8.46056 10.0086 9.87106 10.5412 10.9671 11.5066C12.0632 12.4721 12.7696 13.8041 12.9539 15.253C13.0119 15.72 12.7819 16.173 12.3839 16.427C10.7772 17.4566 8.90829 18.0027 6.99994 18C5.09134 18.0032 3.22208 17.4575 1.61493 16.428ZM16.2499 5.75C16.2499 5.55109 16.1709 5.36032 16.0303 5.21967C15.8896 5.07902 15.6988 5 15.4999 5C15.301 5 15.1103 5.07902 14.9696 5.21967C14.829 5.36032 14.7499 5.55109 14.7499 5.75V7.75H12.7499C12.551 7.75 12.3603 7.82902 12.2196 7.96967C12.079 8.11032 11.9999 8.30109 11.9999 8.5C11.9999 8.69891 12.079 8.88968 12.2196 9.03033C12.3603 9.17098 12.551 9.25 12.7499 9.25H14.7499V11.25C14.7499 11.4489 14.829 11.6397 14.9696 11.7803C15.1103 11.921 15.301 12 15.4999 12C15.6988 12 15.8896 11.921 16.0303 11.7803C16.1709 11.6397 16.2499 11.4489 16.2499 11.25V9.25H18.2499C18.4488 9.25 18.6396 9.17098 18.7803 9.03033C18.9209 8.88968 18.9999 8.69891 18.9999 8.5C18.9999 8.30109 18.9209 8.11032 18.7803 7.96967C18.6396 7.82902 18.4488 7.75 18.2499 7.75H16.2499V5.75Z"
                    fill="#BAA5F5"
                  />
                </svg>

                Account Management
              </h4>

              <p class="p--sm">
                Manage accounts in one place and control user access from a
                single dashboard.
              </p>
            </div>

            <!-- Custom Mail Servers -->
            <div class="whitelabel-landing__features__subgrid__item">
              <h4 class="p">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.581 4.485C4.69916 3.92372 5.00673 3.42013 5.45213 3.05871C5.89753 2.6973 6.45366 2.50003 7.02725 2.5H12.9697C13.5435 2.49974 14.1 2.69688 14.5456 3.05832C14.9913 3.41976 15.299 3.92351 15.4172 4.485L16.7897 11.0075C16.2265 10.7546 15.6159 10.6242 14.9985 10.625H4.99975C4.36225 10.625 3.756 10.7625 3.2085 11.0075L4.581 4.485Z"
                    fill="#BAA5F5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5 12.5C4.33696 12.5 3.70107 12.7634 3.23223 13.2322C2.76339 13.7011 2.5 14.337 2.5 15C2.5 15.663 2.76339 16.2989 3.23223 16.7678C3.70107 17.2366 4.33696 17.5 5 17.5H15C15.663 17.5 16.2989 17.2366 16.7678 16.7678C17.2366 16.2989 17.5 15.663 17.5 15C17.5 14.337 17.2366 13.7011 16.7678 13.2322C16.2989 12.7634 15.663 12.5 15 12.5H5ZM15 15.9375C15.2486 15.9375 15.4871 15.8387 15.6629 15.6629C15.8387 15.4871 15.9375 15.2486 15.9375 15C15.9375 14.7514 15.8387 14.5129 15.6629 14.3371C15.4871 14.1613 15.2486 14.0625 15 14.0625C14.7514 14.0625 14.5129 14.1613 14.3371 14.3371C14.1613 14.5129 14.0625 14.7514 14.0625 15C14.0625 15.2486 14.1613 15.4871 14.3371 15.6629C14.5129 15.8387 14.7514 15.9375 15 15.9375ZM12.1875 15C12.1875 15.2486 12.0887 15.4871 11.9129 15.6629C11.7371 15.8387 11.4986 15.9375 11.25 15.9375C11.0014 15.9375 10.7629 15.8387 10.5871 15.6629C10.4113 15.4871 10.3125 15.2486 10.3125 15C10.3125 14.7514 10.4113 14.5129 10.5871 14.3371C10.7629 14.1613 11.0014 14.0625 11.25 14.0625C11.4986 14.0625 11.7371 14.1613 11.9129 14.3371C12.0887 14.5129 12.1875 14.7514 12.1875 15Z"
                    fill="#BAA5F5"
                  />
                </svg>

                Custom Mail Servers
              </h4>

              <p class="p--sm">
                Use our default Transpond mail servers or your own
                infrastructure.
              </p>
            </div>

            <!-- Template Control -->
            <div class="whitelabel-landing__features__subgrid__item">
              <h4 class="p">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M15.9929 1.38504C16.3528 1.10791 16.8012 0.97121 17.2544 1.00047C17.7077 1.02973 18.1348 1.22294 18.456 1.54404C18.7772 1.86514 18.9706 2.29217 19 2.74541C19.0295 3.19864 18.8929 3.6471 18.6159 4.00704L14.5859 9.27704C13.4515 10.7604 12.0051 11.9763 10.3489 12.839C10.1442 12.0746 9.74172 11.3775 9.18211 10.8179C8.62249 10.2583 7.9254 9.85583 7.16094 9.65104C8.02379 7.99527 9.23977 6.5492 10.7229 5.41504L15.9929 1.38504ZM5.99994 11C5.20429 11 4.44123 11.3161 3.87862 11.8787C3.31601 12.4413 2.99994 13.2044 2.99994 14C3.00011 14.085 2.97862 14.1686 2.9375 14.243C2.89639 14.3174 2.837 14.38 2.76494 14.425C2.69288 14.4701 2.61054 14.496 2.52568 14.5004C2.44082 14.5048 2.35624 14.4874 2.27994 14.45C2.14553 14.384 1.99443 14.3597 1.84609 14.3803C1.69776 14.4009 1.55898 14.4654 1.44764 14.5656C1.3363 14.6657 1.2575 14.7969 1.22137 14.9423C1.18524 15.0876 1.19345 15.2404 1.24494 15.381C1.56962 16.2617 2.19359 17.0002 3.00771 17.4673C3.82183 17.9345 4.77427 18.1005 5.69843 17.9364C6.6226 17.7723 7.45965 17.2885 8.06318 16.5697C8.66671 15.8508 8.9983 14.9427 8.99994 14.004V14C8.99896 13.4427 8.84325 12.8965 8.55016 12.4225C8.25706 11.9484 7.83809 11.565 7.33994 11.315C6.92385 11.1073 6.46502 10.9994 5.99994 11Z"
                    fill="#BAA5F5"
                  />
                </svg>

                Template Control
              </h4>

              <p class="p--sm">
                Access our library of pre-built templates or build your own
                custom solutions.
              </p>
            </div>

            <!-- Dedicated Support -->
            <div class="whitelabel-landing__features__subgrid__item">
              <h4 class="p">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM8.94 6.94C8.87033 7.00967 8.78763 7.06493 8.6966 7.10263C8.60558 7.14033 8.50802 7.15974 8.4095 7.15974C8.31098 7.15974 8.21342 7.14033 8.1224 7.10263C8.03137 7.06493 7.94867 7.00967 7.879 6.94C7.80933 6.87033 7.75407 6.78763 7.71637 6.6966C7.67867 6.60558 7.65926 6.50802 7.65926 6.4095C7.65926 6.31098 7.67867 6.21342 7.71637 6.1224C7.75407 6.03137 7.80933 5.94867 7.879 5.879C8.2052 5.55275 8.60224 5.30612 9.03927 5.1583C9.4763 5.01047 9.94153 4.96544 10.3988 5.02669C10.8561 5.08794 11.293 5.25383 11.6758 5.51145C12.0585 5.76908 12.3766 6.11151 12.6055 6.5121C12.8343 6.9127 12.9677 7.36068 12.9952 7.82121C13.0227 8.28174 12.9436 8.74242 12.7641 9.16741C12.5846 9.59241 12.3095 9.97028 11.9602 10.2716C11.6108 10.573 11.1967 10.7897 10.75 10.905V11.25C10.75 11.4489 10.671 11.6397 10.5303 11.7803C10.3897 11.921 10.1989 12 10 12C9.80109 12 9.61032 11.921 9.46967 11.7803C9.32902 11.6397 9.25 11.4489 9.25 11.25V10.75C9.25 10.03 9.82 9.578 10.331 9.463C10.558 9.41192 10.7701 9.30871 10.9503 9.16155C11.1306 9.01438 11.2742 8.82729 11.3696 8.61507C11.4651 8.40286 11.5099 8.17133 11.5005 7.93881C11.4911 7.70629 11.4277 7.47916 11.3153 7.27537C11.203 7.07158 11.0447 6.89672 10.8531 6.76464C10.6616 6.63255 10.4419 6.54685 10.2114 6.51432C9.98101 6.48178 9.74618 6.50331 9.52551 6.57719C9.30484 6.65107 9.10438 6.77528 8.94 6.94ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z"
                    fill="#BAA5F5"
                  />
                </svg>

                Dedicated Support
              </h4>

              <p class="p--sm">
                Our support team are on hand to ensure you have everything you
                need.
              </p>
            </div>
          </div>
        </div>

        <hr class="mt-20 md:mt-40" />

        <!-- Our platform, your platform -->
        <div class="whitelabel-landing__platform mt-20 md:mt-40 pb-20 md:pb-40">
          <Effect />

          <div class="whitelabel-landing__platform__header">
            <div class="whitelabel-landing__platform__text">
              <h2 class="whitelabel-landing__platform__heading h3 text-balance">
                Our platform, your platform.
              </h2>

              <p class="whitelabel-landing__platform__subheading mt-2 p--lg">
                The ability to white label Transpond gives you access to a world
                class email marketing solution that provides a ready-made
                revenue stream.
              </p>
            </div>

            <router-link
              to="/features"
              class="btn btn--sm btn--secondary mt-8 mb:mt-0"
            >
              View all features</router-link
            >
          </div>

          <div class="whitelabel-landing__platform__grid mt-12">
            <!-- Create -->
            <router-link
              to="/features/campaignbuilder"
              class="whitelabel-landing__platform__card"
            >
              <!-- Image -->
              <img
                :src="require('@/assets/images/campaign-builder-hero.webp')"
              />

              <!-- Text -->
              <div class="whitelabel-landing__platform__card__text mt-6">
                <h3 class="h6">Create <ArrowRight /></h3>

                <p>Send campaigns that deliver results</p>
              </div>
            </router-link>

            <!-- Automate -->
            <router-link
              to="/features/automations"
              class="whitelabel-landing__platform__card"
            >
              <!-- Image -->
              <img
                :src="
                  require('@/assets/images/automation-example-onboarding-basic.png')
                "
              />

              <!-- Text -->
              <div class="whitelabel-landing__platform__card__text mt-6">
                <h3 class="h6">Automate <ArrowRight /></h3>

                <p>Unlock the power of marketing automation</p>
              </div>
            </router-link>

            <!-- Analyze -->
            <router-link
              to="/features/analytics"
              class="whitelabel-landing__platform__card"
            >
              <!-- Image -->
              <img :src="require('@/assets/images/home/ui.png')" />

              <!-- Text -->
              <div class="whitelabel-landing__platform__card__text mt-6">
                <h3 class="h6">Analyze <ArrowRight /></h3>

                <p>Gain deeper insights and keep improving</p>
              </div>
            </router-link>
          </div>
        </div>

        <!-- Try Transpond for agencies -->
        <div class="whitelabel-landing__try mt-20 mb-30">
          <h2>Try Transpond for agencies</h2>

          <a
            href="https://tracking.capsulecrm.com/form?am=30494&fid=30425&host=true"
            target="_blank"
            class="btn btn--primary mt-8"
          >
            Get In Touch
          </a>
        </div>
      </div>
    </div>
  </WhiteLabelLayout>
</template>

<script>
import WhiteLabelLayout from "@/layouts/WhiteLabelLayout";

const ArrowRight = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4311_299)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L11.7071 12.7071C11.3166 13.0976 10.6834 13.0976 10.2929 12.7071C9.90237 12.3166 9.90237 11.6834 10.2929 11.2929L12.5858 9H1C0.447716 9 -2.41411e-08 8.55228 0 8C2.41411e-08 7.44772 0.447716 7 1 7H12.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4311_299">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Effect = () => (
  <svg
    class="whitelabel-landing__effect"
    width="1440"
    height="493"
    viewBox="0 0 1440 493"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1439.35 214.478C1439.35 252.683 1419.33 289.151 1382.98 322.393C1346.63 355.636 1293.98 385.604 1228.88 410.784C1098.68 461.143 918.763 492.304 720 492.304C521.237 492.304 341.32 461.143 211.118 410.784C146.015 385.604 93.3748 355.636 57.0213 322.393C20.6682 289.151 0.652174 252.683 0.652174 214.478C0.652174 176.274 20.6682 139.806 57.0213 106.563C93.3748 73.3202 146.015 43.3529 211.118 18.1727C341.32 -32.1863 521.237 -63.3478 720 -63.3478C918.763 -63.3478 1098.68 -32.1863 1228.88 18.1727C1293.98 43.3529 1346.63 73.3202 1382.98 106.563C1419.33 139.806 1439.35 176.274 1439.35 214.478Z"
      fill="url(#paint0_radial_4153_7240)"
      stroke="url(#paint1_linear_4153_7240)"
      stroke-width="1.30435"
    />
    <defs>
      <radialGradient
        id="paint0_radial_4153_7240"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(720 444) rotate(-90) scale(291.5 753.667)"
      >
        <stop stop-color="#262524" />
        <stop offset="0.55" stop-color="#292827" />
        <stop offset="1" stop-color="#2B2A29" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_4153_7240"
        x1="720"
        y1="556.5"
        x2="720"
        y2="407.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.4" />
        <stop offset="1" stop-color="#2B2A29" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default {
  name: "WhiteLabelLanding",
  components: {
    WhiteLabelLayout,
    ArrowRight,
    Effect,
  },
  data() {
    return {
      meta: {
        title: "White Label | Transpond",
        description:
          "White label email marketing so you can build stronger relationships with your clients, and grow your agency.",
      },
    };
  },
};
</script>
