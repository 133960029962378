<template>
  <header class="page-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 mb-6">
          <template v-for="category in categories" v-bind:key="category.name">
            <div class="blog__category" :class="category.class">{{ category.name }}</div>
          </template>
        </div>
        <div class="col-xs-12 col-sm-10">
          <h1 class="blog__heading" v-html="postTitle">
          </h1>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "BlogArticleHeader",
  props: {
    title: {
      type: String,
      default: ""      
    }
  },
  data() {
    return {
      categories: [],
      postTitle: ""
    }
  },
  mounted() {
      let categoryData = this.$parent.ourBlog.category;

      if(categoryData && categoryData.length) {
          let categoryList = categoryData.split(',').map((cat) => {
          return {
            name: cat,
            class: "blog__category--" + cat.toLowerCase().replace(' ', '-')
          }
        });

        if(categoryList && categoryList.length){
          this.categories = categoryList;
        }
      }

      if(this.title.length < 1){
        this.postTitle = this.$parent.ourBlog.blogTitle
      }else {
        this.postTitle = this.title;
      }
  }
};
</script>