<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="logo"
    :class="[getClass, colorThemeClass]"
    width="190"
    height="42"
    viewBox="0 0 190 42"
  >
    <path
      d="M11.251 2.66667L6.61659 5.33333L2 2.66667L6.61659 0L11.251 2.66667Z"
    />
    <path
      d="M15.8862 5.33332L11.2518 7.99999L6.61737 5.33332L11.2518 2.66666L15.8862 5.33332Z"
    />
    <path
      d="M20.5201 8.00001L15.8857 10.6667L11.2513 8.00001L15.8857 5.33334L20.5201 8.00001Z"
    />
    <path
      d="M25.154 10.6667L20.5195 13.3333L15.8851 10.6667L20.5195 8L25.154 10.6667Z"
    />
    <path
      d="M29.7702 13.3333L25.1536 16L20.5192 13.3333L25.1536 10.6667L29.7702 13.3333Z"
    />
    <path d="M20.5195 18.6667V24L15.8851 26.6667V21.3333L20.5195 18.6667Z" />
    <path d="M29.7711 13.3333V18.6667L25.1545 21.3333V16L29.7711 13.3333Z" />
    <path d="M11.2518 24V29.3333L6.61737 32V26.6667L11.2518 24Z" />
    <path
      d="M11.251 13.3333L6.61659 16L2 13.3333L6.61659 10.6667L11.251 13.3333Z"
    />
    <path
      d="M15.8862 16L11.2518 18.6667L6.61737 16L11.2518 13.3333L15.8862 16Z"
    />
    <path
      d="M20.5201 18.6667L15.8857 21.3333L11.2513 18.6667L15.8857 16L20.5201 18.6667Z"
    />
    <path d="M6.61659 0V5.33333L2 8V2.66667L6.61659 0Z" />
    <path d="M6.61659 10.6667V16L2 18.6667V13.3333L6.61659 10.6667Z" />
    <path d="M11.251 24L6.61659 26.6667L2 24L6.61659 21.3333L11.251 24Z" />
    <path d="M6.61659 21.3333V26.6667L2 29.3333V24L6.61659 21.3333Z" />
    <path
      d="M46.5668 25.6727H50.3405V9.87041H56.1481V6.64054H41V9.87041H46.5668V25.6727Z"
    />
    <path
      d="M56.8685 25.6727H60.5618V18.9728C60.5618 15.2357 61.4718 13.9278 65.3257 13.9278H66.9583V10.1907H65.7272C62.7297 10.1907 61.0436 11.5254 60.4012 14.0345L59.9195 10.3242H56.8685V25.6727Z"
    />
    <path
      d="M72.5073 26.0998C75.9598 26.0998 78.1544 24.5516 79.2517 22.4695L79.6264 25.6727H82.6507V15.476C82.6507 11.8724 80.5631 9.92379 75.3978 9.92379C70.6874 9.92379 67.9575 12.1393 67.6631 15.7429H71.4903C71.6241 13.821 72.8017 12.7533 75.6119 12.7533C78.1812 12.7533 79.0644 13.5807 79.0644 14.8353C79.0644 16.0365 77.9938 16.3835 75.1301 16.7038C71.8115 17.1042 70.6339 17.3445 69.2689 18.1453C67.9308 18.9194 67.1814 20.2006 67.1814 21.9357C67.1814 24.5249 69.0281 26.0998 72.5073 26.0998ZM73.7652 23.297C71.9988 23.297 71.1959 22.6297 71.1959 21.4552C71.1959 20.7345 71.4636 20.3074 72.1326 19.8803C72.8553 19.4532 73.7384 19.2397 75.826 18.9728C77.405 18.7592 78.5023 18.4656 79.1179 17.9851V18.6524C79.1179 20.8947 76.9768 23.297 73.7652 23.297Z"
    />
    <path
      d="M85.1364 25.6727H88.8832V17.905C88.8832 14.9154 90.1679 13.0736 92.9513 13.0736C95.6276 13.0736 96.5108 14.7286 96.5108 17.3445V25.6727H100.258V16.4369C100.258 12.4596 98.1166 9.92379 94.1824 9.92379C91.399 9.92379 89.5256 11.3118 88.6424 13.4473L88.2944 10.3242H85.1364V25.6727Z"
    />
    <path
      d="M109.452 26.0998C114.27 26.0998 116.625 23.911 116.625 20.9214C116.625 17.5313 113.788 16.8373 110.202 16.4636C107.391 16.1433 105.705 15.9297 105.705 14.6218C105.705 13.6074 106.428 12.8066 108.81 12.8066C111.005 12.8066 112.209 13.4473 112.477 14.9688H116.33C115.768 11.7923 113.52 9.92379 108.863 9.92379C104.314 9.92379 102.066 12.0059 102.066 14.862C102.066 18.2253 105.009 18.9461 108.435 19.3198C111.219 19.5867 112.931 19.8269 112.931 21.1349C112.931 22.3094 112.022 23.0568 109.559 23.0568C107.124 23.0568 105.839 22.3628 105.464 20.601H101.396C101.905 24.2046 104.421 26.0998 109.452 26.0998Z"
    />
    <path
      d="M118.136 29.9999H121.749V23.0835C122.712 24.9253 124.532 26.0197 127.235 26.0197C131.25 26.0197 134.301 23.4038 134.301 18.2787V17.9851C134.301 12.78 131.571 9.92379 127.315 9.92379C124.559 9.92379 122.578 11.2584 121.615 13.3405L121.267 10.3242H118.136V29.9999ZM126.084 22.9233C123.301 22.9233 121.668 21.1082 121.668 18.172V17.9584C121.668 14.9421 123.408 13.0469 126.165 13.0469C128.841 13.0469 130.527 14.7552 130.527 17.9851V18.252C130.527 21.2417 128.975 22.9233 126.084 22.9233Z"
    />
    <path
      d="M144.01 26.0998C149.176 26.0998 152.307 23.217 152.307 18.2787V17.9584C152.307 12.8333 148.935 9.92379 144.037 9.92379C139.247 9.92379 135.794 12.8066 135.794 17.9584V18.2787C135.794 23.2703 138.604 26.0998 144.01 26.0998ZM144.037 22.8966C141.2 22.8966 139.594 21.215 139.594 18.2253V17.9584C139.594 14.7552 141.334 13.0469 144.091 13.0469C146.874 13.0469 148.533 14.8353 148.533 17.9584V18.2253C148.533 21.215 146.954 22.8966 144.037 22.8966Z"
    />
    <path
      d="M154.244 25.6727H157.991V17.905C157.991 14.9154 159.275 13.0736 162.059 13.0736C164.735 13.0736 165.618 14.7286 165.618 17.3445V25.6727H169.365V16.4369C169.365 12.4596 167.224 9.92379 163.29 9.92379C160.506 9.92379 158.633 11.3118 157.75 13.4473L157.402 10.3242H154.244V25.6727Z"
    />
    <path
      d="M178.182 26.0197C181.073 26.0197 182.973 24.6317 183.856 22.5229L184.231 25.6727H187.335V5.99991H183.722V12.9134C182.732 11.0449 180.885 9.95049 178.316 9.95049C174.088 9.95049 171.197 12.6999 171.197 17.7716V18.0919C171.197 23.3504 174.034 26.0197 178.182 26.0197ZM179.306 22.8699C176.657 22.8699 175.024 21.2684 175.024 18.0652V17.825C175.024 14.7819 176.523 12.9935 179.413 12.9935C182.143 12.9935 183.829 14.862 183.829 17.8516V18.0652C183.829 21.0548 182.143 22.8699 179.306 22.8699Z"
    />
    <path
      d="M126.881 38.95V30.5587H127.848V33.6563H127.93C128.001 33.547 128.099 33.4077 128.225 33.2384C128.353 33.0663 128.536 32.9133 128.774 32.7795C129.014 32.6429 129.339 32.5746 129.749 32.5746C130.279 32.5746 130.746 32.7071 131.15 32.972C131.555 33.237 131.87 33.6126 132.097 34.0988C132.323 34.585 132.437 35.1586 132.437 35.8197C132.437 36.4862 132.323 37.0639 132.097 37.5528C131.87 38.0391 131.556 38.416 131.154 38.6837C130.753 38.9487 130.29 39.0811 129.765 39.0811C129.361 39.0811 129.037 39.0142 128.794 38.8804C128.551 38.7438 128.364 38.5895 128.233 38.4174C128.102 38.2426 128.001 38.0978 127.93 37.9831H127.815V38.95H126.881ZM127.831 35.8033C127.831 36.2786 127.901 36.6979 128.04 37.0612C128.18 37.4217 128.383 37.7044 128.651 37.9093C128.919 38.1114 129.246 38.2125 129.634 38.2125C130.039 38.2125 130.376 38.106 130.646 37.8929C130.919 37.6771 131.124 37.3876 131.261 37.0243C131.4 36.6583 131.47 36.2513 131.47 35.8033C131.47 35.3608 131.402 34.962 131.265 34.6069C131.131 34.249 130.928 33.9663 130.654 33.7587C130.384 33.5484 130.044 33.4432 129.634 33.4432C129.241 33.4432 128.91 33.5429 128.643 33.7423C128.375 33.939 128.173 34.2149 128.036 34.57C127.9 34.9224 127.831 35.3335 127.831 35.8033Z"
      fill="#463DFB"
    />
    <path
      d="M134.313 41.3101C134.149 41.3101 134.003 41.2964 133.875 41.2691C133.747 41.2445 133.658 41.2199 133.609 41.1954L133.854 40.3431C134.089 40.4032 134.297 40.4251 134.477 40.4087C134.658 40.3923 134.817 40.3117 134.957 40.1669C135.099 40.0249 135.228 39.7941 135.346 39.4745L135.526 38.9828L133.199 32.6566H134.248L135.985 37.6717H136.051L137.788 32.6566H138.837L136.165 39.8678C136.045 40.1929 135.896 40.4619 135.719 40.675C135.541 40.8908 135.335 41.0506 135.1 41.1544C134.868 41.2582 134.606 41.3101 134.313 41.3101Z"
      fill="#463DFB"
    />
    <path
      d="M150.242 33.181H149.226C149.165 32.8887 149.06 32.632 148.91 32.4107C148.763 32.1895 148.582 32.0037 148.369 31.8535C148.159 31.7005 147.925 31.5858 147.669 31.5093C147.412 31.4328 147.144 31.3946 146.866 31.3946C146.357 31.3946 145.897 31.523 145.485 31.7797C145.075 32.0365 144.749 32.4148 144.505 32.9147C144.265 33.4146 144.145 34.0278 144.145 34.7544C144.145 35.481 144.265 36.0942 144.505 36.5941C144.749 37.0939 145.075 37.4723 145.485 37.729C145.897 37.9858 146.357 38.1142 146.866 38.1142C147.144 38.1142 147.412 38.0759 147.669 37.9994C147.925 37.923 148.159 37.8096 148.369 37.6594C148.582 37.5064 148.763 37.3193 148.91 37.098C149.06 36.8741 149.165 36.6173 149.226 36.3277H150.242C150.165 36.7566 150.026 37.1404 149.824 37.4791C149.622 37.8178 149.37 38.106 149.07 38.3436C148.769 38.5785 148.432 38.7575 148.058 38.8804C147.686 39.0033 147.289 39.0648 146.866 39.0648C146.15 39.0648 145.513 38.8899 144.956 38.5403C144.399 38.1907 143.961 37.6935 143.641 37.0489C143.321 36.4042 143.162 35.6394 143.162 34.7544C143.162 33.8694 143.321 33.1045 143.641 32.4599C143.961 31.8152 144.399 31.3181 144.956 30.9685C145.513 30.6188 146.15 30.444 146.866 30.444C147.289 30.444 147.686 30.5055 148.058 30.6284C148.432 30.7513 148.769 30.9316 149.07 31.1692C149.37 31.4041 149.622 31.6909 149.824 32.0297C150.026 32.3656 150.165 32.7494 150.242 33.181Z"
      fill="#463DFB"
    />
    <path
      d="M153.605 39.0975C153.206 39.0975 152.844 39.0224 152.519 38.8722C152.194 38.7192 151.936 38.4993 151.744 38.2125C151.553 37.923 151.458 37.5733 151.458 37.1636C151.458 36.803 151.529 36.5108 151.671 36.2868C151.813 36.0601 152.003 35.8825 152.24 35.7541C152.478 35.6257 152.74 35.5301 153.027 35.4673C153.316 35.4018 153.607 35.3499 153.9 35.3116C154.282 35.2624 154.592 35.2256 154.83 35.201C155.07 35.1737 155.245 35.1286 155.354 35.0658C155.466 35.0029 155.522 34.8937 155.522 34.738V34.7052C155.522 34.3009 155.411 33.9868 155.19 33.7628C154.972 33.5388 154.64 33.4268 154.195 33.4268C153.733 33.4268 153.371 33.5279 153.109 33.73C152.847 33.9322 152.662 34.148 152.556 34.3774L151.638 34.0496C151.802 33.6672 152.02 33.3695 152.293 33.1564C152.569 32.9406 152.87 32.7904 153.195 32.7057C153.523 32.6183 153.845 32.5746 154.162 32.5746C154.364 32.5746 154.596 32.5992 154.858 32.6484C155.123 32.6948 155.379 32.7918 155.625 32.9393C155.873 33.0868 156.079 33.3094 156.243 33.6071C156.407 33.9049 156.489 34.3037 156.489 34.8035V38.95H155.522V38.0978H155.473C155.407 38.2344 155.298 38.3805 155.145 38.5362C154.992 38.6919 154.789 38.8244 154.535 38.9336C154.281 39.0429 153.971 39.0975 153.605 39.0975ZM153.752 38.2289C154.134 38.2289 154.457 38.1538 154.719 38.0035C154.984 37.8533 155.183 37.6594 155.317 37.4217C155.454 37.1841 155.522 36.9341 155.522 36.6719V35.7869C155.481 35.8361 155.391 35.8811 155.252 35.9221C155.115 35.9604 154.957 35.9945 154.776 36.0245C154.599 36.0519 154.425 36.0764 154.256 36.0983C154.089 36.1174 153.954 36.1338 153.85 36.1475C153.599 36.1802 153.364 36.2335 153.146 36.3073C152.93 36.3783 152.755 36.4862 152.621 36.6309C152.49 36.773 152.425 36.9669 152.425 37.2128C152.425 37.5487 152.549 37.8028 152.797 37.9749C153.049 38.1442 153.367 38.2289 153.752 38.2289Z"
      fill="#463DFB"
    />
    <path
      d="M158.254 41.3101V32.6566H159.188V33.6563H159.303C159.374 33.547 159.472 33.4077 159.598 33.2384C159.726 33.0663 159.909 32.9133 160.147 32.7795C160.387 32.6429 160.712 32.5746 161.122 32.5746C161.652 32.5746 162.119 32.7071 162.523 32.972C162.928 33.237 163.243 33.6126 163.47 34.0988C163.697 34.585 163.81 35.1586 163.81 35.8197C163.81 36.4862 163.697 37.0639 163.47 37.5528C163.243 38.0391 162.929 38.416 162.528 38.6837C162.126 38.9487 161.663 39.0811 161.139 39.0811C160.734 39.0811 160.411 39.0142 160.167 38.8804C159.924 38.7438 159.737 38.5895 159.606 38.4174C159.475 38.2426 159.374 38.0978 159.303 37.9831H159.221V41.3101H158.254ZM159.205 35.8033C159.205 36.2786 159.274 36.6979 159.414 37.0612C159.553 37.4217 159.756 37.7044 160.024 37.9093C160.292 38.1114 160.62 38.2125 161.007 38.2125C161.412 38.2125 161.749 38.106 162.019 37.8929C162.293 37.6771 162.497 37.3876 162.634 37.0243C162.773 36.6583 162.843 36.2513 162.843 35.8033C162.843 35.3608 162.775 34.962 162.638 34.6069C162.504 34.249 162.301 33.9663 162.028 33.7587C161.757 33.5484 161.417 33.4432 161.007 33.4432C160.614 33.4432 160.284 33.5429 160.016 33.7423C159.748 33.939 159.546 34.2149 159.409 34.57C159.273 34.9224 159.205 35.3335 159.205 35.8033Z"
      fill="#463DFB"
    />
    <path
      d="M169.743 34.066L168.874 34.3119C168.82 34.1671 168.739 34.0264 168.633 33.8898C168.529 33.7505 168.387 33.6358 168.206 33.5457C168.026 33.4555 167.795 33.4105 167.514 33.4105C167.129 33.4105 166.808 33.4992 166.551 33.6768C166.297 33.8516 166.17 34.0742 166.17 34.3446C166.17 34.585 166.257 34.7749 166.432 34.9142C166.607 35.0535 166.88 35.1696 167.252 35.2624L168.186 35.4919C168.749 35.6285 169.168 35.8374 169.444 36.1188C169.72 36.3974 169.858 36.7566 169.858 37.1964C169.858 37.5569 169.754 37.8793 169.546 38.1633C169.341 38.4474 169.055 38.6714 168.686 38.8353C168.317 38.9992 167.888 39.0811 167.399 39.0811C166.757 39.0811 166.226 38.9418 165.805 38.6632C165.385 38.3846 165.118 37.9776 165.006 37.4422L165.924 37.2128C166.012 37.5515 166.177 37.8055 166.42 37.9749C166.666 38.1442 166.987 38.2289 167.383 38.2289C167.834 38.2289 168.191 38.1333 168.456 37.9421C168.724 37.7481 168.858 37.516 168.858 37.2455C168.858 37.027 168.781 36.844 168.628 36.6965C168.475 36.5463 168.241 36.4343 167.924 36.3605L166.875 36.1147C166.298 35.9781 165.875 35.7664 165.605 35.4796C165.337 35.1901 165.203 34.8281 165.203 34.3938C165.203 34.0387 165.303 33.7246 165.502 33.4514C165.704 33.1783 165.979 32.9638 166.326 32.8082C166.675 32.6525 167.071 32.5746 167.514 32.5746C168.137 32.5746 168.626 32.7112 168.981 32.9843C169.339 33.2575 169.593 33.6181 169.743 34.066Z"
      fill="#463DFB"
    />
    <path
      d="M175.279 36.3769V32.6566H176.246V38.95H175.279V37.8847H175.214C175.066 38.2043 174.837 38.4761 174.525 38.7001C174.214 38.9213 173.821 39.032 173.345 39.032C172.952 39.032 172.602 38.9459 172.297 38.7738C171.991 38.599 171.75 38.3368 171.575 37.9872C171.401 37.6348 171.313 37.1909 171.313 36.6555V32.6566H172.28V36.59C172.28 37.0489 172.409 37.4149 172.665 37.6881C172.925 37.9612 173.255 38.0978 173.657 38.0978C173.897 38.0978 174.142 38.0363 174.39 37.9134C174.642 37.7905 174.852 37.602 175.021 37.348C175.193 37.0939 175.279 36.7703 175.279 36.3769Z"
      fill="#463DFB"
    />
    <path d="M178.984 30.5587V38.95H178.017V30.5587H178.984Z" fill="#463DFB" />
    <path
      d="M183.394 39.0811C182.788 39.0811 182.265 38.9473 181.825 38.6796C181.388 38.4092 181.05 38.0322 180.813 37.5487C180.578 37.0625 180.46 36.4971 180.46 35.8525C180.46 35.2078 180.578 34.6397 180.813 34.148C181.05 33.6536 181.381 33.2684 181.804 32.9925C182.23 32.7139 182.728 32.5746 183.296 32.5746C183.624 32.5746 183.947 32.6292 184.267 32.7385C184.586 32.8478 184.877 33.0253 185.14 33.2711C185.402 33.5143 185.611 33.8366 185.766 34.2381C185.922 34.6397 186 35.1341 186 35.7213V36.1311H181.149V35.2952H185.017C185.017 34.9401 184.946 34.6233 184.804 34.3446C184.664 34.066 184.465 33.8461 184.205 33.685C183.949 33.5238 183.645 33.4432 183.296 33.4432C182.911 33.4432 182.577 33.5388 182.296 33.73C182.017 33.9185 181.803 34.1644 181.653 34.4676C181.503 34.7708 181.427 35.0958 181.427 35.4427V36C181.427 36.4752 181.509 36.8782 181.673 37.2087C181.84 37.5365 182.071 37.7864 182.366 37.9585C182.661 38.1278 183.003 38.2125 183.394 38.2125C183.648 38.2125 183.878 38.177 184.082 38.106C184.29 38.0322 184.469 37.923 184.619 37.7782C184.769 37.6307 184.886 37.4477 184.967 37.2292L185.902 37.4914C185.803 37.8082 185.638 38.0869 185.406 38.3272C185.174 38.5649 184.887 38.7506 184.545 38.8845C184.204 39.0156 183.82 39.0811 183.394 39.0811Z"
      fill="#463DFB"
    />
  </svg>
</template>
<script>
export default {
  name: "LogoFull",
  props: {
    class: {
      type: String,
    },
    theme: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      colorThemeClass: "",
    };
  },
  mounted() {
    switch (this.theme) {
      case "white":
        this.colorThemeClass = "logo--" + "white";
        break;
      case "purple":
        this.colorThemeClass = "logo--" + "purple";
        break;
      default:
        this.colorThemeClass = "logo--" + "black";
        break;
    }
  },
  computed: {
    getClass() {
      return this.class;
    },
  },
  methods: {},
};
</script>
