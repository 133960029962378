<template>
<footer class="footer footer--blog">
  <div class="container-fluid">
    <div class="row between-sm pb-16 pt-16">
      <div class="footer__legal mb-4 mr-4 mb-sm-0">© 2022 Transpond. All Rights Reserved.</div>
      <div class="footer__legal-links">
        <router-link class="footer__legal-link" to="/terms">Terms</router-link>
        <router-link class="footer__legal-link" to="/privacy">Privacy</router-link>
      </div>
    </div>
  </div>
</footer>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
    };
  },
  ///component has mounted
  created() {
  },

  //functions
  methods: {
    // changeCurrency(newCurr) {
    //   this.currency = newCurr;
    //   this.getPricing();
    // },
  },
};
</script>