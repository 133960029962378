<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <navigation theme="dark"></navigation>

  <section class="about__section pt-30 pb-30">
    <div class="container-fluid">
      <div class="row center-xs">
        <div class="col-xs-12">
          <div class="row center-xs">
            <div class="col-xs-12 col-sm-8 mb-30">
              <h1>The easy to use marketing platform</h1>
            </div>
          </div>
          <div class="col-xs-12 mb-20">
            <div class="about__hero-location">
              <Image
                file="about-hero"
                class="about__hero-image"
                width="1240"
                height="528"
                alt="A view of Bournemouth beach, UK"
              >
              </Image>
              <div class="about__hero-location-decal">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.05826 4.86009C7.63365 3.2847 9.77033 2.39966 11.9983 2.39966C14.2262 2.39966 16.3629 3.2847 17.9383 4.86009C19.5136 6.43547 20.3987 8.57215 20.3987 10.8001C20.3987 13.028 19.5136 15.1647 17.9383 16.7401L11.9983 22.6801L6.05826 16.7401C5.27816 15.9601 4.65934 15.034 4.23715 14.0148C3.81496 12.9956 3.59766 11.9033 3.59766 10.8001C3.59766 9.69691 3.81496 8.60454 4.23715 7.58535C4.65934 6.56616 5.27816 5.64011 6.05826 4.86009ZM11.9983 13.2001C12.6348 13.2001 13.2452 12.9472 13.6953 12.4971C14.1454 12.0471 14.3983 11.4366 14.3983 10.8001C14.3983 10.1636 14.1454 9.55312 13.6953 9.10303C13.2452 8.65294 12.6348 8.40009 11.9983 8.40009C11.3617 8.40009 10.7513 8.65294 10.3012 9.10303C9.85112 9.55312 9.59826 10.1636 9.59826 10.8001C9.59826 11.4366 9.85112 12.0471 10.3012 12.4971C10.7513 12.9472 11.3617 13.2001 11.9983 13.2001Z"
                    fill="#FCD986"
                  />
                </svg>
                <span>Founded in Bournemouth, UK</span>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="row center-xs text-left">
              <div class="col-xs-12 col-sm-6">
                <h6 class="mb-10">
                  Transpond is an easy to use marketing platform that gives you
                  all the tools you need to communicate with your contacts, and
                  also future proofs the way you do it.
                </h6>
                <p>
                  It was founded in Bournemouth in 2009 by an experienced
                  developer and entrepreneur to offer the same features and
                  benefits that more expensive platforms offer, but in a
                  simpler, more straightforward and affordable package.
                </p>
                <p>We've had over 200,000 happy customers since then!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about__section about__section--dark">
    <div class="container-fluid">
      <div class="row between-xs middle-xs">
        <div class="first-sm col-xs-12 col-sm-5">
          <h4 class="mb-6">Brand Resources</h4>
          <p class="p--lg">
            Learn more about Transpond's core features and download useful brand
            assets - including our logo and product screenshots.
          </p>
          <router-link
            to="/media"
            title="Brand resources"
            class="btn btn--secondary mt-10"
            >View brand resources</router-link
          >
        </div>
        <div class="first-xs col-xs-12 col-sm-6 mb-20 sm:mb-0">
          <Image
            file="about-brand-resources"
            class="content-section__image"
            alt="Transpond Logos"
            width="610"
            height="480"
          ></Image>
        </div>
      </div>
    </div>
  </section>

  <site-footer></site-footer>
</template>
<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import Image from "@/components/Image";
import siteType from "../functions/siteType";
import SiteFooter from "@/components/SiteFooter";

var isUkSite = siteType.lookup();
isUkSite = true;

export default {
  name: "AboutUs",
  components: {
    MetaInfo,
    Navigation,
    Image,
    SiteFooter,
  },
  data() {
    return {
      isUkSite: isUkSite,
      meta: {
        title: "About us - Transpond",
        description: "Find out more about Transpond",
      },
    };
  },
};
</script>
