<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Schedule 3"> </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1 mb-30">
          <div class="longform">
            <p>
              International Data Transfer Addendum to the EU Commission Standard
              Contractual clauses
            </p>
            <p style="font-weight: bold">
              VERSION B1.0, in force 21 March 2022
            </p>
            <hr />
            <p>
              This Addendum has been issued by the Information Commissioner for
              Parties making Restricted Transfers. The Information Commissioner
              considers that it provides Appropriate Safeguards for Restricted
              Transfers when it is entered into as a legally binding contract.
            </p>

            <h3>Part 1: Tables</h3>

            <p>Table 1: Parties</p>

            <div class="terms-table terms-table--3-col">
              <table cellPadding="10" cellSpacing="0">
                <tr>
                  <td>
                    <strong>Start Date</strong>
                  </td>
                  <td colspan="2">
                    The date set out in Annex I of the Approved EU SCCs.
                  </td>
                </tr>
                <tr>
                  <td><strong>The Parties</strong></td>
                  <td>
                    <strong
                      >Exporter (who sends the Restricted Transfer)</strong
                    >
                  </td>
                  <td>
                    <strong
                      >Importer (who receives the Restricted Transfer)</strong
                    >
                  </td>
                </tr>
                <tr>
                  <td><strong>Parties details</strong></td>
                  <td>
                    Full legal name: the Customer named in the Agreement. Main
                    address (if a company registered address): As set out in
                    Annex I of the Approved EU SCCs. Official registration
                    number (if any) (company number or similar identifier):
                    Where set out in the Agreement.
                  </td>
                  <td>
                    Full legal name: MPZMail Limited Main address: Level 1, 20
                    Dale Street, Manchester, M1 1EZ, England Official
                    registration number (if any) (company number or similar
                    identifier): 11234241
                  </td>
                </tr>
                <tr>
                  <td><strong>Key Contact</strong></td>
                  <td>
                    Full Name (optional): As set out in Annex I of the Approved
                    EU SCCs. Job Title: As set out in Annex I in the Approved EU
                    SCCs Contact details including email: As set out in Annex I
                    the Approved EU SCCs.
                  </td>
                  <td>
                    Job Title: DPO Contact details including email:
                    support@mpzmail.com
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong
                      >Signature (if required for the purposes of Section
                      2)</strong
                    >
                  </td>
                  <td>no signature is required.</td>
                  <td>no signature is required.</td>
                </tr>
              </table>
            </div>

            <p>Table 2: Selected SCCs, Modules and Selected Clauses</p>

            <div class="terms-table terms-table--3-col">
              <table cellPadding="10" cellSpacing="0">
                <tr>
                  <td>
                    <strong>Addendum EU SCCs</strong>
                  </td>
                  <td>
                    the Approved EU SCCs, including the Appendix Information and
                    with only the following modules, clauses or optional
                    provisions of the Approved EU SCCs brought into effect for
                    the purposes of this Addendum:
                  </td>
                </tr>
              </table>
            </div>

            <div class="terms-table terms-table--3-col">
              <table cellPadding="10" cellSpacing="0">
                <tr>
                  <td>Module</td>
                  <td>Module in operation</td>
                  <td>Clause 11 (Option)</td>
                  <td>Clause 9a General Authorisation</td>
                  <td>Clause 9a (Time period)</td>
                  <td>
                    Is personal data received from the Importer combined with
                    personal data collected by the Exporter?
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>no</td>
                  <td>not used</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>yes</td>
                  <td>not used</td>
                  <td>yes</td>
                  <td>30 days</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>yes</td>
                  <td>not used</td>
                  <td>yes</td>
                  <td>30 days</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>no</td>
                  <td>not used</td>
                  <td>-</td>
                  <td>-</td>
                  <td>no</td>
                </tr>
              </table>
            </div>

            <p>Table 3: Appendix Information</p>
            <p>
              “Appendix Information” means the information which must be
              provided for the selected modules as set out in the Appendix of
              the Approved EU SCCs (other than the Parties), and which for this
              Addendum is set out in:
            </p>
            <div class="terms-table terms-table--3-col">
              <table cellPadding="10" cellSpacing="0">
                <tr>
                  <td>Annex 1A: List of Parties: for Module 2 and Module 3</td>
                  <td>
                    Annex 1B: Description of Transfer: for Module 2 and Module 3
                  </td>
                  <td>
                    Annex II: Technical and organisational measures including
                    technical and organisational measures to ensure the security
                    of the data: for Module 2
                  </td>
                </tr>
              </table>
            </div>

            <p>
              Table 4: Ending this Addendum when the Approved Addendum Changes
            </p>
            <div class="terms-table terms-table--3-col">
              <table cellPadding="10" cellSpacing="0">
                <tr>
                  <td><strong>Ending this Addendum</strong></td>
                  <td>
                    Which Parties may end this Addendum as set out in Section
                    19:
                  </td>
                </tr>
                <tr>
                  <td><strong>when the Approved Addendum changes</strong></td>
                  <td>
                    Importer<br />
                    Exporter
                  </td>
                </tr>
              </table>
            </div>

            <h3>Part 2: Mandatory Clauses</h3>
            <p>Entering into this Addendum</p>
            <ol>
              <li>
                Each Party agrees to be bound by the terms and conditions set
                out in this Addendum, in exchange for the other Party also
                agreeing to be bound by this Addendum.
              </li>
              <li>
                Although Annex 1A and Clause 7 of the Approved EU SCCs require
                signature by the Parties, for the purpose of making Restricted
                Transfers, the Parties may enter into this Addendum in any way
                that makes them legally binding on the Parties and allows data
                subjects to enforce their rights as set out in this Addendum.
                Entering into this Addendum will have the same effect as signing
                the Approved EU SCCs and any part of the Approved EU SCCs.
              </li>
              <div style="margin-left: -20px; margin-bottom: 15px">
                Interpretation of this Addendum
              </div>
              <li>
                Where this Addendum uses terms that are defined in the Approved
                EU SCCs those terms shall have the same meaning as in the
                Approved EU SCCs. In addition, the following terms have the
                following meanings:
                <br />
                <div class="terms-table terms-table--3-col">
                  <table cellPadding="10" cellSpacing="0">
                    <tr>
                      <td>Addendum</td>
                      <td>
                        This International Data Transfer Addendum which is made
                        up of this Addendum incorporating the Addendum EU SCCs.
                      </td>
                    </tr>
                    <tr>
                      <td>Addendum EU SCCs</td>
                      <td>
                        The version(s) of the Approved EU SCCs which this
                        Addendum is appended to, as set out in Table 2,
                        including the Appendix Information.
                      </td>
                    </tr>
                    <tr>
                      <td>Appendix Information</td>
                      <td>As set out in Table 3.</td>
                    </tr>
                    <tr>
                      <td>Appropriate Safeguards</td>
                      <td>
                        The standard of protection over the personal data and of
                        data subjects’ rights, which is required by UK Data
                        Protection Laws when you are making a Restricted
                        Transfer relying on standard data protection clauses
                        under Article 46(2)(d) UK GDPR.
                      </td>
                    </tr>
                    <tr>
                      <td>Approved Addendum</td>
                      <td>
                        The template Addendum issued by the ICO and laid before
                        Parliament in accordance with s119A of the Data
                        Protection Act 2018 on 2 February 2022, as it is revised
                        under Section 18.
                      </td>
                    </tr>
                    <tr>
                      <td>Approved EU SCCs</td>
                      <td>
                        The Standard Contractual Clauses set out in the Annex of
                        Commission Implementing Decision (EU) 2021/914 of 4 June
                        2021.
                      </td>
                    </tr>
                    <tr>
                      <td>ICO</td>
                      <td>The Information Commissioner.</td>
                    </tr>
                    <tr>
                      <td>Restricted Transfer</td>
                      <td>
                        A transfer which is covered by Chapter V of the UK GDPR.
                      </td>
                    </tr>
                    <tr>
                      <td>UK</td>
                      <td>
                        The United Kingdom of Great Britain and Northern
                        Ireland.
                      </td>
                    </tr>
                    <tr>
                      <td>UK Data Protection Laws</td>
                      <td>
                        All laws relating to data protection, the processing of
                        personal data, privacy and/or electronic communications
                        in force from time to time in the UK, including the UK
                        GDPR and the Data Protection Act 2018.
                      </td>
                    </tr>
                    <tr>
                      <td>UK GDPR</td>
                      <td>
                        As defined in section 3 of the Data Protection Act 2018.
                      </td>
                    </tr>
                  </table>
                </div>
              </li>
              <li>
                This Addendum must always be interpreted in a manner that is
                consistent with UK Data Protection Laws and so that it fulfils
                the Parties’ obligation to provide the Appropriate Safeguards
              </li>
              <li>
                If the provisions included in the Addendum EU SCCs amend the
                Approved SCCs in any way which is not permitted under the
                Approved EU SCCs or the Approved Addendum, such amendment(s)
                will not be incorporated in this Addendum and the equivalent
                provision of the Approved EU SCCs will take their place.
              </li>
              <li>
                If there is any inconsistency or conflict between UK Data
                Protection Laws and this Addendum, UK Data Protection Laws
                applies.
              </li>
              <li>
                If the meaning of this Addendum is unclear or there is more than
                one meaning, the meaning which most closely aligns with UK Data
                Protection Laws applies.
              </li>
              <li>
                Any references to legislation (or specific provisions of
                legislation) means that legislation (or specific provision) as
                it may change over time. This includes where that legislation
                (or specific provision) has been consolidated, re-enacted and/or
                replaced after this Addendum has been entered into.
              </li>
              <div style="margin-left: -20px; margin-bottom: 15px">
                Hierarchy
              </div>
              <li>
                Although Clause 5 of the Approved EU SCCs sets out that the
                Approved EU SCCs prevail over all related agreements between the
                parties, the parties agree that, for Restricted Transfers, the
                hierarchy in Section 10 will prevail.
              </li>
              <li>
                Where there is any inconsistency or conflict between the
                Approved Addendum and the Addendum EU SCCs (as applicable), the
                Approved Addendum overrides the Addendum EU SCCs, except where
                (and in so far as) the inconsistent or conflicting terms of the
                Addendum EU SCCs provides greater protection for data subjects,
                in which case those terms will override the Approved Addendum.
              </li>
              <li>
                Where this Addendum incorporates Addendum EU SCCs which have
                been entered into to protect transfers subject to the General
                Data Protection Regulation (EU) 2016/679 then the Parties
                acknowledge that nothing in this Addendum impacts those Addendum
                EU SCCs.
              </li>
              <div style="margin-left: -20px; margin-bottom: 15px">
                Incorporation of and changes to the EU SCCs
              </div>
              <li>
                This Addendum incorporates the Addendum EU SCCs which are
                amended to the extent necessary so that:
                <ol type="a">
                  <li>
                    together they operate for data transfers made by the data
                    exporter to the data importer, to the extent that UK Data
                    Protection Laws apply to the data exporter’s processing when
                    making that data transfer, and they provide Appropriate
                    Safeguards for those data transfers;
                  </li>
                  <li>
                    Sections 9 to 11 override Clause 5 (Hierarchy) of the
                    Addendum EU SCCs; and
                  </li>
                  <li>
                    this Addendum (including the Addendum EU SCCs incorporated
                    into it) is (1) governed by the laws of England and Wales
                    and (2) any dispute arising from it is resolved by the
                    courts of England and Wales, in each case unless the laws
                    and/or courts of Scotland or Northern Ireland have been
                    expressly selected by the Parties.
                  </li>
                </ol>
              </li>
              <li>
                Unless the Parties have agreed alternative amendments which meet
                the requirements of Section 12, the provisions of Section 15
                will apply.
              </li>
              <li>
                No amendments to the Approved EU SCCs other than to meet the
                requirements of Section 12 may be made.
              </li>
              <li>
                The following amendments to the Addendum EU SCCs (for the
                purpose of Section 12) are made.
                <ol type="a">
                  <li>
                    References to the “Clauses” means this Addendum,
                    incorporating the Addendum EU SCCs;
                  </li>
                  <li>
                    In Clause 2, delete the words:<br />“and, with respect to
                    data transfers from controllers to processors and/or
                    processors to processors, standard contractual clauses
                    pursuant to Article 28(7) of Regulation (EU) 2016/679”;
                  </li>
                  <li>
                    Clause 6 (Description of the transfer(s)) is replaced
                    with:<br />“The details of the transfers(s) and in
                    particular the categories of personal data that are
                    transferred and the purpose(s) for which they are
                    transferred) are those specified in Annex I.B where UK Data
                    Protection Laws apply to the data exporter’s processing when
                    making that transfer.”;
                  </li>
                  <li>
                    Clause 8.7(i) of Module 1 is replaced with:<br />“it is to a
                    country benefitting from adequacy regulations pursuant to
                    Section 17A of the UK GDPR that covers the onward transfer”;
                  </li>
                  <li>
                    Clause 8.8(i) of Modules 2 and 3 is replaced with:<br />“the
                    onward transfer is to a country benefitting from adequacy
                    regulations pursuant to Section 17A of the UK GDPR that
                    covers the onward transfer;”
                  </li>
                  <li>
                    References to “Regulation (EU) 2016/679”, “Regulation (EU)
                    2016/679 of the European Parliament and of the Council of 27
                    April 2016 on the protection of natural persons with regard
                    to the processing of personal data and on the free movement
                    of such data (General Data Protection Regulation)” and “that
                    Regulation” are all replaced by “UK Data Protection Laws”.
                    References to specific Article(s) of “Regulation (EU)
                    2016/679” are replaced with the equivalent Article or
                    Section of UK Data Protection Laws;
                  </li>
                  <li>References to Regulation (EU) 2018/1725 are removed;</li>
                  <li>
                    References to the “European Union”, “Union”, “EU”, “EU
                    Member State”, “Member State” and “EU or Member State” are
                    all replaced with the “UK”;
                  </li>
                  <li>
                    The reference to “Clause 12(c)(i)” at Clause 10(b)(i) of
                    Module one, is replaced with “Clause 11(c)(i)”;
                  </li>
                  <li>Clause 13(a) and Part C of Annex I are not used;</li>
                  <li>
                    The “competent supervisory authority” and “supervisory
                    authority” are both replaced with the “Information
                    Commissioner”;
                  </li>
                  <li>
                    In Clause 16(e), subsection (i) is replaced with:<br />“the
                    Secretary of State makes regulations pursuant to Section 17A
                    of the Data Protection Act 2018 that cover the transfer of
                    personal data to which these clauses apply;”;
                  </li>
                  <li>
                    Clause 17 is replaced with:<br />“These Clauses are governed
                    by the laws of England and Wales.”;
                  </li>
                  <li>
                    Clause 18 is replaced with:<br />“Any dispute arising from
                    these Clauses shall be resolved by the courts of England and
                    Wales. A data subject may also bring legal proceedings
                    against the data exporter and/or data importer before the
                    courts of any country in the UK. The Parties agree to submit
                    themselves to the jurisdiction of such courts.”; and
                  </li>
                  <li>
                    The footnotes to the Approved EU SCCs do not form part of
                    the Addendum, except for footnotes 8, 9, 10 and 11.
                  </li>
                </ol>
              </li>
              <div style="margin-left: -20px; margin-bottom: 15px">
                Amendments to this Addendum
              </div>
              <li>
                The Parties may agree to change Clauses 17 and/or 18 of the
                Addendum EU SCCs to refer to the laws and/or courts of Scotland
                or Northern Ireland.
              </li>
              <li>
                If the Parties wish to change the format of the information
                included in Part 1: Tables of the Approved Addendum, they may do
                so by agreeing to the change in writing, provided that the
                change does not reduce the Appropriate Safeguards.
              </li>
              <li>
                From time to time, the ICO may issue a revised Approved Addendum
                which:
                <ol type="a">
                  <li>
                    makes reasonable and proportionate changes to the Approved
                    Addendum, including correcting errors in the Approved
                    Addendum; and/or
                  </li>
                  <li>reflects changes to UK Data Protection Laws;</li>
                </ol>
              </li>
              <div style="margin-left: -20px; margin-bottom: 15px">
                The revised Approved Addendum will specify the start date from
                which the changes to the Approved<br />
                Addendum are effective and whether the Parties need to review
                this Addendum including the Appendix Information. This Addendum
                is automatically amended as set out in the revised Approved
                Addendum from the start date specified.
              </div>
              <li>
                If the ICO issues a revised Approved Addendum under Section 18,
                if any Party selected in Table 4 “Ending the Addendum when the
                Approved Addendum changes”, will as a direct result of the
                changes in the Approved Addendum have a substantial,
                disproportionate and demonstrable increase in:
                <ol type="a">
                  <li>
                    its direct costs of performing its obligations under the
                    Addendum; and/or
                  </li>
                  <li>its risk under the Addendum</li>
                </ol>
                and in either case it has first taken reasonable steps to reduce
                those costs or risks so that it is not substantial and
                disproportionate, then that Party may end this Addendum at the
                end of a reasonable notice period, by providing written notice
                for that period to the other Party before the start date of the
                revised Approved Addendum.
              </li>
              <li>
                The Parties do not need the consent of any third party to make
                changes to this Addendum, but any changes must be made in
                accordance with its terms.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "DataProcessingAgreementSchedule3",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    TermsNav,
  },
  data() {
    return {
      meta: {
        title: "Data Processing Agreement - Schedule 3 - Transpond",
        description:
          "Take a look at the Transpond Data Processing Agreement - Schedule 3",
      },
    };
  },
};
</script>
