<script setup>
  import textHelpers from '../../functions/text';
</script>

<template>
  <div class="mb-20">
    <router-link
      :to="'/blog/' + textHelpers.stringToSlug(blogData.blogTitle) + '/' + blogData.id"
      class="blog__article-listing"
      >
      <template v-if="isNext != true">

        <div class="row middle-xs">
          <div class="col-xs-2 hidden-xs hidden-xvs hidden-vs">
            <div class="blog__article-listing-month-day">
              <div class="blog__article-listing-month">
                {{publishMonth}}
              </div>
              <div class="blog__article-listing-day">
                {{publishDay}}
              </div>
            </div>
          </div>
          <div class="col-sm-9 col-sm-offset-1">
            <h6 class="p text-link mb-2">{{ blogData.blogTitle }}</h6>
            <p class="mb-6" v-html="blogExcerpt"></p>
            <template v-for="category in categories" v-bind:key="category.name">
              <div class="blog__category" :class="category.class">{{ category.name }}</div>
            </template>

          </div>        
        </div>

      </template>
      <template v-if="isNext == true">

        <div class="row middle-xs">
          <div class="col-sm-2 hidden-xs hidden-xvs hidden-vs">
            <div class="blog__article-listing-month-day">
              <div class="blog__article-listing-day">
                Next
              </div>
            </div>
          </div>
          <div class="col-sm-9 col-sm-offset-1">
            <p class="text-link mb-2">{{ blogData.blogTitle }}</p>
          </div>        
        </div>

      </template>

    </router-link>
  </div>
</template>

<script>
export default {
  name: "BlogArticle",
  props: { 
    isNext: {
      type: Boolean,
      default: false
    },
    blogData: {
      type: Object
    }
  },
  data() {
    return {
      categories: [],
      publishDay: 0,
      publishMonth: "",
      blogExcerpt: "",
    }
  },
  mounted() {

    let categoryList = this.blogData.category.split(',').map((cat) => {
      return {
        name: cat,
        class: "blog__category--" + cat.toLowerCase().replace(' ', '')
      }
    });
    
    if(categoryList && categoryList.length){
      this.categories = categoryList;
    }

    let publishDate = new Date(this.blogData.createdAt);
    let monthName = publishDate.toLocaleString('en-GB', {month: 'short'});
    //get the day of the month
    let monthDay = publishDate.getDate();

    this.publishMonth = monthName
    this.publishDay = (monthDay + "").length > 1 ? monthDay : "0" + monthDay; 

    let contentHTML = new DOMParser().parseFromString(this.blogData.content, 'text/html');
    let plainText = contentHTML.body.textContent || "";

    this.blogExcerpt = plainText.substring(0, 200) + "...";
  }
};
</script>