<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Make every visit count with website tracking"
      subtitleText="Discover the journeys your contacts take after they’ve clicked on links in your communications, and set up actions to ensure you’re making the most of every marketing opportunity."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
      theme="blue"
      image="website-tracking-hero"
      title="How website tracking works"
      :steps="[
        {
          title: 'Get started',
          content:
            'Simply copy and paste a single line of javascript into your website source code to enable tracking',
        },
        {
          title: 'Track visits',
          content: `Instantly see your website visitors as they visit your site via Transpond's easy interface`,
        },
        {
          title: 'Set triggers',
          content:
            'Add customers to automations when they visit specific pages on your site',
        },
        {
          title: 'Go deeper',
          content:
            'Advanced users are also able to push and pull any data to or from Transpond via a simple Javascript interface',
        },
      ]"
    ></SectionHero>

    <SectionTwoColumnLeft
      id="whywebsitetrackingisimportant"
      title="Why website tracking is important"
      content="Not every marketing tool allows you to see where your contacts go after they’ve clicked on your emails. That means you’ll be missing out on opportunities, and won’t be exploring your full marketing potential, but you’ll also be paying to handle things that way. Transpond gives you a complete view so you can really maximize your growth."
      image="why-tracking-important"
    ></SectionTwoColumnLeft>
  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnLeft from "@/components/ContentSections/SectionTwoColumnLeft";

export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnLeft,
  },
  data() {
    return {
      meta: {
        title: "Website tracking - Transpond",
        description:
          "Make every visit count with website tracking. Discover the journeys your contacts take after they’ve clicked on links in your communications, and set up actions to ensure you’re making the most of every marketing opportunity.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
