<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <blog-sidebar></blog-sidebar>

    <navigation></navigation>

    <PageHeader title="Insights, tips & announcements"></PageHeader>

    <div class="container-fluid" v-if="dataLoaded === true">
      <div class="row">
        <div class="col-sm-8 col-sm-offset-1">
          <template v-for="(item, index) in blogData" v-bind:key="index">
            <template v-for="article in item" v-bind:key="article.id">
              <blog-article v-bind:blogData="article"></blog-article>
            </template>
          </template>
        </div>
      </div>
    </div>

    <site-footer></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/NavigationBlog";
import SiteFooter from "@/components/Blog/BlogFooter";
import PageHeader from "@/components/Header/PageHeader";
import BlogSidebar from "@/components/Blog/BlogSidebar";
import BlogArticle from "@/components/Blog/BlogArticleListing";
import axios from "axios";

export default {
  name: "BlogPage",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    BlogSidebar,
    SiteFooter,
    BlogArticle,
  },
  data() {
    return {
      blogData: [],
      featuredBlogData: {},
      dataLoaded: false,
      meta: {
        title: "Transpond blog",
        description:
          "Get the latest updates from the Transpond team, and take your  marketing experience even further.",
      },
    };
  },
  created() {
    axios.get("https://api.transpond.io/api/blog").then((response) => {
      this.featuredBlogData = response.data.Blogs[0];

      var totFound = 0;
      var tmpData = [];
      response.data.Blogs.forEach((article, index) => {
        tmpData.push(article);
        totFound++;
        if (totFound > 2 || index >= response.data.Blogs.length - 1) {
          this.blogData.push(tmpData);
          tmpData = [];
          totFound = 0;
        }
      });
      this.dataLoaded = true;
    });
  },
};
</script>
