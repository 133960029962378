<template>
  <video 
    class="content-section__video"
    playsinline="" 
    autoplay=""
    muted="" 
    disableremoteplayback="" 
    :src="require('../assets/images/'+ file + '.mp4')" 
    type="video/mp4"
  ></video>

</template>

<script>

export default {
  name: "VideoComponent",
  props: {
    file: {
      type: String,
      required: true,
    }
  },
};
</script>