<template>
  <header class="page-header mb-0 pt-20">
    <div class="container-fluid">
      <div class="row center-xs">
        <div v-if="kicker" class="col-xs-12 col-sm-10">
          <div class="page-header__kicker--gradient mb-4">
            <img :src="require('@/assets/images/icons/' + kicker.icon + '-filled-' + kicker.theme + '.svg')" />
            {{ kicker.text }}
          </div>
        </div>
        <div class="col-xs-12 col-sm-10">
          <h1 class="mb-4">{{ title }}</h1>
        </div>
        <div class="col-xs-12 col-sm-6">
          <h6 v-if="subtitleText" class="mb-8">
            {{ subtitleText }}
          </h6>
          <router-link
            v-if="buttonText && buttonLink"
            :to="{ name: buttonLink }"
            title="{{ buttonText }}"
            class="btn btn--primary"
            >{{ buttonText }}</router-link
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeaderFeature",
  components: {
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitleText: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    buttonLink: {
      type: String,
    },
    kicker: {
      type: Object,
    }
  },
};
</script>
