class SiteTypeLookup {

    static lookup() {
        var isUkSite = false;
        var thisHostName = window.location.hostname.toLowerCase();
        if (thisHostName.indexOf(".co.uk") > -1) isUkSite = true;

        return isUkSite;
    }
}

export default SiteTypeLookup;