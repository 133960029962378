<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Schedule 2"> </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1 mb-30">
          <div class="longform">
            <h2>Technical and Organisational Security Measures</h2>
            <h2>
              (Including Technical and Organisational Measures to Ensure the
              Security of Data)
            </h2>

            <p>
              Below is a description of the technical and organisational
              measures implemented by the Processor(s) / Data Importer(s)
              (including any relevant certifications) to ensure an appropriate
              level of security, taking into account the nature, scope, context
              and purpose of the processing, and the risks for the rights and
              freedoms of natural persons.
            </p>
            <p>
              Full details of the Processor's/Data Importer's technical and
              organisational security measures used to protect Personal Data can
              be requested.
            </p>
            <p>
              Where applicable this Schedule 2 will serve as Annex II to the
              SCCs.
            </p>

            <div class="terms-table terms-table--2-col">
              <table>
                <thead>
                  <th>
                    <strong>Measure</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <th>Measures for encryption of Personal Data</th>
                    <td>
                      Data transmitted between customers and our application is
                      encrypted over public networks using Transport Layer
                      Security (“TLS”).
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring ongoing confidentiality, integrity,
                      availability and resilience of processing systems and
                      services
                    </th>
                    <td>
                      <p>
                        The Processor utilises Digital Ocean with extensive
                        application and infrastructure monitoring with 24x7
                        application support rosters.
                      </p>
                      <p>
                        We maintain redundancy throughout our IT infrastructure
                        in order to minimize the lack of availability to or loss
                        of data.
                      </p>
                      <p>
                        We maintain redundancy throughout our IT infrastructure
                        in order to minimize the lack of availability to or loss
                        of data.
                      </p>
                      <p>
                        The operation of Processor's service requires that some
                        employees have access to the systems which store and
                        process Personal Data. These employees are prohibited
                        from using these permissions to view Personal Data
                        unless it is necessary to do so. All of the Processor's
                        employees and contract personnel are bound to our
                        policies regarding Personal Data and we treat these
                        issues as matters of the highest importance within our
                        company. All staff employment contracts have full
                        confidentiality clauses and all employees are required
                        to read and sign our comprehensive information security
                        policy covering the security, availability, and
                        confidentiality of Processor's service.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring the ability to restore the
                      availability and access to Personal Data in a timely
                      manner in the event of a physical or technical incident
                    </th>
                    <td>
                      Data is stored in duplicate. Should a single duplicate
                      fail the service can be restored without data loss. In
                      addition frequent backups are taken so in the unlikely
                      event of both original copies of the data being lost the
                      backups can be used to restore the service with minimal
                      loss of data.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Processes for regularly testing, assessing and evaluating
                      the effectiveness of technical and organisational measures
                      in order to ensure the security of the processing
                    </th>
                    <td>
                      We strive to automate audits hence the majority of our
                      monitoring of our infrastructure is automated and running
                      24/7. We obtain an external security and compliance audit
                      once per calendar year.
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for user identification and authorisation</th>
                    <td>
                      <p>
                        Passwords for authentication are salted and hashed using
                        industry standard hashing functions.
                      </p>
                      <p>
                        The Controller invites and removes users and applies
                        permission levels in the account. Users can enable Two
                        Factor Authentication to provide additional protection.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring physical security of locations at
                      which Personal Data are processed
                    </th>
                    <td>
                      The Service is hosted and Personal Data is stored within
                      data centres provided by Digital Ocean (DO). As such, the
                      Processor relies on the physical, environmental and
                      infrastructure controls of DO. The Processor periodically
                      reviews certifications and third-party attestations
                      provided by DO relating to the effectiveness of its data
                      centre controls.
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring events logging</th>
                    <td>
                      System events are recorded in the form of log files
                      therefore it is possible to review retroactively whether
                      and by who Personal Data was entered, altered or deleted.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring system configuration, including
                      default configuration
                    </th>
                    <td>
                      <p>
                        Security patches are applied to the application
                        dependencies as issues are identified via 3rd party
                        monitoring platform(s).
                      </p>
                      <p>
                        Server operating systems and third party tools are
                        updated automatically when new updates are available.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for internal IT and IT security governance and
                      management
                    </th>
                    <td>
                      Access to systems which store and process Personal Data is
                      subject to role based need. Employees have individual
                      logins for such systems. Two factor authentication must be
                      enabled where available.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for certification/assurance of processes and
                      products
                    </th>
                    <td>
                      The Processor utilises third party data centres that
                      maintain current ISO 27001 certifications and SOC 2
                      Attestation Reports. The Processor will not utilise third
                      party data centres used for primary storage that do not
                      maintain the aforementioned certifications and/or
                      attestations, or other substantially similar or equivalent
                      certifications and/or attestations.
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring data minimisation</th>
                    <td>
                      <p>
                        The Controller decides the Personal Data to be stored on
                        the Service and is responsible for defining its own
                        policies for minimising data collection and storage.
                      </p>
                      <p>
                        If Personal Data is no longer required the Controller
                        can delete it from the service. See “Measures for
                        ensuring limited data retention”.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring data quality</th>
                    <td>
                      We do not assess the quality of the data provided by the
                      Controller. We provide reporting tools within our product
                      to help the Controller understand and validate the data
                      that is stored.
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring limited data retention</th>
                    <td>
                      <p>
                        The Controller is responsible for defining its own
                        retention policies and using the tools provided by the
                        Service to delete Personal Data.
                      </p>
                      <p>
                        If Personal Data is no longer required the Controller
                        can delete it from the service. It should be noted that
                        with each deletion the data is in the first instance
                        locked and then permanently deleted from the production
                        system after a certain delay. This is done in order to
                        prevent accidental deletions.
                      </p>
                      <p>
                        Cancellation or Termination of the Service will also
                        automatically result in permanent deletion of Personal
                        Data after a certain recovery period.
                      </p>
                      <p>
                        Following permanent deletion from the live systems,
                        partial data resides on the Processor's backup archives
                        and is removed over time in line with our
                        <a href="/dpa_cancellation">data retention policy</a>.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring accountability</th>
                    <td>
                      <p>
                        The Processor has designated local representation in
                        Europe and the United Kingdom.
                      </p>
                      <p>
                        Our contact details in the United Kingdom
                        <a href="mailto:support@mpzmail.com"
                          >support@mpzmail.com</a
                        >.
                      </p>
                      <p>Contact details of our European representative</p>
                      <ul>
                        <li>
                          Email:
                          <a href="mailto:mpzmail@datarep.com"
                            >mpzmail@datarep.com</a
                          >
                        </li>
                        <li>
                          Online webform:
                          <a
                            href="https://www.datarep.com/data-request"
                            target="_blank"
                            rel="noopener noreferrer"
                            >https://www.datarep.com/data-request</a
                          >
                        </li>
                        <li>
                          Addresses:
                          <a href="/gdprcontactlocations"
                            >View all EU rep contact locations</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for allowing data portability and ensuring
                      erasure
                    </th>
                    <td>
                      The Service has built-in tools that allow the Controller
                      to export and permanently erase data.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures to be taken by the (Sub-) processor to be able to
                      provide assistance to the Controller (and, for transfers
                      from a Processor to a Sub-processor, to the Data
                      Exporter).
                    </th>
                    <td>
                      The transfer of Personal Data to a third party (e.g.
                      customers, sub-contractors, service providers) is only
                      made if a corresponding contract exists, and only for the
                      specific purposes. If Personal Data is transferred outside
                      the EEA, the Processor provides that an adequate level of
                      data protection exists at the target location or
                      organisation in accordance with the European Union's data
                      protection requirements, e.g. by employing contracts based
                      on the EU SCCs.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "DataProcessingAgreementSchedule2",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    TermsNav,
  },
  data() {
    return {
      meta: {
        title: "Data Processing Agreement - Schedule 2 - Transpond",
        description:
          "Take a look at the Transpond Data Processing Agreement - Schedule 2",
      },
    };
  },
};
</script>
