<template>
  <teleport to="head" v-if="title !== ''">
    <title>{{ title }}</title>
    <meta name="description" :content="description" />
    <meta v-if="noindex" name="robots" :content="noindex" />

    <meta property="og:title" :content="title" />
    <meta property="og:description" :content="description" />
    <meta property="og:site_name" :content="meta.siteName" />
    <meta property="og:type" :content="meta.type" />
    <meta property="og:image" :content="meta.image" />
    <meta property="og:url" :content="meta.url" />

    <meta name="twitter:title" :content="title" />
    <meta name="twitter:description" :content="description" />
    <meta name="twitter:image" :content="meta.image" />
    <meta name="twitter:site" :content="meta.url" />
    <meta name="twitter:creator" :content="meta.creator" />
  </teleport>
</template>
<script>
export default {
  name: "MetaInfo",
  data() {
    return {
      meta: {
        image: "@/assets/transpond-logo.png",
        url: window.location,
        type: "website",
        siteName: "Transpond",
        creator: "@MPZMail",
      },
    };
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    noindex: {
      type: String,
    },
  },
};
</script>
