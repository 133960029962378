<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <div>
    <navigation></navigation>
    <PageHeader title="Simple, transparent pricing"></PageHeader>
    <pricing-table></pricing-table>

    <customer-logos
      class="mt-20 mb-20"
      :isInverted="false"
      heading="You'll be in good company..."
    ></customer-logos>

    <site-footer></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import SiteFooter from "@/components/SiteFooter";
import PricingTable from "@/components/Pricing/PricingTable";
import CustomerLogos from "@/components/CustomerLogos";

export default {
  name: "PricingPage",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    PricingTable,
    CustomerLogos,
  },
  data() {
    return {
      meta: {
        title: "Pricing - Transpond",
        description: "Simple, transparent pricing - Transpond",
      },
    };
  },

  mounted() {},
};
</script>
