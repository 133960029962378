<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Restricted Transfers"> </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1 mb-30">
          <div class="longform">
            <p>
              The parties agree that the EU SCCs shall apply to Restricted
              Transfers from the EEA. The EU SCCs shall be deemed entered into
              (and incorporated into this DPA by reference) and completed as
              follows:
            </p>
            <ol type="I">
              <li>
                Module One (Controller to Controller) shall apply where we are
                processing Customer account data for our own purposes.
              </li>
              <li>
                Module Two (Controller to Processor) shall apply where the
                Customer is a Controller of Customer Data were are processing
                Customer Data.
              </li>
              <li>
                Module Three (Processor to Processor) shall apply where we are a
                Processor of the Customer Data and the we use a Sub-processor to
                process the Customer Data.
              </li>
              <li>
                In Clause 7 of the EU SCCs, the optional docking clause will not
                apply;
              </li>
              <li>
                In Clause 9 of the EU SCCs Option 2 applies, and the time period
                for giving notice of Sub-processor changes shall be 30 days;
              </li>
              <li>
                In Clause 11 of the EU SCCs, the optional language shall not
                apply;
              </li>
              <li>
                In Clause 17 of the EU SCCs, Option 1 applies and the EU SCCs
                shall be governed by Irish law;
              </li>
              <li>
                In Clause 18(b) of the EU SCCs, disputes shall be resolved by
                the courts of Ireland;
              </li>
              <li>
                Annex I of the EU SCCs shall be deemed completed with the
                information set out in Schedule 1 of this DPA;
              </li>
              <li>
                Annex II of the EU SCCs shall be deemed completed with the
                information set out in Schedule 2 of this DPA;
              </li>
            </ol>
            <p>
              The Parties agree that the EU SCCs as amended above in clauses (i)
              to (x), shall be adjusted as set out below where the FDPA applies
              to any Restricted Transfer:
            </p>
            <ol type="I">
              <li>
                The Swiss Federal Data Protection and Information Commissioner
                (“FDPIC”) shall be the sole Supervisory Authority for Restricted
                Transfers exclusively subject to the FDPA;
              </li>
              <li>
                Restricted Transfers subject to both the FDPA and the EU GDPR,
                shall be dealt with by the EU Supervisory Authority named in
                Schedule 1 of this DPA;
              </li>
              <li>
                The term 'member state' must not be interpreted in such a way as
                to exclude data subjects in Switzerland from the possibility of
                suing for their rights in their place of habitual residence
                (Switzerland) in accordance with Clause 18I of the EU SCCs;
              </li>
              <li>
                Where Restricted Transfers are exclusively subject to the FDPA,
                all references to the GDPR in the EU SCCs are to be understood
                to be references to the FDPA;
              </li>
              <li>
                Where Restricted Transfers are subject to both the FDPA and the
                EU GDPR, all references to the GDPR in the EU SCCs are to be
                understood to be references to the FDPA insofar as the
                Restricted Transfers are subject to the FDPA;
              </li>
              <li>
                The Swiss SCCs also protect the Personal Data of legal entities
                until the entry into force of the revised FDPA.
              </li>
            </ol>

            <p>
              The parties agree that the UK SCCs shall apply to Restricted
              Transfers from the UK and the UK SCCs shall be deemed entered into
              (and incorporated into this DPA by reference), completed as
              follows:
            </p>
            <ol type="I">
              <li>
                Appendix 1 of the UK SCCs shall be deemed completed with the
                information set out in Schedule 1 of this DPA; and
              </li>
              <li>
                Appendix 2 of the UK SCCs shall be deemed completed with the
                information set out in Schedule 2 of this DPA.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "DataProcessingAgreementRestrictedTransfers",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Data Processing Agreement - Restricted Transfers - Transpond",
        description:
          "Take a look at the Transpond Data Processing Agreement - Restricted Transfers",
      },
    };
  },
};
</script>
