<template>

<section>

  <div class="container-fluid">
    <div class="row pt-20">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1">
        <div class="row between-xs">
          <div class="col-xs-12 col-md-7 mb-10">
            <div class="row">
              <div class="col-xs-12">
                <Tabs>
                  <Tab title="Overview">
                    <div class="longform" v-html="this.$parent.ourApp.appLongDescription">
                    </div>
                  </Tab>
                  <Tab title="Features">
                    <div class="longform">
                      <ol>
                        <li
                          v-for="(feature, featureIndex) in this.$parent.ourApp.features"
                          v-bind:key="featureIndex">
                          <h6 class="mb-3">{{ feature.title }}</h6>
                          <p>{{ feature.details }}</p>
                        </li>
                      </ol>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 first-xs last-md">
            
              <dl class="integration-meta-block">
                <header class="integration-meta-block__header p-6">
                  <div class="row">
                    <div class="integration-meta-block__header-logo mr-3">
                      <LogoMark
                        theme="purple"
                      ></LogoMark>
                    </div>
                    <div>
                      <dt class="p--xs">
                        Built by
                      </dt>
                      <dd>
                        <strong>Transpond</strong>
                      </dd>
                    </div>
                  </div>
                </header>

                <div class="integration-meta-block__links p-6">

                  <div class="mb-5">
                    <dt class="p--sm integration-meta-block__links-header">Category</dt>
                    <dd><strong>{{this.$parent.ourApp.category}}</strong></dd>
                  </div>
                  <div>
                    <dt class="p--sm integration-meta-block__links-header">Installs</dt>
                    <dd><strong>{{this.$parent.ourApp.totalInstalls}}</strong></dd>
                  </div>

                </div>
              </dl>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</template>

<script>

import Tabs from "../Tabs"
import Tab from "../Tab"  
import LogoMark from "../Svgs/Logo/LogoMark"

export default {
  name: "IntegrationTabs",
  components: {
    Tabs,
    Tab,
    LogoMark
  },
  props: {


  },
  methods: {

  }
}

</script>