<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <navigation></navigation>

  <PageHeader title="Affiliate Program Terms"> </PageHeader>
  <div class="container-fluid">
    <div class="row mb-30">
      <div class="col-xs-12 col-md-3 mb-20">
        <TermsNav></TermsNav>
      </div>
      <div class="col-xs-12 col-md-6 col-md-offset-1">
        <div class="longform">
              By signing up to be an affiliate in the Transpond Affiliate Program (the "Program") you agree to be bound by the following terms and conditions (the "Terms"). 
              Please ensure that you read them carefully before signing up. 
              These Terms are a legal agreement between MPZMail Limited trading as Transpond, a company registered in England and Wales under company number 11234241 and having
              its registered office at 20 Dale St, Manchester, M1 1EZ ("Transpond", "we", "us") and You (the "Affiliate", "you").
              <br/><br/>
              We reserve the right to update and change the Terms from time to time without notice. 
              Any amendments, modifications, enhancements or changes to the Program including the release of new features and resources made available by us from time to time 
              shall be subject to these Terms. 
              Continued use of the Program after any such changes shall constitute your consent to such changes. 
              You can review the most current version of the Terms at any time on our website.
              <br/><br/>
              Any violation of these Terms may result in, among other things, termination or suspension of your rights to be an Affiliate and forfeiture of 
              any outstanding affiliate referral fee payments earned during the violation.
              <br/><br/>
              <h3>Account Registration & Terms</h3>
              You must provide your legal full name, a valid e-mail address, and any other information requested in order to complete the sign up process for an Affiliate account 
              ("account"). For the term of this Agreement you are responsible for keeping all account information up to date.
              <br/><br/>
              You must be 18 years of age or older to join this Program.
              <br/><br/>
              Each account is for use by either a single legal entity (e.g. a company or a partnership) or an individual user. 
              We do not permit you to share your user name and password with any other person nor with multiple users on a network. 
              Responsibility for the security of any usernames and passwords issued (including those of any Invitees) rests with you.
              <br/><br/>
              You may not use the Program for any illegal or unauthorized purpose. You must not, in the use of the Program, 
              violate any laws in your jurisdiction (including but not limited to copyright laws).
              <br/><br/>
              You may not use the Program to earn referral fees on your own accounts of the Transpond service ("services").
              <br/><br/>
              <h3>Referral Links & Promotion</h3>
              Once you have signed up for the Program you will be provided with a URL link that must be used to identify you when placing a link from your site,
              e-mail or other communications to the Transpond website. It is your responsibility to ensure each such link is correctly formatted.
              <br/><br/>
              We may also provide graphical images that can be used within the links to promote Transpond. 
              You may not modify these images in any way. We reserve the right to change the images at any time without notice.
              <br/><br/>
              Affiliate links must point to the Transpond application (app.transpond.io). 
              Links pointed at any other Transpond site (For example the marketing site www.transpond.io) will not be counted.
              <br/><br/>
              You will be solely responsible for the development, operation, and maintenance of your site and for all materials that appear on your site.
              <br/><br/>
              From time to time we may decide to offer you discount codes to use when promoting Transpond. 
              You are only authorized to use discount codes which have been assigned specifically to you. 
              In addition, you may not place any discount code offered by Transpond on any coupon or discounts website.
              <br/><br/>
              <h3>Inappropriate Advertising and Promotion</h3>
              You must not engage in any form of inappropriate or unethical advertising and promotion.
              We have the sole right to decide whether a promotion method used by you is appropriate or inappropriate.
              <br/><br/>
              Inappropriate forms of advertising and promotion include, but are not limited to:
              <ul>
                <li>Using any illegal or spam method of advertising like unsolicited e-mail, repeated placing of your referral link in forums, newsgroups, message boards, social media sites etc. We will not tolerate any form of spam or unsolicited e-mail.</li>
                <li>Links which contain altered or deceptive source identifying information. For example: links must not mislead customers into believing that they are dealing with Us directly, or which falsely state or otherwise misrepresent your affiliation with Us or to the Transpond website.</li>
                <li>Targetting or placing advertisements on keywords and phrases containing our trademark, or variations or misspellings of the trademarked term. This means for example, that you may not bid on Pay per Click or Pay per Impression campaigns on any keywords or phrases containing our trademarks or registered URLs, or on any variations, abbreviations, or misspellings of the same.</li>
                <li>Other than on websites you own, or where you have our prior approval, you may not place advertisements that in any way advertise, display or contain our trademark (Transpond) or our registered URL (transpond.io) or any variation, abbreviation, or misspelling of the same.</li>
                <li>Providing cash backs, rewards, or any other kind of incentives without prior approval from us.</li>
                <li>Using our advertising materials, trademark, or name in a way that is detrimental to our image, as determined at our sole discretion.</li>
                <li>Using iframes or any other techniques or technology to add a tracking cookie to a referral by any means other than an actual click-through.</li>
                <li>Your domain name(s), social media profiles, or any other online or offline presence must NOT contain keywords and phrases containing our trademark or any other variations or misspellings confusingly similar to our trademark, name, or domain name.</li>
                <li>You may not place any discount code offered by Transpond on any coupon or discounts website.</li>
              </ul>
              <br/><br/>
              <h3>Referral Fees</h3>
              For the sale of a subscription to be eligible to earn a referral fee:
              <ul>
                <li>The customer must not have already visited the Transpond website or application via another source within the past 30 days;</li>
                <li>And the customer must click-through a link from your site, e-mail, or other communications to the Transpond website and sign up within 30 days of the initial click-through. If they fail to sign up within those 30 days and later return without following your link, you will not earn a referral fee</li>
              </ul>
              <br/><br/>
              We will only pay referral fees on links that are automatically tracked and reported by our systems. For our systems to track the referral, the visitor must have cookies enabled. We will not pay referral fees if someone says they signed up through you but it was not tracked by our system.
              <br/><br/>
              The referral fee is 20% of our revenue from customers that you refer. The referral fee will be credited to your Affiliate account when the customer pays their subscription.
              <br/><br/>
              <h3>Payment</h3>
              Accrued referral fees are paid via PayPal roughly once per month and 3 months in arrears. You must have a valid PayPal account to receive referral fees, as we don’t offer payment via cheque/check, credit card, cash or other method. If you do not keep your PayPal account, e-mail address or billing details up to date We cannot make payments to you. We will accrue a maximum of three months commission and then we reserve the right to delete Your account at any time where you fail to keep such details up to date. From the date of deletion of Your account any referral fees already accrued will automatically lapse and shall no longer be payable to You and You will cease to be entitled to accrue any future referral fees for any referrals made thereafter.
              <br/><br/>
              Where the customer has paid a subscription in US dollars, we will use the relevant Pounds Sterling pricing for the same subscription from our pricing page.
              <br/><br/>
              Customer payments refunded or payments charged-back due to credit card fraud do not qualify for referral fees. We may delay crediting of referral fees subject to risk analysis considerations and Anti-Money Laundering procedures.
              <br/><br/>
              A summary of Your customers and payment history is available to the Affiliate by logging into the Affiliate area on their account.
              <br/><br/>
              The referral fee structure is subject to change at our discretion.
              <br/><br/>
              We reserve the right to disqualify referral fees earned through fraudulent, illegal, or overly aggressive, questionable sales or marketing methods.
              <br/><br/>
              All fees are exclusive of all taxes, charges, levies, assessments and other fees of any kind imposed on your involvement in this Agreement and shall be the responsibility of, and payable by you.
              <br/><br/>
              <h3>Customer Definition</h3>
              Every customer who buys a service through this program is deemed to be a customer of Transpond. Accordingly, all of our rules, policies, and operating procedures concerning pricing, customer orders, customer service, and services sales will apply to those customers. We may change our policies and operating procedures at any time. Transpond is not responsible for any representations made by the Affiliate that contradict our rules, policies or operating procedures.
              <br/><br/>
              <h3>Pricing & Availability</h3>
              We will determine the prices to be charged for services sold under this Program in accordance with our own pricing policies. Services prices and availability may vary from time to time. Because price changes may affect services that you have listed on your site, you should not display services prices on your site. We will use commercially reasonable efforts to present accurate information, but we cannot guarantee the availability or price of any particular service.
              <br/><br/>
              <h3>Copyrighted and Trademarked material</h3>
              You are solely responsible for ensuring that your reviews, product descriptions and articles (if applicable at your site) obey all applicable copyright, trademark, and other laws. Transpond will not be responsible if you use another party's copyrighted or trademarked material in violation of the law.
              <br/><br/>
              <h3>Term of the Agreement and Program</h3>
              The term of this Agreement will begin upon our acceptance of your Program application and will end when terminated by either party. Either you or we may terminate this Agreement at any time, with or without cause, by giving the other party notice of termination. Notice by e-mail, to your address on our records, is considered sufficient notice to terminate this Agreement. Transpond reserves the right to end the Program at any time. Upon Program termination, Transpond will pay any legitimate outstanding earnings.
              <br/><br/>
              <h3>Termination</h3>
              Transpond, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Program, or any other Transpond service, for any reason at any time. Such termination will result in the deactivation or deletion of your Affiliate Account, and the forfeiture and relinquishment of all potential or accrued referral fees in your Account if they were earned through fraudulent, illegal, overly aggressive, or Inappropriate Advertising & Promotion methods. Transpond reserves the right to refuse service to anyone for any reason at any time. Upon the termination of this Agreement for any reason, you will immediately cease use of, and remove from your site, all links to the Transpond website and all our images and other materials provided under the Program
              <br/><br/>
              <h3>Relationship of Parties</h3>
              You and we are independent contractors, and nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between the parties. You will have no authority to make or accept any offers or representations on our behalf. You will not make any statement, whether on your site or otherwise, that reasonably would contradict anything in this Agreement.
              <br/><br/>
              <h3>Limitations of Liability</h3>
              The Company and any of the Company's officers, directors, employees, shareholders or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage that may result to you or a third party (including without limitation, any direct, indirect, punitive or consequential loss or damages, or any loss of income, profits, goodwill, data, contracts, use of money, or loss or damages arising from or connected in any way to business interruption, and whether in tort (including without limitation negligence), contract or otherwise) in connection with this Program.
              <br/><br/>
              Nothing in this legal notice shall exclude or limit the Company's liability for:
              <ol>
                <li>death or personal injury caused by negligence (as such term is defined by the Unfair Contract Terms Act 1977; or</li>
                <li>fraud; or</li>
                <li>misrepresentation as to a fundamental matter; or</li>
                <li>any liability which cannot be excluded or limited under applicable law.</li>
              </ol>
              <br/><br/>
              If your use of material provided under this Program results in the need for servicing, repair or correction of equipment, software or data, you assume all costs thereof. The Company’s maximum aggregate liability under or in connection with these Terms, or any collateral contract, whether in contract, tort (including negligence) or otherwise (a "Claim"), shall be limited to a sum equal to the aggregate amount which we are obliged to pay you in the twelve (12) month period immediately prior to the period giving rise to such Claim.
              <br/><br/>
              <h3>Arbitration</h3>
              Any dispute relating in any way to this Agreement (including any actual or alleged breach hereof), any transactions or activities under this Agreement or your relationship with us or any of our affiliates shall be submitted to confidential arbitration in England and Wales. Arbitration under this agreement shall be conducted under the rules then prevailing of the England and Wales Arbitration Association. The arbitrator's award shall be binding and may be entered as a judgment in any court of competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other party subject to this Agreement, whether through class arbitration proceedings or otherwise.
              <br/><br/>
              <h3>Notice</h3>
              All notices given by you to us must be given to MPZMail Limited at support@Transpond.io. We may give notice to you at the e-mail address you provided to us when registering. Notice will be deemed received and properly served 24 hours after an e-mail is sent. In proving the service of any notice, it will be sufficient to prove in the case of an e-mail, that such e-mail was sent to the specified e-mail address of the addressee.
              <br/><br/>
              <h3>Events outside our control</h3>
              We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations hereunder that is caused by events outside our reasonable control (a "Force Majeure Event").
              <br/><br/>
              A Force Majeure Event includes any act, event, non-happening, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:
              <ul>
                <li>strikes, lock-outs or other industrial action;</li>
                <li>civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;</li>
                <li>fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;</li>
                <li>impossibility of the use of public or private telecommunications networks;</li>
                <li>the acts, decrees, legislation, regulations or restrictions of any government.</li>
              </ul>
              <br/><br/>
              Our performance is deemed to be suspended for the period that the Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will use our reasonable endeavors to bring the Force Majeure Event to a close or to find a solution by which our obligations under these Terms may be performed despite the Force Majeure Event.
              <br/><br/>
              <h3>Waiver</h3>
              If we fail, at any time to insist upon strict performance of any of your obligations under these Terms, or if we fail to exercise any of the rights or remedies to which we are entitled hereunder, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with such obligations.
              <br/><br/>
              A waiver by us of any default shall not constitute a waiver of any subsequent default.
              <br/><br/>
              No waiver by us of any of these Terms shall be effective unless it is expressly stated to be a waiver and is communicated to you in writing.
              <br/><br/>
              <h3>Severability</h3>
              If any of these Terms are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.
              <br/><br/>
              <h3>Entire agreement</h3>
              These Terms and any document expressly referred to in it represents the entire agreement between us in relation to the use of the Program and supersedes any prior agreement, understanding or arrangement between us, whether oral or in writing.
              <br/><br/>
              We each acknowledge that, in entering into these Terms, neither of us has relied on any representation, undertaking or promise given by the other or be implied from anything said or written in negotiations between us prior to entering into these Terms except as expressly stated herein.
              <br/><br/>
              Neither of us shall have any remedy in respect of any untrue statement made by the other, whether orally or in writing, prior to the date we entered into these Terms (unless such untrue statement was made fraudulently) and the other party's only remedy shall be for breach of contract as provided in these Terms.
              <br/><br/>
              <h3>Governing law and jurisdiction</h3>
              This legal notice shall be governed by and construed in accordance with English law. Disputes arising in connection with this legal notice shall be subject to the exclusive jurisdiction of the English Courts.
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "TermsAffiliates",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Affiliate Program Terms - Transpond",
        description: "Take a look at the Transpond Affilliate Program Terms",
      },
    };
  },
};
</script>
