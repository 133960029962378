<template>
  <nav class="mega-menu mega-menu--resources">
    <div class="container-fluid mega-menu__container">
      <ul class="mega-menu__links">
        <li>
          <a class="mega-menu__link" href="https://kb.transpond.io/">
            <h6 class="text-link">Knowledge Base</h6>
            <p class="p--sm pr-6">
              Get the most out of Transpond by using our extensive library of
              help articles.
            </p>
          </a>
        </li>
        <li>
          <a class="mega-menu__link" href="/blog">
            <h6 class="text-link">Blog</h6>
            <p class="p--sm pr-6">
              Keep up to date with the latest news and product updates.
            </p>
          </a>
        </li>
        <li>
          <a class="mega-menu__link" href="/contactus">
            <h6 class="text-link">Contact Us</h6>
            <p class="p--sm pr-6">
              If you need help or have any questions, contact our friendly team.
            </p>
          </a>
        </li>
        <li>
          <a class="mega-menu__link"  v-on:click="TrackingHelpers.linkToApp('apidocs/1')">
            <h6 class="text-link">Developer API</h6>
            <p class="p--sm pr-6">
              Comprehensive access to the Transpond platform, from a simple API.
            </p>
          </a>
        </li>
      </ul>
      <div class="mega-menu__aside">
        <div class="mega-menu__aside-text">
          <h6>Email Templates Gallery</h6>
          <p class="p--sm mb-6">
            Beautifully designed templates to help you on your way.
          </p>
          <a class="mega-menu__link" href="/emailtemplates">
            <button
              class="btn btn--tertiary btn--sm mega-menu__aside-btn"
              title="View templates"
            >
              View templates
            </button>
          </a>
        </div>
        <div class="mega-menu__aside-image">
          <Image file="mega-promo-bg-crop" width="183" height="200" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Image from "@/components/Image";
import TrackingHelpers from "@/functions/tracking";


export default {
  name: "ResourcesMenu",
  components: {
    Image,
  },
  setup()
  {
    return { TrackingHelpers }
  }
};
</script>
