<template>
  <picture :class="classIn">
    <source :srcset="require('../assets/images/' + webpUrl + '')" />
    <img
      :style="style"
      class="lazyload"
      :loading="loading"
      :src="require('../assets/images/' + pngUrl + '')"
      :alt="alt"
      :width="width"
      :height="height"
    />
  </picture>
</template>

<script>
export default {
  name: "ImageComponent",
  props: {
    file: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    loading: {
      type: String,
      default: "lazy",
    },
  },
  methods: {
    getPngUrl() {
      return this.file + ".png";
    },
    getWebpUrl() {
      return this.file + ".webp";
    },
    getClass() {
      return this.class;
    },
  },
  computed: {
    pngUrl() {
      return this.getPngUrl();
    },
    webpUrl() {
      return this.getWebpUrl();
    },
    classIn() {
      return this.getClass();
    },
  },
};
</script>
