<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Gain deeper insights and keep improving"
      subtitleText="See how contacts engage with your campaigns and how you can maximize every opportunity with powerful analytics."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
      theme="green"
      image="analytics-hero"
      title="Gain insights into how your emails are performing"
      :paragraphs="[
        'Transpond provides all of the necessary insights that you can use to refine your marketing strategy and meet your goals.',
        'Use our detailed analytics tools to monitor and track your email campaigns in real time. View open rates, unsubscribes, link clicks, replies, bounce rates and more. By understanding your audience and knowing what resonates with them, you can deliver more engaging and successful email campaigns.',
      ]"
    ></SectionHero>

    <SectionCardsTwoColumn
      titleLeft="Click maps"
      contentLeft="Discover which links are driving the most engagement within an email campaign by using click maps. By tracking clicks you can compare how links are performing based on factors such as their placement within an email or visual design."
      imageLeft="click-maps-feature"
      titleRight="Analytics for Signup Forms"
      contentRight="Once you have created a signup form you can monitor how successfully they are at capturing new contacts. You can also compare the conversion rate of a hosted form versus an embedded form to see which is performing best."
      imageRight="analytics-signup-forms-feature"
    ></SectionCardsTwoColumn>

    <SectionTwoColumnAccordionLeft
      title="Experiment, learn and improve with A/B testing"
      content="Enabling Site Tracking on your website allows you to achieve much more with Transpond - including what users do after they have clicked a link in your email campaign."
      image="ab-testing"
      theme="dark"
      :accordion="[
        {
          name: 'Name split test',
          content:
            'See if a recipient is more likely to open an email from a company name or an individual.',
        },
        {
          name: 'Subject split test',
          content:
            'Phrase your subject lines in different ways to see what leads to higher open rates.',
        },
        {
          name: 'Sender split test',
          content:
            'Some recipients may be more likely to open an email from an email address they recognise.',
        },
        {
          name: 'Content split test',
          content:
            'Create different versions of your content and measure engagement.',
        },
      ]"
    ></SectionTwoColumnAccordionLeft>

    <SectionCardsTwoColumn
      titleLeft="Reply tracking"
      contentLeft="By adding a mailbox in Transpond you can view all replies to a campaign in one place. This allows you to gather more insight into how your customers are responding to an email once it has been sent."
      imageLeft="reply-tracking-feature"
      titleRight="Location breakdown"
      contentRight="Geolocation data allows you to see the approximate location of  your subscribers, giving you valuable insight that you can use to improve your campaigns. Increase the personalization of content or schedule an email to send at a more suitable time."
      imageRight="location-breakdown-feature"
    ></SectionCardsTwoColumn>

    <SectionTwoColumnHeadingParagraphs
      title="Why Transpond analytics are different"
      theme="grey"
      :paragraphs="[
        'Transpond lets you see full, unfiltered and unhidden bounce reports. If you have any issues with deliverability you will be able to see them in full. Plus, when viewing click reports you’re also able to include or exclude clicks from bots, giving a more accurate view of engagement.',
      ]"
    ></SectionTwoColumnHeadingParagraphs>

    <SectionTwoColumnRight
      id="sitetracking"
      kicker="Site Tracking"
      title="Delve deeper with advanced Site Tracking"
      content="Enabling Site Tracking on your website allows you to achieve much more with Transpond - including what users do after they have clicked a link in your email campaign."
      button-text="Learn more"
      button-link="Features"
      image="site-tracking"
    ></SectionTwoColumnRight>
  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionCardsTwoColumn from "@/components/ContentSections/SectionCardsTwoColumn";
import SectionTwoColumnHeadingParagraphs from "@/components/ContentSections/SectionTwoColumnHeadingParagraphs";
import SectionTwoColumnAccordionLeft from "@/components/ContentSections/SectionTwoColumnAccordionLeft.vue";

export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnRight,
    SectionCardsTwoColumn,
    SectionTwoColumnHeadingParagraphs,
    SectionTwoColumnAccordionLeft,
  },
  data() {
    return {
      meta: {
        title: "Analytics - Transpond",
        description:
          "Gain deeper insights and keep improving. See how contacts engage with your campaigns and how you can maximize every opportunity with powerful analytics.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
