<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <navigation theme="dark"></navigation>

  <PageHeader
    title="Brand Resources"
    theme="dark"
    class="mb-0 pb-10 sm:mb-0 sm:pb-30"
  ></PageHeader>

  <section class="resources__section resources__section--dark">
    <div class="container-fluid">
      <div class="row between-xs middle-xs">
        <div class="col-xs-12 col-sm-5 mb-10 sm:mb-0">
          <Image
            file="resources-promo-image"
            class="resources__header-image"
            width="550"
          >
          </Image>
        </div>
        <div class="col-xs-12 col-sm-5">
          <div class="row column-xs">
            <div class="col">
              <h4 class="mb-8">
                Transpond is a powerful Marketing platform that helps you
                communicate with your contacts with ease, and helps you grow
                your business.
              </h4>
              <p class="mb-16">
                Our core features include: our campaign and template builder,
                automations, forms, website tracking, transactional email, our
                campaign wizard, powerful analytics and list cleaning. Transpond
                also integrates with range of applications including Capsule,
                Pipedrive, Zoho, HubSpot, Google Sheets, Stripe and Zapier.
              </p>
              <a
                href="/assets/transpond-brand-assets.zip"
                class="btn btn--primary"
                download
                >Download Asset</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="resources__section resources__section--dark">
    <div class="container-fluid">
      <div class="row between-xs mb-10">
        <div class="col-xs">
          <h4>Logomark + Logotype</h4>
        </div>
        <div class="col-xs grow-0">
          <a
            href="/assets/transpond-full-logo.zip"
            class="btn btn--secondary btn--sm"
            download
            >Download</a
          >
        </div>
      </div>
      <div class="row between-xs">
        <div class="col-xs-12 col-sm-4 mb-5 sm:mb-0">
          <div class="resources__brand-element resources__brand-element--white">
            <LogoFull theme="black"> </LogoFull>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 mb-5 sm:mb-0">
          <div
            class="resources__brand-element resources__brand-element--purple"
          >
            <LogoFull theme="black"> </LogoFull>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 mb-5 sm:mb-0">
          <div class="resources__brand-element resources__brand-element--black">
            <LogoFull theme="white"> </LogoFull>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="resources__section resources__section--dark">
    <div class="container-fluid">
      <div class="row between-xs mb-10">
        <div class="col-xs">
          <h4>Logomark</h4>
        </div>
        <div class="col-xs grow-0">
          <a
            href="/assets/transpond-logomark.zip"
            class="btn btn--secondary btn--sm"
            download
            >Download</a
          >
        </div>
      </div>
      <div class="row between-xs">
        <div class="col-xs-12 col-sm-4 mb-5 sm:mb-0">
          <div class="resources__brand-element resources__brand-element--white">
            <LogoMark theme="black"> </LogoMark>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 mb-5 sm:mb-0">
          <div
            class="resources__brand-element resources__brand-element--purple"
          >
            <LogoMark theme="black"> </LogoMark>
          </div>
        </div>
        <div class="col-xs-12 col-sm-4 mb-5 sm:mb-0">
          <div class="resources__brand-element resources__brand-element--black">
            <LogoMark theme="white"> </LogoMark>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="resources__section resources__section--dark">
    <div class="container-fluid">
      <div class="row mb-10">
        <div class="col-xs">
          <h4>Colours</h4>
        </div>
      </div>
      <div class="row between-xs">
        <div class="col-xs-12 col-sm-3 mb-5 sm:mb-0">
          <div
            class="resources__brand-element resources__brand-element--purple"
          >
            <div class="resources__brand-element-color-meta">
              <h5 class="mb-8">#BAA5F5</h5>
              <p class="p--sm">
                RGB 186, 165, 245 <br />
                CMYK 24, 33, 0, 4
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-3 mb-5 sm:mb-0">
          <div class="resources__brand-element resources__brand-element--black">
            <div class="resources__brand-element-color-meta">
              <h5 class="mb-8">#000000</h5>
              <p class="p--sm">
                RGB 0,0,0 <br />
                CMYK 0, 0, 0, 100
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-3 mb-5 sm:mb-0">
          <div class="resources__brand-element resources__brand-element--stone">
            <div class="resources__brand-element-color-meta">
              <h5 class="mb-8">#F3F4F6</h5>
              <p class="p--sm">
                RGB 248, 243, 241 <br />
                CMYK 0, 2, 3, 3
              </p>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-3 mb-5 sm:mb-0">
          <div class="resources__brand-element resources__brand-element--white">
            <div class="resources__brand-element-color-meta">
              <h5 class="mb-8">#FFFFFF</h5>
              <p class="p--sm">
                RGB 255, 255, 255 <br />
                CMYK 0, 0, 0, 0
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="resources__section resources__section--dark">
    <div class="container-fluid">
      <div class="row center-xs">
        <h3 class="mb-2">Need a hand?</h3>
        <div class="col-xs-12">
          <div class="row center-xs">
            <div class="col-xs-12 col-md-6">
              <p class="mb-10 p--lg">
                If you have a question about the Transpond brand or would like
                further guidance on how to use our logo or colours, feel free to
                reach out.
              </p>
            </div>
          </div>
        </div>
        <div>
          <router-link to="/contactus" class="btn btn--primary">
            Send us a message
          </router-link>
        </div>
      </div>
    </div>
  </section>

  <site-footer></site-footer>
</template>
<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import Image from "@/components/Image";
import siteType from "../functions/siteType";
import LogoFull from "@/components/Svgs/Logo/LogoFull";
import LogoMark from "@/components/Svgs/Logo/LogoMark";
import SiteFooter from "@/components/SiteFooter";

var isUkSite = siteType.lookup();
isUkSite = true;

export default {
  name: "BrandResources",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    Image,
    LogoFull,
    LogoMark,
  },
  data() {
    return {
      isUkSite: isUkSite,
      meta: {
        title: "Transpond Brand Resources",
        description: "Brand resources, logos & colors for Transpond",
      },
    };
  },
};
</script>
