<template>
  <div class="sub-navigation__item-container">
    <router-link
      :to="url"
      :title="text"
      :class="['sub-navigation__item', activeClass]"
      ><img class="sub-navigation__icon" :src="icon" :alt="text + ' icon'" />
      <span class="sub-navigation__text">{{ text }}</span></router-link
    >
  </div>
</template>

<script>
export default {
  name: "SubNavigationItem",
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
    url: {
      type: String,
    },
  },
  data() {
    return {
      activeClass: "",
    };
  },
  created() {
    this.activeClass =
      this.url === window.location.pathname
        ? "sub-navigation__item--active"
        : "";
  },
};
</script>
