<template>
  <section
    class="content-section mt-40"
  >
    <div class="container-fluid">
      <div class="row center-xs">
        <div class="col-xs-12 col-sm-8">
          <h2 v-if="title" class="mb-4">{{ title }}</h2>
          <p v-if="content" class="mb-10">{{ content }}</p>
        </div>  
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div :class="'image-switcher center-xs pt-14 pb-0 xs:pl-4 xs:pr-4 sm:pr-10 sm:pl-10 md:pr-40 md:pl-40 image-switcher--' + size + ' image-switcher--background-' + background">
            <div v-if="items" class="image-switcher__switch wrap justify-center p-2 mb-10">
              <a 
                v-for="item in items" 
                :key="item.image" 
                :class="[
                  'image-switcher__item',
                  item.name === selected
                    ? 'is-active'
                    : ''
                ]"
                :active="item.name === selected"
                v-on:click="setSelected(item)"
              >{{ item.name }}</a>
            </div>

            <div v-if="items" class="image-switcher__images">
              <div v-for="item in items" :key="item.image" class="image-switcher__image-container">
                <Image
                  :file="item.image"
                  :class="[
                  'mb-0 image-switcher__image',
                  item.name !== selected
                    ? 'hidden'
                    : ''
                ]"
                ></Image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";

export default {
  name: "SectionImageSwitcher",
  components: { Image },
  props: {
    theme: {
      type: String,
    },
    title: {
      type: String,
      default: "Heading Goes Here",
    },
    content: {
      type: String,
    },
    items: {
      type: Array,
    },
    activeImage: {
      type: String,
    },
    size: {
      type: String,
      default: "inset"
    },
    background: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected: this.activeImage,
    }
  },
  methods: {
    setSelected(item) {
      this.selected = item.name;
    },
  }
};
</script>
