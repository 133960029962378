<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Create stunning emails for real engagement"
      subtitleText="Transpond’s intuitive drag and drop email designer is perfect for creating campaigns that get noticed and help your business grow. Start from a template or create something from scratch."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
      theme="orange"
      image="campaign-builder-hero"
      title="Get started quickly with our drag-and-drop builder"
      :accordion="[
        {
          name: 'Pre-built templates',
          content:
            'Transpond has dozens of pre-built templates that include everything you need to start sending professional-looking email campaigns. Our pre-built templates are fully customizable and look great on any device.',
        },
        {
          name: 'Drag and Drop editor',
          content:
            'Our Drag and Drop editor makes it easy to create and send beautifully-designed email campaigns. Build the layout that works for you and use elements such as images, buttons and interactive components to create emails that your audience will engage with.',
        },
        {
          name: 'Import templates',
          content:
            'As well as customizing one of our pre-built templates, you can also easily import an existing template from another Transpond account, a HTML file or from a Mailchimp account.',
        },
        {
          name: 'File manager',
          content:
            'Transpond’s File Manager allows you to upload, manage and categorize all of your images and documents in one place. File Manager also helps to ensure that your campaigns look and feel consistent.',
        },
      ]"
      selectedItem="Pre-built templates"
    ></SectionHero>

    <SectionTwoColumnRight
      kicker="Brand Analyzer"
      title="Instantly create branded emails campaigns"
      content="Our Brand Analyzer tool allows you to quickly and easily create emails that match the look and feel of your brand. We do the hard work for you by automatically importing assets including your logo, brand colors and images."
      video="brand-analyzer"
    ></SectionTwoColumnRight>

    <SectionCardsTwoColumn
      titleLeft="Free image library"
      contentLeft="Access over 3 million free high-resolution images that you can use in your campaigns. Provided by Unsplash, one of the largest providers of royalty-free images."
      imageLeft="image-library-feature"
      logoLeft="unsplash-logo"
      titleRight="Automated spam testing"
      contentRight="To take the pain out of inbox testing, we provide a one-click solution that enables you to quickly test your email content, infrastructure, authentication and more in seconds. If there are any problems, our spam testing tool will let you know what they are, and how to solve them."
      imageRight="spam-testing-feature"
    ></SectionCardsTwoColumn>

    <SectionTwoColumnHeadingParagraphs
      title="Email builders - what to look out for"
      theme="grey"
      :paragraphs="[
        'Not all email builders are equal. Depending on the app you’re using, it can be quite limited in what you’re able to achieve for your template designs.',
        'For example, it’s common to only be able to work in the defined limits of a set template. Transpond allows you to either keep things simple with a template, or build something completely bespoke.',
      ]"
    ></SectionTwoColumnHeadingParagraphs>

    <SectionCardsTwoColumn
      titleLeft="List cleaning"
      contentLeft="Stay on top of keeping your email list free from invalid, unused and spamtrap email addresses without hassle. Transpond automatically filters them out, which means you don't have to worry about building your reputation as an email sender."
      imageLeft="list-cleaning-feature"
      titleRight="Add polls and questionnaires"
      contentRight="From post-purchase questionnaires to customer service satisfaction surveys, gathering feedback via email is a great way to measure success and build stronger customer relationships."
      imageRight="polls-feature"
    ></SectionCardsTwoColumn>

    <SectionStepsTwoColumnRight
      title="Getting started with our  drag and drop editor"
      image="campaign-builder-get-started"
      :steps="[
        {
          title: 'Your choice',
          content:
            'Start with one of the in-built themes or start from scratch',
        },
        {
          title: 'Edit away',
          content:
            'The Elements dropdown lets you easily change blocks and styles',
        },
        {
          title: 'Test your creation',
          content:
            'Quickly preview your email on your desktop, or send a test to your inbox',
        },
      ]"
    ></SectionStepsTwoColumnRight>
  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionCardsTwoColumn from "@/components/ContentSections/SectionCardsTwoColumn";
import SectionTwoColumnHeadingParagraphs from "@/components/ContentSections/SectionTwoColumnHeadingParagraphs";
import SectionStepsTwoColumnRight from "@/components/ContentSections/SectionStepsTwoColumnRight.vue";

export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnRight,
    SectionCardsTwoColumn,
    SectionTwoColumnHeadingParagraphs,
    SectionStepsTwoColumnRight,
  },
  data() {
    return {
      meta: {
        title: "Campaign Builder - Transpond",
        description:
          "Create stunning emails for real engagement. Transpond’s intuitive drag and drop email designer is perfect for creating campaigns that get noticed and help your business grow. Start from a template or create something from scratch.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
