<template>
  <section class="accordion-section pt-10 pb-10 sm:pt-30 sm:pb-30">
    <div class="container-fluid">
      <div class="row accordion-container">
        <div class="col-xs-12 col-md-5">
          <div
            class="between-xs middle-xs"
            v-for="item in items"
            v-bind:key="item.name"
          >
            <div class="accordion">
              <AccordionItem
                class=""
                :active="item.name === selected"
                @setSelected="() => setSelected(item)"
              >
                {{ item.name }}
                <template v-slot:content>
                  {{ item.content }}
                </template>
              </AccordionItem>
            </div>
          </div>
        </div>
        <div
          class="accordion__image"
          v-for="item in items"
          v-bind:key="item.name"
        >
          <Image
            v-if="item.name === selected"
            :file="item.image"
            class="content-section__image"
            alt="Test"
            width="610"
          ></Image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AccordionItem from "./AccordionItem";
import Image from "../Image";

export default {
  name: "SectionAccordion",
  components: {
    AccordionItem,
    Image,
  },
  data() {
    return {
      selected: "Forms",
      items: [
        {
          name: "Forms",
          content:
            "Capture the important data and insight that you need to drive your business. The Transpond drag and drop builder lets you easily create forms that can be linked to your emails, added to your website or used across Social Media.",
          image: "forms",
        },
        {
          name: "Website Tracking",
          content:
            "Transpond's website tracking helps you to see what recipients do after clicking a link in one of your communications, and then set up automations, alerts and follow up communications when specific pages are hit or if they haven't completed a goal that you've set.",
          image: "website-tracking",
        },
        {
          name: "List Cleaning",
          content:
            "Stay on top of keeping your email list free from invalid, unused and spamtrap email addresses without hassle. Transpond's big data email list cleaner automatically filters them out, which means you don't have to worry about building your reputation as an email sender.",
          image: "list-cleaning",
        },
        {
          name: "Campaign Wizard",
          content:
            "Send campaigns in just a few clicks with the trusty Transpond campaign wizard. Send to multiple groups, exclude others, add segments, you can do it all. You'll also be able to find out whether your email is going to get to the intended inbox or hit a spam filter as testing for the world's most popular email providers is a standard feature within Transpond.",
          image: "campaign-wizard",
        },
      ],
    };
  },
  methods: {
    setSelected(item) {
      this.selected = item.name;
    },
  },
};
</script>
