<template>
  <div class="image-slider">
    <div class="image-slider__controls mb-5">
      <div class="image-slider__controls-count">
        {{position}} / <span class="image-slider__controls-count-total">{{sliderImagesCount}}</span>
      </div>
      <div

        aria-label="image slider controls">
        <button
          :class="{'text-link--disabled': firstSlide }"
          class="image-slider__control text-link text-link--arrow-before"
          aria-label="previous"
          @click="navigateToNextItem(`start`)">
        </button>
        <button
          :class="{'text-link--disabled': lastSlide }"
          class="image-slider__control text-link"
          aria-label="next"
          @click="navigateToNextItem(`end`)">
        </button>
      </div>

    </div>
    <div 
      class="image-slider__wrapper" 
      ref="imageSliderWrapper"
      role="region"
      aria-label="Image carousel"
      tabindex="0"
      >
      <div 
        class="image-slider__slider"
        role="list"
        ref="imageSlider"
        @scroll="handleImageSliderScroll()"
        v-bind:style="{ width: sliderWidth, transform: 'translateX(-' + sliderPosition + 'px)' }">
        <template 
          v-for="(integrationImageUrl, imgIndex) in this.$parent.ourApp.screenshots"
          v-bind:key="imgIndex">
          <ImageSliderImage
            ref="sliderImage"
            v-bind:url="integrationImageUrl"
            v-bind:alt="
              this.$parent.ourApp.appName +
              ' Integration Screenshot'
            "
            v-bind:title="
              this.$parent.ourApp.appName +
              ' Integration Screenshot'
            ">
          </ImageSliderImage>
        </template>
      </div>

    </div>
  </div>

</template>
<script>
import ImageSliderImage from "./ImageSliderImage";
import _ from 'lodash'; 

export default {
    name: "ImageSlider",
    components: { ImageSliderImage },
    props: {
    },
    data() {
      return {
        position: 0,
        sliderPosition: 0,
        sliderWidth: "",
        viewableWidth: 0,
        viewableHeight: 0,
        imageWidth: 0,
        paddingAmount: 0,
        imageGap: 0,
        sliderImages: [],
        sliderImagesCount: 0,
        sliderImagesWidth: 0,
        lastSlide: false,
        firstSlide: false,
      }
    },
    mounted() {

      const sliderWrapper = this.$refs["imageSliderWrapper"];
      const sliderImages = this.$refs["sliderImage"];

      let sliderStyle = window.getComputedStyle(sliderWrapper);
      
      this.viewableHeight = sliderStyle.height;
      this.viewableWidth = sliderStyle.width;

      this.sliderImages = sliderImages.map((image, i) => {
        image.$el.setAttribute('data-slider-index', (i + 1));
        return image.$el
      });

      this.sliderImagesCount = this.sliderImages.length;
      this.handleImageSliderScroll();
      this.$refs["imageSlider"].addEventListener('scroll', this.handleImageSliderScroll());

      let intersectionOptions = {
        root: this.$refs["imageSlider"],
        threshold: 0.5
      }

      let sliderImageObserver = new IntersectionObserver(entries => {
        entries.forEach((entry) => {
          if(entry.isIntersecting && entry.intersectionRatio > 0) {
            this.scrollCounterHandler(entry);
          }
        })
      }, intersectionOptions);

      this.sliderImages.forEach((image) => {
        sliderImageObserver.observe(image);
      });
    },
    methods: {
      getDistanceToFocalPoint(element, focalPoint = 'center') {
        //const documentWidth = document.documentElement.clientWidth;
        const rect = element.getBoundingClientRect();
        switch (focalPoint) {
          case 'start':
            return rect.left;
          case 'end':
            return rect.right;
          case 'center':
          default: {
            const centerFromLeft = rect.left + rect.width / 2;
            return centerFromLeft;
          }
        }
      },
      handleImageSliderScroll: _.throttle(function() {
        const imageSlider = this.$refs["imageSlider"];
        // scrollLeft is negative in a right-to-left writing mode
        const scrollLeft = Math.abs(imageSlider.scrollLeft);
        // off-by-one correction for Chrome, where clientWidth is sometimes rounded down
        const width = imageSlider.clientWidth + 1;
        const isAtStart = Math.floor(scrollLeft) === 0;
        const isAtEnd = Math.ceil(width + scrollLeft) >= imageSlider.scrollWidth;
        this.lastSlide = isAtEnd;
        this.firstSlide = isAtStart;
      }),
      navigateToNextItem(direction) {

        const scrollContainerCenter = this.getDistanceToFocalPoint(this.$refs["imageSliderWrapper"], 'center');
        let targetFocalPoint;
        let sliderItems = this.$refs["sliderImage"];
        sliderItems = sliderItems.map((image) => {
          return image.$el
        });

        sliderItems = direction === 'start' ? sliderItems.reverse() : sliderItems;

        for(const sliderImage of sliderItems) {
          let focalPoint = window.getComputedStyle(sliderImage).scrollSnapAlign;
          if (focalPoint === 'none') {
            focalPoint = 'center';
            }

          const distanceToItem = this.getDistanceToFocalPoint(sliderImage, focalPoint);

          if(
            (direction === "start" && distanceToItem + 1 < scrollContainerCenter) ||
            (direction === "end" && distanceToItem - scrollContainerCenter > 1)
          ){
            targetFocalPoint = distanceToItem;
            break;
          }
        }

        if (typeof targetFocalPoint === 'undefined') return;
        const sign = 1;
        const scrollAmount = sign * (targetFocalPoint - scrollContainerCenter);
        this.$refs["imageSlider"].scrollBy({left: scrollAmount });
      },
      scrollCounterHandler(entry) {
        if(entry && entry.target){
          this.position = entry.target.dataset.sliderIndex;
        }
      }
    }
}

</script>