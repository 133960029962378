<template>
  <svg :class="getClass" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4004 0H1.70893C0.796874 0 0 0.656249 0 1.55759V18.2861C0 19.1924 0.796874 20 1.70893 20H18.3955C19.3125 20 20 19.187 20 18.2861V1.55759C20.0053 0.656249 19.3125 0 18.4004 0ZM6.19954 16.671H3.33437V7.76249H6.19954V16.671ZM4.86606 6.40803H4.84553C3.92857 6.40803 3.33482 5.72544 3.33482 4.87097C3.33482 4.00089 3.94419 3.33437 4.88169 3.33437C5.81919 3.33437 6.39285 3.99598 6.41338 4.87097C6.41294 5.72544 5.81919 6.40803 4.86606 6.40803ZM16.671 16.671H13.8058V11.8C13.8058 10.633 13.3888 9.8357 12.3522 9.8357C11.5603 9.8357 11.0915 10.3714 10.883 10.8933C10.8049 11.0808 10.7839 11.3361 10.7839 11.5969V16.671H7.91874V7.76249H10.7839V9.00222C11.2009 8.40847 11.8522 7.55401 13.3678 7.55401C15.2486 7.55401 16.6714 8.79374 16.6714 11.4665L16.671 16.671Z" fill="black"/>
  </svg>
</template>
<script>
export default {
  name: "LinkedinIcon",
  props: {
    class: {
      type: String
    }
  },
  computed: {
    getClass() {
      return this.class;
    }
  }
}
</script>