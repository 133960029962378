<template>
  <svg :class="getClass" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.57457 0.31684C5.71482 -0.105614 6.28518 -0.105613 6.42543 0.31684L7.59137 3.83006C7.65317 4.01643 7.81927 4.14278 8.00762 4.1468L11.558 4.22259C11.9849 4.2317 12.1612 4.79991 11.8209 5.07012L8.99107 7.31718C8.841 7.43638 8.77759 7.64084 8.83208 7.82969L9.86039 11.3897C9.98407 11.8178 9.5226 12.169 9.17212 11.9135L6.25726 9.78906C6.1026 9.67641 5.8974 9.67641 5.74274 9.78906L2.82788 11.9135C2.47739 12.169 2.01593 11.8178 2.13961 11.3897L3.16792 7.82969C3.22241 7.64084 3.159 7.43638 3.00893 7.31718L0.179103 5.07012C-0.161204 4.79991 0.0150754 4.2317 0.442036 4.22259L3.99238 4.1468C4.18073 4.14278 4.34683 4.01643 4.40863 3.83006L5.57457 0.31684Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "StarFull",
  props: {
    class: {
      type: String
    }
  },
  computed: {
    getClass() {
      return this.class;
    }
  }
}
</script>