const axios = require("axios");
const textHelpers = require("../functions/text");

module.exports = [
  {
    path: "/",
    name: "Home",
    componentName: "Home",
  },
  {
    path: "/features",
    name: "Features",
    componentName: "Features",
  },
  {
    path: "/features/campaignbuilder",
    name: "CampaignBuilder",
    componentName: "CampaignBuilder",
  },
  {
    path: "/features/analytics",
    name: "Analytics",
    componentName: "Analytics",
  },
  {
    path: "/features/signupforms",
    name: "SignupForms",
    componentName: "SignupForms",
  },
  {
    path: "/features/websitetracking",
    name: "WebsiteTracking",
    componentName: "WebsiteTracking",
  },
  {
    path: "/features/transactionalemails",
    name: "TransactionalEmails",
    componentName: "TransactionalEmails",
  },
  {
    path: "/features/automations",
    name: "Automations",
    componentName: "Automations",
  },
  {
    path: "/features/ai-content-assistant",
    name: "AIContentAssistant",
    componentName: "AIContentAssistant",
  },
  {
    path: "/features/social-campaigns",
    name: "SocialCampaigns",
    componentName: "SocialCampaigns",
  },
  {
    path: "/features/conversations",
    name: "Conversations",
    componentName: "Conversations",
  },
  {
    path: "/pricing",
    name: "Pricing",
    componentName: "Pricing",
  },
  {
    path: "/contactus",
    name: "ContactUs",
    componentName: "ContactUs",
  },
  {
    path: "/contacted",
    name: "Contacted",
    componentName: "Contacted",
  },
  {
    path: "/blog",
    name: "Blog",
    componentName: "Blog",
  },
  {
    path: "/emailtemplates",
    name: "EmailTemplates",
    componentName: "EmailTemplates",
  },
  {
    path: "/emailtemplate/:templateId",
    name: "EmailTemplatesDetail",
    componentName: "EmailTemplatesDetail",
    meta: {
      sitemap: { ignoreRoute: true },
    },
  },
  {
    path: "/blog/:articleName/:articleId",
    name: "BlogArticleFull",
    componentName: "BlogArticleFull",
    meta: {
      sitemap: {
        slugs: async () => {
          const response = await axios.get("https://api.transpond.io/api/blog");
          return response.data.Blogs.map((blog) => ({
            articleName: textHelpers.stringToSlug(blog.blogTitle),
            articleId: blog.id,
          }));
        },
      },
    },
  },
  {
    path: "/integrations",
    name: "Integrations",
    componentName: "Integrations",
  },
  {
    path: "/integrations/categories/:selectedCatName?",
    name: "IntegrationsCats",
    componentName: "Integrations",
    meta: {
      sitemap: {
        slugs: async () => {
          const response = await axios.get(
            "https://api.transpond.io/api/applications/categories"
          );
          return response.data.AppCats.map((categoryContainer) => ({
            selectedCatName: textHelpers.stringToSlug(
              categoryContainer.category
            ),
          }));
        },
      },
    },
  },
  {
    path: "/integrations/:appName/:appId",
    name: "IntegrationsApp",
    componentName: "IntegrationsDetail",
    meta: {
      sitemap: {
        slugs: async () => {
          const response = await axios.get(
            "https://api.transpond.io/api/applications"
          );

          return response.data.Applications.map((app) => ({
            appName: textHelpers.stringToSlug(app.appName + " email marketing"),
            appId: app.id,
          }));
        },
      },
    },
  },
  {
    path: "/apidocs",
    beforeEnter() {
      location.href = "https://app.transpond.io/apidocs/1";
    },
  },
  {
    path: "/about",
    name: "AboutUs",
    componentName: "About",
  },
  {
    path: "/media",
    name: "BrandResources",
    componentName: "BrandResources",
  },
  {
    path: "/terms",
    name: "Terms",
    componentName: "Terms",
  },
  {
    path: "/privacy",
    name: "Privacy",
    componentName: "Privacy",
  },
  {
    path: "/dpa",
    name: "DPA",
    componentName: "Dpa",
  },
  {
    path: "/dpa_restrictedtransfers",
    name: "DPARestrictedTransfers",
    componentName: "DpaRestricted",
  },
  {
    path: "/dpa_schedule1",
    name: "DPASchedule1",
    componentName: "DpaSchedule1",
  },
  {
    path: "/dpa_schedule2",
    name: "DPASchedule2",
    componentName: "DpaSchedule2",
  },
  {
    path: "/dpa_schedule3",
    name: "DPASchedule3",
    componentName: "DpaSchedule3",
  },
  {
    path: "/dpa_cancellation",
    name: "CancellationPolicy",
    componentName: "DpaCancellation",
  },
  {
    path: "/subprocessors",
    name: "SubProcessors",
    componentName: "TermsSubProcessors",
  },
  {
    path: "/gdprcontactlocations",
    name: "GDPRContactLocations",
    componentName: "GDPRContactLocations",
  },
  {
    path: "/cookiepolicy",
    name: "CookiePolicy",
    componentName: "TermsCookiePolicy",
  },
  {
    path: "/acceptableuse",
    name: "AcceptableUsePolicy",
    componentName: "TermsAcceptableUse",
  },
  {
    path: "/thirdparties",
    name: "ThirdParties",
    componentName: "TermsThirdParties",
  },
  {
    path: "/reseller_dpa",
    name: "ResellerDPA",
    componentName: "TermsResellerDPA",
  },
  {
    path: "/reseller_agreement",
    name: "ResellerAgreement",
    componentName: "TermsReseller",
  },
  {
    path: "/affiliate_terms",
    name: "AffiliateTerms",
    componentName: "TermsAffiliates",
  },
  {
    path: "/mailchimp-alternative",
    name: "MailchimpAlternative",
    componentName: "MailchimpAlternative",
  },
  {
    path: "/whitelabel",
    name: "WhitelabelLanding",
    componentName: "WhiteLabelLanding",
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];
