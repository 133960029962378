<template>

<div v-show="title == currentTitle">
  <slot></slot>
</div>

</template>
<script>

  export default {
    name: "ContentTab",
    props: ["title"],
    inject: ["selectedTab"],
    data() {
      return {
        currentTitle: ""
      }
    },
    created() {
      this.currentTitle = this.selectedTab;
    }
  }
</script>