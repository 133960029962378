<template>
  <MetaInfo :title="pageTitle" :description="pageDescription"></MetaInfo>

  <div :class="'theme--' + theme">
    <Navigation :theme="theme"></Navigation>
    <slot />
    <footerCTA :theme="theme" class="mt-40"></footerCTA>
    <site-footer :theme="theme"></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import FooterCTA from "@/components/FooterCTA";
import SiteFooter from "@/components/SiteFooter";

export default {
  name: "DefaultLayout",

  components: {
    MetaInfo,
    Navigation,
    FooterCTA,
    SiteFooter,
  },

  props: {
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
    theme: {
      type: String,
      default: "light"
    }
  },
};
</script>
