<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <div v-if="dataLoaded === true">
    <blog-sidebar></blog-sidebar>

    <navigation></navigation>

    <PageHeader :title="ourBlog.blogTitle"></PageHeader>

    <section class="container-fluid">
      <div class="row">
        <div class="col-xs-12 mb-20">
          <div v-if="hasHeroImage" class="row mb-20">
            <img
              :src="blogPostHeroImageUrl"
              width="1030"
              height="440"
              style="aspect-ratio: 1030 / 440;"
              v-bind:title="'Hero Image'"
            />
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-2 mb-10 sm:mb-0">
              <BlogSocialShare
                :currentPageUrl="blogPostUrl"
                :pageTitle="blogPostTitle"
                :postSummary="blogPostDescription"
              ></BlogSocialShare>
            </div>
            <div class="col-xs-12 col-sm-6">
              <article class="longform" v-html="blogPostContent"></article>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="row center-xs text-left">
            <div class="col-xs-12 col-sm-6">
              <template v-for="(item, index) in blogData" v-bind:key="index">
                <template v-for="article in item" v-bind:key="article.id">
                  <blog-article
                    v-bind:blogData="article"
                    :isNext="true"
                  ></blog-article>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>

    <site-footer></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/Blog/BlogArticleNavigation";
import PageHeader from "@/components/Blog/BlogArticleHeader";
import SiteFooter from "@/components/Blog/BlogFooter";
import BlogArticle from "@/components/Blog/BlogArticleListing";
import BlogSidebar from "@/components/Blog/BlogSidebar";
import BlogSocialShare from "@/components/Blog/BlogSocialShare";
import textHelpers from "@/functions/text";
import axios from "axios";

export default {
  name: "BlogArticleFull",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    BlogSidebar,
    SiteFooter,
    BlogArticle,
    BlogSocialShare,
  },
  data() {
    return {
      meta: {
        title: "",
        description: "",
      },
      blogData: [],
      ourBlog: [],
      dataLoaded: false,
      nextBlogPost: [],
      blogPostTitle: "",
      blogPostUrl: "",
      blogPostDescription: "",
      blogPostContent: "",
      hasHeroImage: false,
      blogPostHeroImageUrl: "",
    };
  },
  created() {
    axios
      .get("https://api.transpond.io/api/blog/" + this.$route.params.articleId)
      .then((response) => {
        this.ourBlog = response.data.Blogs;
        if (!response.data.Blogs || response.data.Blogs.length < 1)
          this.$router.push("/blog");

        return axios.get("https://api.transpond.io/api/blog");
      })
      .then((response) => {
        var currentArticleIndex = 0;
        var tmpData = [];
        var blogArticles = response.data.Blogs;

        blogArticles.forEach((article, index) => {
          if (article.id == this.$route.params.articleId) {
            currentArticleIndex = index;
            tmpData = blogArticles.at(currentArticleIndex - 1);
            this.blogData.push([tmpData]);
          }
        });

        this.dataLoaded = true;

        this.blogPostTitle = this.ourBlog.blogTitle + " - Transpond Blog";

        this.blogPostUrl = window.location.href;

        this.blogPostDescription =
          "Find out about " + this.ourBlog.blogTitle + " at the Transpond Blog";

        this.hasHeroImage = this.ourBlog.featureImageUrl !== "https://yourimage.com";

        this.blogPostHeroImageUrl = this.ourBlog.featureImageUrl;

        this.blogPostContent = textHelpers.cleanHtml(this.ourBlog.content);
        this.meta.title = this.blogPostTitle;
        this.meta.description = this.blogPostDescription;
      });
  },
};
</script>
