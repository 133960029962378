<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Social media presence, without the headache"
      subtitleText="Transpond’s social media scheduling tool makes it easier to find the time to get posting regularly, without fuss."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
    theme="blue"
    image="social-campaigns-hero"
    title="Connect with your audience where they are"
    :paragraphs="[
      'Rapidly grow your brand by creating, scheduling, and analyzing entire social media campaigns.',
      'Manage your social media campaigns from one central location. Whether you’re active on one social media channel or many, Transpond works with: Facebook, Instagram, LinkedIn, X (formerly Twitter).',
    ]"
  ></SectionHero>

    <SectionCardsTwoColumn
      titleLeft="Share your best content online"
      contentLeft="Drive engagement by including videos, images, or external content links within your scheduled posts to stay on the right side of the algorithm."
      imageLeft="campaigns-media-feature"
      titleRight="Let AI write engaging social posts"
      contentRight="Getting creative can be tough. Thankfully, Transpond’s AI Content Assistant quickly drafts compelling copy for your social posts. Simply enter your prompt and let AI do its magic."
      imageRight="campaigns-ai-feature"
    ></SectionCardsTwoColumn>

    <SectionTwoColumnRight
    kicker="Analytics"
    title=" Track engagement with your social campaigns"
    content=" Get an overview of key metrics like impressions, clicks and engagement signals to help demonstrate the impact of your social campaigns."
    image="campaigns-engagement-feature"
  ></SectionTwoColumnRight>

  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionCardsTwoColumn from "@/components/ContentSections/SectionCardsTwoColumn";

export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnRight,
    SectionCardsTwoColumn,
  },
  data() {
    return {
      meta: {
        title: "Campaign Builder - Transpond",
        description:
          "Create stunning emails for real engagement. Transpond’s intuitive drag and drop email designer is perfect for creating campaigns that get noticed and help your business grow. Start from a template or create something from scratch.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
