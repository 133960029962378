<script setup>
import textHelpers from "../functions/text";
</script>
<template>
  <MetaInfo
    :title="getMetaTitle()"
    :description="getMetaDescription()"
  ></MetaInfo>
  <navigation></navigation>

  <PageHeader title="Integrate with your favorite apps"></PageHeader>

  <section class="mb-20 sm:mb-40">
    <div class="container-fluid">
      <div class="row between-xs">
        <div class="col-xs-12 col-md-3 mb-10">
          <ul class="sidebar-menu">
            <li
              class="sidebar-menu__item"
              :class="
                isCurrentCategory('all') ? 'sidebar-menu__item--active' : ''
              "
            >
              <a v-on:click="changeCat('all')" href="#">All Integrations</a>
            </li>
            <template v-for="(cat, index) in catData" v-bind:key="index">
              <li
                class="sidebar-menu__item"
                :class="
                  isCurrentCategory(cat.category.toLowerCase())
                    ? 'sidebar-menu__item--active'
                    : ''
                "
              >
                <a
                  v-on:click="changeCat(cat.category)"
                  :data-key="index"
                  :data-value="cat.category.toLowerCase()"
                  href="#"
                  >{{ cat.category }}</a
                >
              </li>
            </template>
          </ul>
        </div>
        <div class="col-xs-12 col-md-9 col-lg-8">
          <div class="integrations-grid mb-6">
            <template v-for="(apps, index) in appData" v-bind:key="index">
              <template v-for="app in apps" v-bind:key="app.id">
                <article class="integration-card" v-on:click="clickApp(app)">
                  <div class="integration-card__inner">
                    <div
                      class="integration-logo"
                      :class="
                        'integration-logo--' +
                        textHelpers.stringToSlug(app.appName)
                      "
                    >
                      <img
                        v-bind:src="app.appMarketingLogoUri"
                        width="32"
                        class="integration-card__logo"
                        v-bind:alt="'Email Marketing for ' + app.appName"
                        v-bind:title="'Email Marketing for ' + app.appName"
                      />
                    </div>

                    <div class="integration-card__description">
                      <h6 class="p integration-card__title strong">
                        {{ app.appName }}
                      </h6>
                      <p class="p--sm">{{ app.appDescription }}</p>
                    </div>
                  </div>
                </article>
              </template>
            </template>
          </div>

          <div class="row integrations-cta between-xs middle-xs p-8">
            <div class="col-xs-12 col-sm-9 mb-5 sm:mb-0">
              <h6 class="p strong mb-2">Build your own integration</h6>
              <p>
                Interested in connecting your app? Get started with our API
                docs.
              </p>
            </div>
            <a
              v-on:click="TrackingHelpers.linkToApp('apidocs/1')"
              title="View API Docs"
              class="btn btn--secondary btn--sm"
              target="_blank"
              >View API Docs</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <footerCTA></footerCTA>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import SiteFooter from "@/components/SiteFooter";
import FooterCTA from "@/components/FooterCTA";
import axios from "axios";
import TrackingHelpers from "@/functions/tracking";

export default {
  name: "IntegrationsPage",
  setup() {
    return { TrackingHelpers };
  },
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    FooterCTA,
  },
  data() {
    return {
      appDataRaw: [],
      catDataRaw: [],
      appData: [],
      catData: [],
      dataLoaded: false,
      currentCategory: "",
      currentSearch: "",
      featuredApp: undefined,
      meta: {
        title: "Transpond integration partners",
        description:
          "Explore the full range of integrations for Transpond, including Capsule, Stripe and Zapier. Ensure you're managing data easily across applications.",
      },
    };
  },

  created() {
    var limit = "";
    if (this.$route.params.selectedCatName) {
      limit = "";
      this.currentCategory = textHelpers.slugToString(
        this.$route.params.selectedCatName
      );
    }

    axios
      .get(
        "https://api.transpond.io/api/applications/?order=totalInstalls&limit=" +
          limit
      )
      .then((response) => {
        this.appsDataRaw = response.data;

        var featuredApp;
        this.appsDataRaw.Applications.forEach((ap) => {
          if (ap.featured == true) {
            featuredApp = ap;
          }
        });
        if (featuredApp) {
          this.featuredApp = featuredApp;
        }

        return axios.get(
          "https://api.transpond.io/api/applications/categories"
        );
      })
      .then((response) => {
        this.catsDataRaw = response.data;
        this.bindIntegrations();
      });
  },
  methods: {
    // Dynamically set meta title and description
    getMetaTitle() {
      let title = "Transpond integration partners";

      if (this.currentCategory && this.catData) {
        const categoryObject = this.catData.find(
          (cat) =>
            cat.category.toLowerCase() === this.currentCategory.toLowerCase()
        );

        if (categoryObject) {
          title = `${categoryObject.category} | ${title}`;
        }
      }

      return title;
    },
    getMetaDescription() {
      if (this.currentCategory && this.catData) {
        const categoryObject = this.catData.find(
          (cat) =>
            cat.category.toLowerCase() === this.currentCategory.toLowerCase()
        );

        if (categoryObject) {
          return `Explore the full range of integrations for ${categoryObject.category} apps on Transpond. Ensure you're managing data easily across applications.`;
        }
      }

      return "Explore the full range of integrations for Transpond, including Capsule, Stripe, and Zapier. Ensure you're managing data easily across applications.";
    },
    bindIntegrations() {
      this.dataLoaded = false;

      var totalFound = 0;
      var tmpData = [];
      this.appData = [];

      this.appsDataRaw.Applications.forEach((application, index) => {
        var foundSearch = false;
        if (this.currentSearch.length > 0) {
          foundSearch = true;
          if (
            application.appName
              .toLowerCase()
              .indexOf(this.currentSearch.toLowerCase()) < 0
          ) {
            return;
          }
        }

        if (!foundSearch) {
          if (
            this.currentCategory &&
            this.currentCategory.toLowerCase() !=
              application.category.toLowerCase()
          )
            return;
        }

        tmpData.push(application);
        totalFound++;
        if (
          totalFound > 2 ||
          index >= this.appsDataRaw.Applications.length - 1
        ) {
          this.appData.push(tmpData);
          tmpData = [];
          totalFound = 0;
        }
      });

      if (tmpData.length > 0) {
        this.appData.push(tmpData);
      }

      this.catData = this.catsDataRaw.AppCats;
      this.dataLoaded = true;
    },
    changeCat(catIn) {
      if (!catIn.length || catIn === "all") {
        this.$router.push({
          path: "/integrations",
        });
      } else {
        this.$router.push({
          path: "/integrations/categories/" + textHelpers.stringToSlug(catIn),
        });
      }
    },
    isCurrentCategory(categoryIn) {
      if (!this.currentCategory.length && categoryIn === "all") {
        return true;
      } else if (categoryIn === this.currentCategory) {
        return true;
      }
    },
    changeSearch() {
      this.bindIntegrations();
    },
    clickApp(theApp) {
      this.$router.push({
        path:
          "/integrations/" +
          textHelpers.stringToSlug(theApp.appName + " email marketing") +
          "/" +
          theApp.id,
      });
    },
  },
};
</script>
