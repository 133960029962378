<script setup>
  import textHelpers from '../../functions/text';
</script>

<template>

<section class="integration-header">
  <div class="container-fluid">
    <div class="row middle-xs">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 pt-10 pb-10">
        <div class="row">
          <div class="col-xs-12 mb-10">
            <router-link to="/integrations" class="text-link text-link--arrow-before"
              >All integrations</router-link
            >
          </div>
        </div>
        <div class="row between-xs middle-xs">
          <div class="col-xs-12 col-sm-12 col-md-8 mb-10 md:mb-0">
            <div class="row middle-xs">
              <div class="col-xs grow-0 mb-5 sm:mb-0 mr-2">
                <div class="integration-logo integration-logo--large"  :class="'integration-logo--' + textHelpers.stringToSlug($parent.ourApp.appName)">
                  <img
                    v-bind:src="$parent.ourApp.appMarketingLogoUri"
                    width="50"
                    v-bind:alt="$parent.ourApp.appName + ' Email Marketing Integration'"
                    v-bind:title="$parent.ourApp.appName + ' Email Marketing Integration'"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-sm-9">
                  <h1 class="h6">{{ $parent.ourApp.appName }}</h1>
                <p>{{$parent.ourApp.appDescription}}</p>
              </div>
            </div>
          </div>
          <div class="col-xs grow-0">
            <button
              class="btn btn--primary"
              v-on:click="$parent.installApp()"
              v-bind:alt="'Add email marketing to ' + $parent.ourApp.appName"
              v-bind:title="'Add email marketing to ' + $parent.ourApp.appName"
            >
              Connect
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>

</section>

</template>

<script>

  export default {

  }

</script>