<template>
  <nav
    class="whitelabel-nav"
    :class="{
      'whitelabel-nav--dark': theme == 'dark' && isAtTop,
      'whitelabel-nav--white': !isAtTop,
    }"
  >
    <div class="container between-xs flex p-4">
      <div class="whitelabel-nav__logo">
        <router-link to="/">
          <LogoFull
            class="nav__logo"
            :theme="theme == 'dark' ? 'white' : ''"
          ></LogoFull>
        </router-link>

        <span class="p--sm">White Label</span>
      </div>
    </div>
  </nav>
</template>

<script>
import LogoFull from "@/components/Svgs/Logo/LogoFull";

export default {
  name: "WhiteLabelNav",
  components: {
    LogoFull,
  },
  props: {
    theme: {
      type: String,
    },
  },
  data() {
    return {
      isAtTop: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isAtTop = window.scrollY === 0;
    },
  },
};
</script>
