<template>
  <section
    :class="[
      'content-section',
      this.theme == 'grey'
        ? 'content-section--grey pt-30 pb-30 mt-40'
        : 'mt-30'
    ]"
  >
    <div class="container-fluid">
      <div class="row between-xs">
        <div class="col-xs-12 col-sm-5">
          <h4 v-if="title" class="mb-6 sm:mb-0">{{ title }}</h4>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div v-if="paragraphs">
            <p v-for="(paragraph,index) in paragraphs" :key="index" class="p--lg">{{ paragraph }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionTwoColumnHeadingParagraphs",
  components: { },
  props: {
    theme: {
      type: String,
    },
    title: {
      type: String,
      default: "Title",
    },
    paragraphs: {
      type: Array,
    },
  },
};
</script>
