<template>
  <div
    class="accordion__item"
    :class="{ 'is-active': active }"
    v-on:click="handleChange"
  >
    <div class="accordion__item-title">
      <h5>
        <slot></slot>
      </h5>
      <div class="accordion__item-icon">
        <div class="accordion__item-icon-inner"></div>
      </div>
    </div>
    <div class="accordion__item-content">
      <p>
        <slot name="content"></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionItem",
  props: {
    active: {
      type: Boolean,
    },
  },

  methods: {
    handleChange() {
      this.$emit("setSelected");
    },
  },
};
</script>
