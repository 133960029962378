<template>
  <section class="mb-12">
    <div class="container-fluid">
      <div class="row">
        <hr>
        <div class="col-sm-12 pt-8 pb-8 mb-20">
          <h2 class="h1">
            {{title}}
          </h2>
          <router-link v-if="buttonLink" :to="{ name: buttonLink }" title="{{buttonText}}" class="btn btn--primary mt-10">{{buttonText}}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Join Transpond today"
    },
    buttonLink: {
      type: String,
      default: "Pricing"
    },
    buttonText: {
      type: String,
      default: "Get started"
    }
  }
}
</script>