<template>
  <div class="blog__sidebar">
    <a href="/">
      <LogoMark
        theme="purple"
        >
      </LogoMark>      
    </a>
  </div>
</template>
<script>

import LogoMark from "../Svgs/Logo/LogoMark";

export default {
  name: "BlogSidebar",
  components: {
    LogoMark
  }
}

</script>