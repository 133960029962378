<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Cancellation &amp; Data Deletion Policy"> </PageHeader>

    <div class="container-fluid">
      <div class="row mb-30">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <p>
              The owner of an Transpond account can cancel at any time by
              logging into the Transpond account and following the Cancel
              Account link in the account settings area.
            </p>
            <p>After cancellation:</p>
            <ul>
              <li>Your account will be made inaccessible.</li>
              <li>
                No further subscription payments will be collected for the
                account.
              </li>
              <li>
                A 14 day grace period is provided in case the cancellation was
                accidental or you change your mind and wish to restore your
                account.
              </li>
              <li>
                After the expiry of the 14 day grace period the account will be
                immediately deleted along with any residual content in the
                account and restore of the account will no longer be possible.
              </li>
              <li>
                Please note that partial content may reside on our archival
                systems for backup purposes for a period of up to 120 days.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "CancellationandDataDeletion",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Cancellation and Data Deletion- Transpond",
        description:
          "Take a look at the Transpond Cancellation and Data Deletion",
      },
    };
  },
};
</script>
