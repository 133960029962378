<template>
  <ul class="mt-10 col-xs-12 sm:mt-30 sm:mb-40 feature-highlights__container">
    <router-link
      v-for="feature in features"
      :key="feature"
      class="feature-highlight"
      :to="feature.link"
      ><li>{{ feature.highlight }}</li></router-link
    >
  </ul>
</template>

<script>
export default {
  name: "PageHeaderFeatureHighlight",
  props: {
    features: {
      type: Array,
    },
  },
};
</script>
