<template>
  <section :class="'sub-navigation hidden-xvs hidden-vs hidden-sm sub-navigation--' + theme">
    <div class="container-fluid">
      <div class="row between-xs">
        <div class="col-xs-12">
          <div class="sub-navigation__items">
            <div v-for="link in links" :key="link.url">
              <SubNavigationItem
                :text="link.text"
                :icon="
                  require('@/assets/images/icons/' + link.icon + '-filled-' + theme + '.svg')
                "
                :url="link.url"
              ></SubNavigationItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SubNavigationItem from "./SubNavigationItem.vue";

export default {
  name: "SubNavigation",
  components: { SubNavigationItem },
  props: {
    links: {
      type: Array,
    },
    theme: {
      type: String,
      default: "light"
    }
  },
};
</script>
