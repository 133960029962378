<template>
    <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Optimize transactional email experience"
      subtitleText="Keep contacts informed with perfectly timed emails. By keeping them informed and engaged, you can keep customers happy and drive real loyalty."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
      theme="blue"
      image="transactional-email-hero"
      title="Transactional email, explained"
      :paragraphs="
        [
          'Transactional emails are automated messages that are sent to an individual recipient in response to an action they have performed - such as password reset emails or order confirmation emails. They typically include data and information that is specific to the recipient - unlike marketing emails which deliver broadly the same content to multiple recipients.',

          'With Transpond, we’ve made it easy for you to send and track transactional emails to your users. '
        ]"
    ></SectionHero>

    <SectionImageSwitcher
      title="Popular uses for transactional emails"
      :items="
      [
        { name: 'Welcome', image: 'welcome-email' },
        { name: 'Password Reset', image: 'password-reset' },
        { name: 'Receipt & Invoice', image: 'receipt' },
        { name: 'Trial Expiration', image: 'trial-expiration' },
        { name: 'User Invitation', image: 'user-invitation' },
      ]"
      activeImage="Welcome"
      background=false
      size="full"
    ></SectionImageSwitcher>

    <SectionStepsTwoColumnLeft
      title="It’s easy to get started with transactional email"
      image="transactional-get-started"
      :steps="
        [
          { title: 'Copy your code', content: 'Paste it into your own codebase' },
          { title: 'Good to go', content: 'You’re plugged in and ready to send perfectly timed emails'},
          { title: 'See how they perform', content: 'Access key stats to see how you can optimize emails'},
        ]"
    ></SectionStepsTwoColumnLeft>

    <SectionTwoColumnHeadingParagraphs
      title="How Transactional emails can help you"
      :paragraphs="[
        'There are lots of different types of transactional emails. They can range from confirming an account through to letting a customer know when their order has been received and then dispatched.',
        'Due to their nature they tend to get very high levels of engagement, and that’s why they need to be of a high standard. Getting them right creates a great experience, and the right app makes doing that easy.'
      ]"
    ></SectionTwoColumnHeadingParagraphs>

  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnHeadingParagraphs from "@/components/ContentSections/SectionTwoColumnHeadingParagraphs";
import SectionStepsTwoColumnLeft from "@/components/ContentSections/SectionStepsTwoColumnLeft.vue";
import SectionImageSwitcher from "@/components/ContentSections/SectionImageSwitcher.vue";

export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnHeadingParagraphs,
    SectionStepsTwoColumnLeft,
    SectionImageSwitcher
  },
  data() {
    return {
      meta: {
        title: "Transactional Emails - Transpond",
        description:
          "Optimize transactional email experience. Keep contacts informed with perfectly timed emails. By keeping them informed and engaged, you can keep customers happy and drive real loyalty.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
