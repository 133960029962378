<template>
  <header
    class="page-header"
    :class="[{ 'page-header--dark': theme == 'dark' }, classIn]"
  >
    <div class="container-fluid">
      <div class="row justify-content-center">
        <h1 class="mb-5 text-center page-header__title" v-html="title"></h1>
        <h6
          class="text-center d-block w-50 page-header__subtitle"
          v-if="subtitleText"
        >
          {{ subtitleText }}
        </h6>

        <slot name="pageLinks"></slot>

        <slot name="image"></slot>

        <slot></slot>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeader",
  components: {},
  props: {
    class: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    subtitleText: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    theme: {
      type: String,
    },
  },
  data() {
    return {
      classIn: this.class,
    };
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .page-header__title {
    font-size: 1.8rem;
    margin: 2rem 0 2rem 0 !important;
  }

  .page-header__subtitle {
    font-size: 0.9rem;
    width: 80% !important;
  }
}
</style>
