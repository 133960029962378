<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <div>
    <navigation></navigation>
    <PageHeader title="Reseller Data Processing Agreement"> </PageHeader>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1 mb-30">
          <div class="longform">
            <p>
              This DPA is entered into between the Controller and the Processor
              and is incorporated into and governed by the terms of the
              Agreement.
            </p>
            <h2>1. Definitions</h2>
            <p>
              Any capitalised term not defined in this DPA shall have the
              meaning given to it in the Agreement.
            </p>
            <p>
              “Affiliate” means any entity that directly or indirectly controls,
              is controlled by, or is under common control of a party.
              “Control,” for purposes of this definition, means direct or
              indirect ownership or control of more than 50% of the voting
              interests of a party;
            </p>
            <p>
              “Agreement” means the agreement between the Processor and the
              Controller for the provision of the Services by the Controller to
              Customers;
            </p>
            <p>
              “CCPA” means the California Consumer Privacy Act of 2018, along
              with its regulations and as amended from time to time;
            </p>
            <p>“Controller” means the Agency;</p>
            <p>
              “Data Protection Law” means all laws and regulations, including
              laws and regulations of the European Union, the European Economic
              Area, their member states and the United Kingdom any amendments,
              replacements or renewals thereof, applicable to the processing of
              Personal Data, including where applicable the Data Protection,
              Privacy and Electronic Communications (Amendments etc.) (EU Exit)
              Regulations 2020, the EU GDPR, the UK GDPR, the FDPA, the UK Data
              Protection Act 2018, the CCPA and any applicable national
              implementing laws, regulations and secondary legislation relating
              to the processing of Personal Data and the privacy of electronic
              communications, as amended, replaced or updated from time to time,
              including the Privacy and Electronic Communications Directive
              (2002/58/EC) and the Privacy and Electronic Communications (EC
              Directive) Regulations 2003 (SI 2003/2426);
            </p>
            <p>
              “Data Subject” shall have the same meaning as in Data Protection
              Law, or means a “Consumer” as that term is defined in the CCPA;
            </p>
            <p>
              “DPA” means this data processing agreement together with its
              Schedule 1, Schedule 2 and Schedule 3;
            </p>
            <p>“EEA” means the European Economic Area;</p>
            <p>
              “EU GDPR” means Regulation (EU) 2016/679 of the European
              Parliament and of the Council of 27 April 2016 on the protection
              of natural persons with regard to the processing of personal data
              and on the free movement of such data, (General Data Protection
              Regulation);
            </p>
            <p>
              “FDPA” means the new Swiss Federal Act on Data Protection of 1st of September 2023 and as amended from time to time;
            </p>
            <p>
              “Personal Data” shall have the same meaning as in Data Protection
              Law;
            </p>
            <p>
              “Processor” means us, including as applicable any “Service
              Provider” as that term is defined by the CCPA;
            </p>
            <p>“Restricted Transfer” means:</p>
            <ul>
              <li>
                where the EU GDPR applies, a transfer of Personal Data via the
                Services from the EEA either directly or via onward transfer, to
                any country or recipient outside of the EEA not subject to an
                adequacy determination by the European Commission; and
              </li>
              <li>
                where the UK GDPR applies, a transfer of Personal Data via the
                Services from the United Kingdom either directly or via onward
                transfer, to any country or recipient outside of the UK not
                based on adequacy regulations pursuant to Section 17A of the
                United Kingdom Data Protection Act 2018; and
              </li>
              <li>
                a transfer of Personal Data via the Services from Switzerland
                either directly or via onward transfer, to any country or
                recipient outside of the EEA and/or Switzerland not subject to
                an adequacy determination by the European Commission;
              </li>
            </ul>
            <p>
              “Services” means all services and software applications and
              solutions provided to the Controller by the Processor under and as
              described in the Agreement;
            </p>
            <p>“SCCs” means:</p>
            <ul>
              <li>
                where the EU GDPR applies, the contractual clauses annexed to
                the European Commission's Implementing Decision 2021/914 of 4
                June 2021 on standard contractual clauses for the transfer of
                personal data to third countries, (“<a
                  href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32021D0914&from=EN/"
                  target="_blank"
                  >EU SCCs</a
                >”); and
              </li>
              <li>
                where the UK GDPR applies standard data protection clauses
                adopted pursuant to Article 46(2)(c) as set out in Schedule 3 of
                this DPA, (“<a
                  href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/international-transfers-after-uk-exit/sccs-after-transition-period/"
                  target="_blank"
                  >UK SCCs</a
                >”); and
              </li>
              <li>
                where Personal Data is transferred from Switzerland to outside
                of Switzerland or the EEA, the EU SCCs as amended in accordance
                with guidance from the Swiss Data Protection Authority; (“Swiss
                SCCs”);
              </li>
            </ul>
            <p>
              “Sub-processor” means any third party (including Processor
              Affiliates) engaged directly or indirectly by the Processor (to
              process Personal Data under this DPA in the provision of the
              Services to the Controller.
            </p>
            <p>
              “Supervisory Authority” means a governmental or government
              chartered regulatory body having binding legal authority over a
              party;
            </p>
            <p>
              "UK GDPR" means the EU GDPR as it forms part of the law of England
              and Wales, Scotland and Northern Ireland by virtue of section 3 of
              the European Union (Withdrawal) Act 2018.
            </p>
            <h2>2. Purpose</h2>
            The Processor has agreed to provide the Services to the Controller
            in accordance with the terms of the Agreement. In providing the
            Services, the Processor shall process Customer Data on behalf of the
            Controller. Customer Data may include Personal Data. The Processor
            will process and protect such Personal Data in accordance with the
            terms of this DPA.
            <h2>3. Scope</h2>
            <p>
              In providing the Services to the Controller pursuant to the terms
              of the Agreement, the Processor shall process Personal Data only
              to the extent necessary to provide the Services in accordance with
              the terms of the Agreement, this DPA and the Controller's
              instructions documented in the Agreement, as updated from time to
              time.
            </p>
            <p>
              The Controller and Processor shall take steps to ensure that any
              natural person acting under the authority of the Controller or the
              Processor who has access to Personal Data does not process them
              except on the instructions from the Controller unless he or she is
              required to do so by any Data Protection Law.
            </p>
            <h2>4. Processor Obligations</h2>
            <p>
              The Processor may collect, process or use Personal Data only
              within the scope of this DPA.
            </p>
            <p>
              The Processor confirms that it shall process Personal Data on
              behalf of the Controller in accordance with the documented
              instructions of the Controller.
            </p>
            <p>
              The Processor shall promptly inform the Controller, if in the
              Processor's opinion, any of the instructions regarding the
              processing of Personal Data provided by the Controller, breach any
              Data Protection Law.
            </p>
            <p>
              The Processor shall ensure that all employees, agents, officers
              and contractors involved in the handling of Personal Data: (i) are
              aware of the confidential nature of the Personal Data and are
              contractually bound to keep the Personal Data confidential; (ii)
              have received appropriate training on their responsibilities as a
              data processor; and (iii) are bound by the terms of this DPA.
            </p>
            <p>
              The Processor shall implement appropriate technical and
              organisational measures to protect Personal Data, taking into
              account the state of the art, the costs of implementation and the
              nature, scope, context and purposes of processing as well as the
              risk of varying likelihood and severity for the rights and
              freedoms of natural persons.
            </p>
            <p>
              The Processor shall implement appropriate technical and
              organisational measures to ensure a level of security appropriate
              to the risk, including inter alia as appropriate: (i) the
              pseudonymisation and encryption of Personal Data; (ii) the ability
              to ensure the on-going confidentiality, integrity, availability
              and resilience of processing systems and services; (iii) the
              ability to restore the availability and access to Personal Data in
              a timely manner in the event of a physical or technical incident;
              (iv) a process for regularly testing, assessing and evaluating the
              effectiveness of technical and organisational measures for
              ensuring the security of the processing. In accessing the
              appropriate level of security, account shall be taken in
              particular of the risks that are presented by processing, in
              particular from accidental or unlawful destruction, loss,
              alteration, unauthorised disclosure of, or access to Personal Data
              transmitted, stored or otherwise processed.
            </p>
            <p>
              The technical and organisational measures detailed in Schedule 1
              shall be at all times adhered to as a minimum security standard.
              The Controller accepts and agrees that the technical and
              organisational measures are subject to development and review and
              that the Processor may use alternative suitable measures to those
              detailed in the attachments to this DPA provided that such updates
              and modifications do not result in the degradation of the overall
              security of the Services.
            </p>
            <p>
              The Controller acknowledges and agrees that, in the course of
              providing the Services to the Controller, it may be necessary for
              the Processor to access the Personal Data to respond to any
              technical problems or Controller queries and to ensure the proper
              working of the Services. All such access by the Processor will be
              limited to those purposes.
            </p>
            <p>
              Taking into account the nature of the processing and the
              information available to the Processor, the Processor shall assist
              the Controller by having in place appropriate technical and
              organisational measures, insofar as this is possible, for the
              fulfilment of the Controller's obligation to respond to requests
              for exercising the Data Subject's rights and the Controller's
              compliance with the Controller's data protection obligations in
              respect of the processing of Personal Data.
            </p>
            <p>
              The Processor may not: (i) sell Personal Data; (ii) retain, use,
              or disclose Personal Data for commercial purposes other than
              providing the Services under the terms of the Agreement; or (iii)
              retain, use, or disclose Personal Data outside of the Agreement.
            </p>
            <h2>5. Controller Obligations</h2>
            <p>
              The Controller represents and warrants that: (i) it shall comply
              with the terms of the Agreement, this DPA and its obligations
              under Data Protection Law; (ii) it has obtained any and all
              necessary permissions and authorisations necessary to permit the
              Processor, its Affiliates and Sub-processors, to execute their
              rights or perform their obligations under this DPA; (iii) all
              Affiliates of the Controller who use the Services shall comply
              with the obligations of the Controller set out in this DPA.
            </p>
            <p>
              The Controller has their own obligations to implement their own
              appropriate technical and organisational measures to protect
              Personal Data, taking into account the state of the art, the costs
              of implementation and the nature, scope, context and purposes of
              processing as well as the risk of varying likelihood and severity
              for the rights and freedoms of natural persons. The Controller
              shall implement appropriate technical and organisational measures
              to ensure a level of security appropriate to the risk, including
              inter alia as appropriate: (i) the pseudonymisation and encryption
              of Personal Data; (ii) the ability to ensure the on-going
              confidentiality, integrity, availability and resilience of
              processing systems and services; (iii) the ability to restore the
              availability and access to Personal Data in a timely manner in the
              event of a physical or technical incident; (iv) a process for
              regularly testing, assessing and evaluating the effectiveness of
              technical and organisational measures for ensuring the security of
              the processing. In accessing the appropriate level of security
              account shall be taken in particular of the risks that are
              presented by processing, in particular from accidental or unlawful
              destruction, loss, alteration, unauthorised disclosure of, or
              access to Personal Data transmitted, stored or otherwise
              processed.
            </p>
            <p>
              The Controller acknowledges and agrees that some instructions from
              the Controller, including the Processor assisting with audits,
              inspections, DPIAs or providing other assistance under this DPA,
              may result in additional fees. In such case the Processor shall
              notify the Controller of its fees for providing such assistance in
              advance and shall be entitled to charge the Controller for its
              reasonable costs and expenses in providing such assistance, unless
              agreed otherwise.
            </p>
            <h2>6. Sub-processors</h2>
            <p>
              The Controller acknowledges and agrees that: (i) Affiliates of the
              Processor may be used as Sub-processors; and (ii) the Processor
              and its Affiliates respectively may engage Sub-processors in
              connection with the provision of the Services.
            </p>
            <p>
              All Sub-processors who process Personal Data in the provision of
              the Services to the Controller shall comply with the obligations
              of the Processor similar to those set out in this DPA.
            </p>
            <p>
              The Controller authorises the Processor to use the Sub-processors
              included in the list of Sub-Processors (published at
              https://transpond.io/subprocessors) to process the Personal Data.
              During the term of this DPA, the Processor shall provide the
              Controller with 30 days prior notification, via email (or
              in-application notice), of any changes to the list of
              Sub-processors before authorising any new or replacement
              Sub-processor to process Personal Data in connection with the
              provision of the Services.
            </p>
            <p>
              The Controller may object to the use of a new or replacement
              Sub-processor, by notifying the Processor promptly in writing
              within ten (10) Business Days after receipt of the Processor's
              notice. If the Controller objects to a new or replacement
              Sub-processor, the Controller may terminate the Agreement with
              respect to those Services which cannot be provided by the
              Processor without the use of the new or replacement Sub-processor.
              The Processor will refund the Controller any prepaid fees covering
              the remainder of the term of the Agreement following the effective
              date of termination with respect to such terminated Services.
            </p>
            <p>
              All Sub-processors who process Personal Data shall comply with the
              obligations of the Processor set out in this DPA. The Processor
              shall prior to the relevant Sub-processor carrying out any
              processing activities in respect of the Personal Data: (i) appoint
              each Sub-processor under a written contract containing materially
              the same obligations to those of the Processor in this DPA
              enforceable by the Processor; and (ii) ensure each such
              Sub-processor complies with all such obligations.
            </p>
            <p>
              The Controller agrees that the Processor and its Sub-processors
              may make Restricted Transfers of Personal Data for the purpose of
              providing the Services to the Controller in accordance with the
              Agreement. The Processor confirms that such Sub-processors: (i)
              are located in a third country or territory recognised by the EU
              Commission or a Supervisory Authority, as applicable, to have an
              adequate level of protection; or (ii) have entered into the
              applicable SCCs with the Processor; or (iii) have other legally
              recognised appropriate safeguards in place.
            </p>
            <h2>7. Restricted Transfers</h2>
            <p>
              The parties agree that, when the transfer of Personal Data from
              the Controller to the Processor or from the Processor to a
              Sub-processor is a Restricted Transfer, it shall be subject to the
              applicable SCCs as outlined in Schedule 4.
            </p>
            <p>
              In the event that any provision of this DPA contradicts directly
              or indirectly any SCCs, the provisions of the applicable SCCs
              shall prevail over the terms of the DPA.
            </p>
            <h2>8. Data Subject Access Requests</h2>
            <p>
              The Controller may require correction, deletion, blocking and/or
              making available the Personal Data during or after termination of
              the Agreement. The Controller acknowledges and agrees that the
              Processor will process the request to the extent it is lawful and
              will reasonably fulfil such request in accordance with its
              standard operational procedures to the extent possible.
            </p>
            <p>
              In the event that the Processor receives a request from a Data
              Subject in relation to Personal Data, the Processor will refer the
              Data Subject to the Controller unless otherwise prohibited by law.
              The Controller shall reimburse the Processor for all costs
              incurred resulting from providing reasonable assistance in dealing
              with a Data Subject request. In the event that the Processor is
              legally required to respond to the Data Subject, the Controller
              will fully cooperate with the Processor as applicable.
            </p>
            <h2>9. Audit</h2>
            <p>
              The Processor shall make available to the Controller all
              information reasonably necessary to demonstrate compliance with
              its processing obligations and allow for and contribute to audits
              and inspections.
            </p>
            <p>
              Any audit conducted under this DPA shall consist of examination of
              the most recent reports, certificates and/or extracts prepared by
              an independent auditor bound by confidentiality provisions similar
              to those set out in the Agreement. In the event that provision of
              the same is not deemed sufficient in the reasonable opinion of the
              Controller, the Controller may at its own expense conduct a more
              extensive audit which will be: (i) limited in scope to matters
              specific to the Controller and agreed in advance with the
              Processor; (ii) carried out during the Processor's usual business
              hours and upon reasonable notice which shall be not less than 4
              weeks unless an identifiable material issue has arisen; and (iii)
              conducted in a way which does not interfere with the Processor's
              day-to-day business. The Processor may charge a fee (based on its
              reasonable time and costs) for assisting with any audit. The
              Processor will provide the Controller with further details of any
              applicable fee, and the basis of its calculation, in advance of
              any such audit.
            </p>
            <p>
              This clause shall not modify or limit the rights of audit of the
              Controller, instead it is intended to clarify the procedures in
              respect of any audit undertaken pursuant thereto.
            </p>
            <h2>10. Personal Data Breach</h2>
            <p>
              The Processor shall notify the Controller without undue delay
              after becoming aware of (and in any event within 72 hours of
              discovering) any accidental or unlawful destruction, loss,
              alteration or unauthorised disclosure or access to any Personal
              Data (“Personal Data Breach”).
            </p>
            <p>
              The Processor will take all commercially reasonable measures to
              secure the Personal Data, to limit the effects of any Personal
              Data Breach and to assist the Controller in meeting the
              Controller's obligations under applicable law.
            </p>
            <p>
              The Processor's notification of, or response to, a Personal Data
              Breach under this Section 10 will not be construed as an
              acknowledgement by the Processor of any fault or liability with
              respect to the Personal Data Breach.
            </p>
            <h2>11. Compliance, Cooperation and Response</h2>
            <p>
              The Processor will notify the Controller promptly of any request
              or complaint regarding the processing of Personal Data, which
              adversely impacts the Controller, unless such notification is not
              permitted under applicable law or a relevant court order.
            </p>
            <p>
              The Processor may make copies of and/or retain Personal Data in
              order to comply with any legal or regulatory requirement
              including, but not limited to, retention requirements.
            </p>
            <p>
              The Processor shall reasonably assist the Controller in meeting
              the Controller's obligation to carry out data protection impact
              assessments (DPIAs), taking into account the nature of the
              processing and the information available to the Processor.
            </p>
            <p>
              The Controller shall notify the Processor within a reasonable
              time, of any changes to applicable data protection laws, codes or
              regulations which may affect the contractual duties of the
              Processor. The Processor shall respond within a reasonable
              timeframe in respect of any changes that need to be made to the
              terms of this DPA or to the technical and organisational measures
              to maintain compliance. If the Processor is unable to accommodate
              necessary changes, the Controller may terminate the part or parts
              of the Services which give rise to the non-compliance. To the
              extent that other parts of the Services provided are not affected
              by such changes, the provision of those Services shall remain
              unaffected.
            </p>
            <p>
              The Controller and the Processor and, where applicable, their
              representatives, shall cooperate, on request, with a Supervisory
              Authority in the performance of their respective obligations under
              this DPA and Data Protection Law.
            </p>
            <p>
              The parties agree that the Processor will be entitled to charge
              the Controller additional fees to reimburse the Processor for its
              staff time, costs and expenses in assisting the Controller, when
              the Controller requests the Processor to provide assistance
              pursuant to this DPA. In such cases, the Processor will notify the
              Controller of its fees for providing assistance, in advance.
            </p>
            <h2>12. Liability</h2>
            <p>
              The limitations on liability set out in the Agreement apply to all
              claims made pursuant to any breach of the terms of this DPA.
            </p>
            <p>
              The parties agree that the Processor shall be liable for any
              breaches of this DPA caused by the acts and omissions or
              negligence of its Sub-processors to the same extent the Processor
              would be liable if performing the services of each Sub-processor
              directly under the terms of the DPA, subject to any limitations on
              liability set out in the terms of the Agreement.
            </p>
            <p>
              The parties agree that the Controller shall be liable for any
              breaches of this DPA caused by the acts and omissions or
              negligence of its Affiliates as if such acts, omissions or
              negligence had been committed by the Controller itself.
            </p>
            <p>
              The Controller shall not be entitled to recover more than once in
              respect of the same loss.
            </p>
            <h2>13. Term and Termination</h2>
            <p>
              The Processor will only process Personal Data for the term of the
              DPA. The term of this DPA shall coincide with the commencement of
              the Agreement and this DPA shall terminate automatically together
              with termination or expiry of the Agreement.
            </p>
            <h2>14. Deletion and Return of Personal Data</h2>
            <p>
              The Processor will enable the Controller to delete Personal Data
              using the functionality provided by the Service at any time. For
              certain deletions, a recovery feature is offered by the Processor
              to enable recovery from accidental deletions for up to 30 days.
              This may be overridden by the Controller. After any recovery
              period, the Processor will permanently delete the Personal Data
              from the live systems.
            </p>
            <p>
              On termination, the Controller has the option to request the
              return or deletion of Personal Data. This request must be made
              within 14 days of termination. The Processor will make the data
              available for download by the Controller in a machine readable
              format. The Processor will permanently delete the Personal Data
              from the live systems in any event within 14 days of termination
              of the Agreement.
            </p>
            <p>
              Following permanent deletion from the live systems, partial data
              resides on the Processor's backup systems in accordance with its
              then current data back-up policy. If requested by the Controller,
              the Processor may be able to assist with recovery of partial data
              from these backups during this period. A fee will be charged for
              this service.
            </p>
            <p>
              In addition, where any law, regulation, or government or
              regulatory body requires the Processor to retain any documents or
              materials containing any part or all of the Personal Data, the
              Processor shall be entitled to retain such documents or materials
              for the duration of such retention requirement.
            </p>
            <h2>15. General</h2>
            <p>
              Should a provision of this DPA be invalid or become invalid then
              the legal effect of the other provisions shall be unaffected. A
              valid provision is deemed to have been agreed which comes closest
              to what the parties intended commercially and shall replace the
              invalid provision. The same shall apply to any omissions.
            </p>
            <p>
              Subject to any provisions of the SCCs to the contrary, this DPA
              shall be governed by the laws of England and Wales. The courts of
              England shall have exclusive jurisdiction for the settlement of
              all disputes arising under this DPA.
            </p>
            <p>
              The parties agree that this DPA is incorporated into and governed
              by the terms of the Agreement.
            </p>
            <h1>Schedule 1</h1>
            <p>
              List of Parties, Description of Processing and Transfer of
              Personal Data, Competent Supervisory Authority
            </p>
            <h2>MODULE TWO: CONTROLLER TO PROCESSOR</h2>
            <h3>A. LIST OF PARTIES</h3>
            <p><strong>The Controller: means the Agency.</strong></p>
            <div class="terms-table terms-table--2-col">
              <table>
                <tbody>
                  <tr>
                    <th>Address:</th>
                    <td>As set out for the Agency in the Agreement.</td>
                  </tr>
                  <tr>
                    <th>
                      Contact person's name, position and contact details:
                    </th>
                    <td>
                      As provided by the Agency in its account and used for
                      notification and invoicing purposes.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Activities relevant to the data transferred under the
                      SCCs:
                    </th>
                    <td>Use of the Services.</td>
                  </tr>
                  <tr>
                    <th>Signature and date:</th>
                    <td>
                      By entering into the Agreement, the Controller is deemed
                      to have signed the SCCs incorporated into this DPA and
                      including their Annexes, as of the Effective Date of the
                      Agreement.
                    </td>
                  </tr>
                  <tr>
                    <th>Role:</th>
                    <td>Data Exporter.</td>
                  </tr>
                  <tr>
                    <th>Name of Representative (if applicable):</th>
                    <td>
                      Any UK or EU representative named in the Controller's
                      privacy policy.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <strong
                >The Processor: means the Company: Transpond Limited</strong
              >
            </p>
            <div class="terms-table terms-table--2-col">
              <table>
                <tbody>
                  <tr>
                    <th>Address:</th>
                    <td>
                      Level 1, 20 Dale Street, Manchester, M1 1EZ, England.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Contact person's name, position and contact details:
                    </th>
                    <td>support@mpzmail.com</td>
                  </tr>
                  <tr>
                    <th>
                      Activities relevant to the data transferred under the
                      SCCs:
                    </th>
                    <td>
                      The provision of online marketing solutions to the
                      Controller under which the Processor processes Personal
                      Data upon the instructions of the Controller in accordance
                      with the terms of the Agreement.
                    </td>
                  </tr>
                  <tr>
                    <th>Signature and date:</th>
                    <td>
                      By entering into the Agreement, the Processor is deemed to
                      have signed the SCCs, incorporated into this DPA,
                      including their Annexes, as of the Effective Date of the
                      Agreement.
                    </td>
                  </tr>
                  <tr>
                    <th>Role:</th>
                    <td>Data Importer</td>
                  </tr>
                  <tr>
                    <th>Name of Representative (if applicable):</th>
                    <td>Data Protection Representative Limited</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3>B. DESCRIPTION OF PROCESSING AND TRANSFERS</h3>
            <div class="terms-table terms-table--2-col">
              <table>
                <tbody>
                  <tr>
                    <th>Categories of data subjects:</th>
                    <td>
                      Individuals about whom data is uploaded to the Services by
                      (or at the direction of) the Controller or by Users,
                      Affiliates and other participants whom the Controller has
                      granted the right to access the Services in accordance
                      with the provisions of the Agreement including but not
                      limited to:
                      <ul>
                        <li>
                          Prospects, customers, clients, business partners,
                          vendors and suppliers (who are natural persons)
                        </li>
                        <li>
                          Employees, agents, advisors, consultants, freelancers
                          (who are natural persons).
                        </li>
                        <li>
                          Individuals with whom Users communicate by email
                          and/or other messaging media.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Categories of Personal Data:</th>
                    <td>
                      Data relating to individuals uploaded to the Service by
                      (or at the direction of) the Controller or by Users,
                      Affiliates and other participants whom the Controller has
                      granted the right to access the Services in accordance
                      with the provisions of the Agreement. The Personal Data
                      includes but is not limited to:
                      <ul>
                        <li>Email Address, Phone Number</li>
                        <li>
                          Personal Data held in custom fields defined by the
                          Controller or Users
                        </li>
                        <li>
                          IP address of browsers or email clients that contacts
                          use
                        </li>
                        <li>
                          Geolocation of contacts browser or email clients
                        </li>
                        <li>
                          Information offered by Users as part of support
                          enquiries
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>Sensitive Data:</th>
                    <td>
                      No sensitive data (special category data) will be
                      processed or transferred and shall not be contained in the
                      content of, or attachments to, emails.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      The frequency of the processing and transfer (e.g. whether
                      the data is transferred on a one-off or continuous basis):
                    </th>
                    <td>Continuous basis for the duration of the Agreement.</td>
                  </tr>
                  <tr>
                    <th>Nature of the processing:</th>
                    <td>
                      Processing operations include but are not limited to:
                      providing email campaign &amp; marketing automation
                      services to users of the Services.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Purpose(s) of the data transfer and further processing:
                    </th>
                    <td>
                      Personal Data is transferred to sub-contractors who need
                      to process some of the Personal Data in order to provide
                      their services to the Processor as part of the Services
                      provided by the Processor to the Controller.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      The period for which the Personal Data will be retained,
                      or, if that is not possible, the criteria used to
                      determine that period:
                    </th>
                    <td>
                      Unless agreed otherwise in writing, for the duration of
                      the Agreement, subject to clause 14 of the DPA.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      For transfers to (Sub-) processors, also specify subject
                      matter, nature and duration of the processing:
                    </th>
                    <td>
                      The Sub-processor list (<a
                        href="https://transpond.io/subprocessors"
                        target="_blank"
                        rel="noopener noreferrer"
                        >https://transpond.io/subprocessors</a
                      >) sets out the Personal Data processed by each
                      Sub-processor and the services provided by each
                      Sub-processor.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3>C. COMPETENT SUPERVISORY AUTHORITY</h3>
            <div class="terms-table terms-table--2-col">
              <table>
                <tbody>
                  <tr>
                    <th>
                      Identify the competent supervisory authority/ies (e.g. in
                      accordance with Clause 13 of the SCCs)
                    </th>
                    <td>
                      <p>
                        Where the EU GDPR applies, the Irish Data Protection
                        Authority - the Data Protection Commission (DPC).
                      </p>
                      <p>
                        Where the UK GDPR applies, the UK Information
                        Commissioner's Office, (ICO).
                      </p>
                      <p>
                        Where the FDPA applies, the Swiss Federal Data
                        Protection and Information Commissioner, (FDPIC).
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2>MODULE THREE: PROCESSOR TO PROCESSOR</h2>
            <h3>A. LIST OF PARTIES</h3>
            <p><strong>The Data Exporter</strong>: is the Company.</p>
            <p>
              <strong>The Data Importers</strong>: are the Sub-processors named
              in the Sub-processor list published at
              <a
                href="https://transpond.io/subprocessors"
                target="_blank"
                rel="noopener noreferrer"
                >https://transpond.io/subprocessors</a
              >
              which contains the name, address, contact details and activities
              relevant to the data transferred to each Data Importer.
            </p>
            <h3>B. DESCRIPTION OF PROCESSING AND TRANSFERS</h3>
            <p>
              The Sub-processor list includes the information about the
              processing and transfers of the Personal Data, for each Data
              Importer:
            </p>
            <ul>
              <li>categories of Data Subjects</li>
              <li>categories of Personal Data</li>
              <li>the nature of the processing</li>
              <li>the purposes of the processing</li>
            </ul>
            <p>Personal Data is processed by each Sub-processor:</p>
            <ul>
              <li>on a continuous basis</li>
              <li>
                to the extent necessary to provide the Services in accordance
                with the Agreement and the Data Exporter's instructions.
              </li>
              <li>
                for the duration of the Agreement and subject to clause 14 of
                the DPA.
              </li>
            </ul>
            <h3>C. COMPETENT SUPERVISORY AUTHORITY</h3>
            <p>
              The competent Supervisory Authority of the Data Exporter shall be:
            </p>
            <ul>
              <li>
                Where the EU GDPR applies, the Irish Data Protection Authority –
                the Data Protection Commission (DPC).
              </li>
              <li>
                Where the UK GDPR applies, the UK Information Commissioner's
                Office, (ICO).
              </li>
              <li>
                Where the FDPA applies, the Swiss Federal Data Protection and
                Information Commissioner, (FDPIC).
              </li>
            </ul>
            <h1>Schedule 2</h1>
            <p>
              <strong>Technical and Organisational Security Measures</strong>
            </p>
            <p>
              <strong
                >(Including Technical and Organisational Measures to Ensure the
                Security of Data)</strong
              >
            </p>
            <p>
              Below is a description of the technical and organisational
              measures implemented by the Processor(s) / Data Importer(s)
              (including any relevant certifications) to ensure an appropriate
              level of security, taking into account the nature, scope, context
              and purpose of the processing, and the risks for the rights and
              freedoms of natural persons.
            </p>
            <p>
              Full details of the Processor's/Data Importer's technical and
              organisational security measures used to protect Personal Data can
              be requested.
            </p>
            <p>
              Where applicable this Schedule 2 will serve as Annex II to the
              SCCs.
            </p>
            <div class="terms-table terms-table--2-col">
              <table>
                <thead>
                  <th>Measure</th>
                  <th>Description</th>
                </thead>
                <tbody>
                  <tr>
                    <th>Measures of encryption of Personal Data</th>
                    <td>
                      Data transmitted between the Agency and our application is
                      encrypted over public networks using Transport Layer
                      Security (“TLS”).
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring ongoing confidentiality, integrity,
                      availability and resilience of processing systems and
                      services
                    </th>
                    <td>
                      <p>
                        The Processor utilises Digital Ocean with extensive
                        application and infrastructure monitoring with 24x7
                        application support rosters.
                      </p>
                      <p>
                        We maintain redundancy throughout our IT infrastructure
                        in order to minimise the lack of availability to or loss
                        of data.
                      </p>
                      <p>
                        The operation of Processor's service requires that some
                        employees have access to the systems which store and
                        process Personal Data. These employees are prohibited
                        from using these permissions to view Personal Data
                        unless it is necessary to do so. All of the Processor's
                        employees and contract personnel are bound to our
                        policies regarding Personal Data and we treat these
                        issues as matters of the highest importance within our
                        company. All staff employment contracts have full
                        confidentiality clauses and all employees are required
                        to read and sign our comprehensive information security
                        policy covering the security, availability, and
                        confidentiality of Processor's service.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring the ability to restore the
                      availability and access to Personal Data in a timely
                      manner in the event of a physical or technical incident
                    </th>
                    <td>
                      Data is stored in duplicate. Should a single duplicate
                      fail the service can be restored without data loss. In
                      addition frequent backups are taken so in the unlikely
                      event of both original copies of the data being lost the
                      backups can be used to restore the service with minimal
                      loss of data.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Processes for regularly testing, assessing and evaluating
                      the effectiveness of technical and organisational measures
                      in order to ensure the security of the processing
                    </th>
                    <td>
                      We strive to automate audits hence the majority of our
                      monitoring of our infrastructure is automated and running
                      24/7. We obtain an external security and compliance audit
                      once per calendar year.
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for user identification and authorisation</th>
                    <td>
                      <p>
                        Passwords for authentication are salted and hashed using
                        industry standard hashing functions.
                      </p>
                      <p>
                        The Controller invites and removes users and applies
                        permission levels in the account. Users can enable Two
                        Factor Authentication to provide additional protection.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring physical security of locations at
                      which Personal Data are processed
                    </th>
                    <td>
                      The Service is hosted and Personal Data is stored within
                      data centres provided by Digital Ocean (DO). As such, the
                      Processor relies on the physical, environmental and
                      infrastructure controls of DO. The Processor periodically
                      reviews certifications and third-party attestations
                      provided by DO relating to the effectiveness of its data
                      centre controls.#
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring events logging</th>
                    <td>
                      System events are recorded in the form of log files
                      therefore it is possible to review retroactively whether
                      and by who Personal Data was entered, altered or deleted.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for ensuring system configuration, including
                      default configuration
                    </th>
                    <td>
                      <p>
                        Security patches are applied to the application
                        dependencies as issues are identified via 3rd party
                        monitoring platform(s).
                      </p>
                      <p>
                        Server operating systems and third party tools are
                        updated automatically when new updates are available.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for internal IT and IT security governance and
                      management
                    </th>
                    <td>
                      Access to systems which store and process Personal Data is
                      subject to role based need. Employees have individual
                      logins for such systems. Two factor authentication must be
                      enabled where available.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for certification/assurance of processes and
                      products
                    </th>
                    <td>
                      The Processor utilises third party data centres that
                      maintain current ISO 27001 certifications and SOC 2
                      Attestation Reports. The Processor will not utilise third
                      party data centres used for primary storage that do not
                      maintain the aforementioned certifications and/or
                      attestations, or other substantially similar or equivalent
                      certifications and/or attestations.
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring data minimisation</th>
                    <td>
                      <p>
                        The Controller decides the Personal Data to be stored on
                        the Service and is responsible for defining its own
                        policies for minimising data collection and storage.
                      </p>
                      <p>
                        If Personal Data is no longer required the Controller
                        can delete it from the service. See “Measures for
                        ensuring limited data retention”.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring data quality</th>
                    <td>
                      We do not assess the quality of the data provided by the
                      Controller. We provide reporting tools within our product
                      to help the Controller understand and validate the data
                      that is stored.
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring limited data retention</th>
                    <td>
                      <p>
                        The Controller is responsible for defining its own
                        retention policies and using the tools provided by the
                        Service to delete Personal Data.
                      </p>
                      <p>
                        If Personal Data is no longer required the Controller
                        can delete it from the service. It should be noted that
                        with each deletion the data is in the first instance
                        locked and then permanently deleted from the production
                        system after a certain delay. This is done in order to
                        prevent accidental deletions.
                      </p>
                      <p>
                        Cancellation or Termination of the Service will also
                        automatically result in permanent deletion of Personal
                        Data after a certain recovery period.
                      </p>
                      <p>
                        Following permanent deletion from the live systems,
                        partial data resides on the Processor's backup archives
                        and is removed over time in line with our data retention
                        policy (<a
                          href="https://transpond.io/dpa_cancellation"
                          target="_blank"
                          rel="noopener noreferrer"
                          >https://transpond.io/dpa_cancellation</a
                        >).
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>Measures for ensuring accountability</th>
                    <td>
                      <p>
                        The Processor has designated local representation in
                        Europe and the United Kingdom.
                      </p>
                      <p>
                        Our contact details in the United Kingdom
                        <a href="mailto:support@mpzmail.com"
                          >support@mpzmail.com</a
                        >.
                      </p>
                      <p>Contact details of our European representative</p>
                      <ul>
                        <li>
                          Email:
                          <a href="mpzmail@datarep.com">mpzmail@datarep.com</a>
                        </li>
                        <li>
                          Online webform:
                          <a
                            href="https://www.datarep.com/data-request"
                            target="_blank"
                            rel="noopener noreferrer"
                            >https://www.datarep.com/data-request</a
                          >
                        </li>
                        <li>
                          Addresses:
                          <a
                            href="https://transpond.io/gdprcontactlocations"
                            target="_blank"
                            rel="noopener noreferrer"
                            >View all EU rep contact locations</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures for allowing data portability and ensuring
                      erasure
                    </th>
                    <td>
                      The Service has built-in tools that allow the Controller
                      to export and permanently erase data.
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Measures to be taken by the (Sub-) processor to be able to
                      provide assistance to the Controller (and, for transfers
                      from a Processor to a Sub-processor, to the Data
                      Exporter).
                    </th>
                    <td>
                      The transfer of Personal Data to a third party (e.g.
                      customers, sub-contractors, service providers) is only
                      made if a corresponding contract exists, and only for the
                      specific purposes. If Personal Data is transferred outside
                      the EEA, the Processor provides that an adequate level of
                      data protection exists at the target location or
                      organisation in accordance with the European Union's data
                      protection requirements, e.g. by employing contracts based
                      on the EU SCCs.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h1>Schedule 3</h1>
              <p>
                International Data Transfer Addendum to the EU Commission
                Standard Contractual clauses
              </p>
              <p style="font-weight: bold">
                VERSION B1.0, in force 21 March 2022
              </p>
              <hr />
              <p>
                This Addendum has been issued by the Information Commissioner
                for Parties making Restricted Transfers. The Information
                Commissioner considers that it provides Appropriate Safeguards
                for Restricted Transfers when it is entered into as a legally
                binding contract.
              </p>
              <h3>Part 1: Tables</h3>
              <p>Table 1: Parties</p>
              <div class="terms-table terms-table--3-col">
                <table cellPadding="10" cellSpacing="0">
                  <tr>
                    <td>
                      <strong>Start Date</strong>
                    </td>
                    <td colspan="2">
                      The date set out in Annex I of the Approved EU SCCs.
                    </td>
                  </tr>
                  <tr>
                    <td><strong>The Parties</strong></td>
                    <td>
                      <strong
                        >Exporter (who sends the Restricted Transfer)</strong
                      >
                    </td>
                    <td>
                      <strong
                        >Importer (who receives the Restricted Transfer)</strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Parties details</strong></td>
                    <td>
                      Full legal name: the Customer named in the Agreement. Main
                      address (if a company registered address): As set out in
                      Annex I of the Approved EU SCCs. Official registration
                      number (if any) (company number or similar identifier):
                      Where set out in the Agreement.
                    </td>
                    <td>
                      Full legal name: MPZMail Limited Main address: Level 1, 20
                      Dale Street, Manchester, M1 1EZ, England Official
                      registration number (if any) (company number or similar
                      identifier): 11234241
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Key Contact</strong></td>
                    <td>
                      Full Name (optional): As set out in Annex I of the
                      Approved EU SCCs. Job Title: As set out in Annex I in the
                      Approved EU SCCs Contact details including email: As set
                      out in Annex I the Approved EU SCCs.
                    </td>
                    <td>
                      Job Title: DPO Contact details including email:
                      support@mpzmail.com
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >Signature (if required for the purposes of Section
                        2)</strong
                      >
                    </td>
                    <td>no signature is required.</td>
                    <td>no signature is required.</td>
                  </tr>
                </table>
              </div>
              <p>Table 2: Selected SCCs, Modules and Selected Clauses</p>
              <div class="terms-table terms-table--3-col">
                <table cellPadding="10" cellSpacing="0">
                  <tr>
                    <td>
                      <strong>Addendum EU SCCs</strong>
                    </td>
                    <td>
                      the Approved EU SCCs, including the Appendix Information
                      and with only the following modules, clauses or optional
                      provisions of the Approved EU SCCs brought into effect for
                      the purposes of this Addendum:
                    </td>
                  </tr>
                </table>
              </div>
              <div class="terms-table terms-table--3-col">
                <table cellPadding="10" cellSpacing="0">
                  <tr>
                    <td>Module</td>
                    <td>Module in operation</td>
                    <td>Clause 11 (Option)</td>
                    <td>Clause 9a General Authorisation</td>
                    <td>Clause 9a (Time period)</td>
                    <td>
                      Is personal data received from the Importer combined with
                      personal data collected by the Exporter?
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>no</td>
                    <td>not used</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>yes</td>
                    <td>not used</td>
                    <td>yes</td>
                    <td>30 days</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>yes</td>
                    <td>not used</td>
                    <td>yes</td>
                    <td>30 days</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>no</td>
                    <td>not used</td>
                    <td>-</td>
                    <td>-</td>
                    <td>no</td>
                  </tr>
                </table>
              </div>
              <p>Table 3: Appendix Information</p>
              <p>
                “Appendix Information” means the information which must be
                provided for the selected modules as set out in the Appendix of
                the Approved EU SCCs (other than the Parties), and which for
                this Addendum is set out in:
              </p>
              <div class="terms-table terms-table--3-col">
                <table cellPadding="10" cellSpacing="0">
                  <tr>
                    <td>
                      Annex 1A: List of Parties: for Module 2 and Module 3
                    </td>
                    <td>
                      Annex 1B: Description of Transfer: for Module 2 and Module
                      3
                    </td>
                    <td>
                      Annex II: Technical and organisational measures including
                      technical and organisational measures to ensure the
                      security of the data: for Module 2
                    </td>
                  </tr>
                </table>
              </div>
              <p>
                Table 4: Ending this Addendum when the Approved Addendum Changes
              </p>
              <div class="terms-table terms-table--3-col">
                <table cellPadding="10" cellSpacing="0">
                  <tr>
                    <td><strong>Ending this Addendum</strong></td>
                    <td>
                      Which Parties may end this Addendum as set out in Section
                      19:
                    </td>
                  </tr>
                  <tr>
                    <td><strong>when the Approved Addendum changes</strong></td>
                    <td>
                      Importer<br />
                      Exporter
                    </td>
                  </tr>
                </table>
              </div>
              <h3>Part 2: Mandatory Clauses</h3>
              <p>Entering into this Addendum</p>
              <ol>
                <li>
                  Each Party agrees to be bound by the terms and conditions set
                  out in this Addendum, in exchange for the other Party also
                  agreeing to be bound by this Addendum.
                </li>
                <li>
                  Although Annex 1A and Clause 7 of the Approved EU SCCs require
                  signature by the Parties, for the purpose of making Restricted
                  Transfers, the Parties may enter into this Addendum in any way
                  that makes them legally binding on the Parties and allows data
                  subjects to enforce their rights as set out in this Addendum.
                  Entering into this Addendum will have the same effect as
                  signing the Approved EU SCCs and any part of the Approved EU
                  SCCs.
                </li>
                <div style="margin-left: -20px; margin-bottom: 15px">
                  Interpretation of this Addendum
                </div>
                <li>
                  Where this Addendum uses terms that are defined in the
                  Approved EU SCCs those terms shall have the same meaning as in
                  the Approved EU SCCs. In addition, the following terms have
                  the following meanings:
                  <br />
                  <div class="terms-table terms-table--3-col">
                    <table cellPadding="10" cellSpacing="0">
                      <tr>
                        <td>Addendum</td>
                        <td>
                          This International Data Transfer Addendum which is
                          made up of this Addendum incorporating the Addendum EU
                          SCCs.
                        </td>
                      </tr>
                      <tr>
                        <td>Addendum EU SCCs</td>
                        <td>
                          The version(s) of the Approved EU SCCs which this
                          Addendum is appended to, as set out in Table 2,
                          including the Appendix Information.
                        </td>
                      </tr>
                      <tr>
                        <td>Appendix Information</td>
                        <td>As set out in Table 3.</td>
                      </tr>
                      <tr>
                        <td>Appropriate Safeguards</td>
                        <td>
                          The standard of protection over the personal data and
                          of data subjects’ rights, which is required by UK Data
                          Protection Laws when you are making a Restricted
                          Transfer relying on standard data protection clauses
                          under Article 46(2)(d) UK GDPR.
                        </td>
                      </tr>
                      <tr>
                        <td>Approved Addendum</td>
                        <td>
                          The template Addendum issued by the ICO and laid
                          before Parliament in accordance with s119A of the Data
                          Protection Act 2018 on 2 February 2022, as it is
                          revised under Section 18.
                        </td>
                      </tr>
                      <tr>
                        <td>Approved EU SCCs</td>
                        <td>
                          The Standard Contractual Clauses set out in the Annex
                          of Commission Implementing Decision (EU) 2021/914 of 4
                          June 2021.
                        </td>
                      </tr>
                      <tr>
                        <td>ICO</td>
                        <td>The Information Commissioner.</td>
                      </tr>
                      <tr>
                        <td>Restricted Transfer</td>
                        <td>
                          A transfer which is covered by Chapter V of the UK
                          GDPR.
                        </td>
                      </tr>
                      <tr>
                        <td>UK</td>
                        <td>
                          The United Kingdom of Great Britain and Northern
                          Ireland.
                        </td>
                      </tr>
                      <tr>
                        <td>UK Data Protection Laws</td>
                        <td>
                          All laws relating to data protection, the processing
                          of personal data, privacy and/or electronic
                          communications in force from time to time in the UK,
                          including the UK GDPR and the Data Protection Act
                          2018.
                        </td>
                      </tr>
                      <tr>
                        <td>UK GDPR</td>
                        <td>
                          As defined in section 3 of the Data Protection Act
                          2018.
                        </td>
                      </tr>
                    </table>
                  </div>
                </li>
                <li>
                  This Addendum must always be interpreted in a manner that is
                  consistent with UK Data Protection Laws and so that it fulfils
                  the Parties’ obligation to provide the Appropriate Safeguards
                </li>
                <li>
                  If the provisions included in the Addendum EU SCCs amend the
                  Approved SCCs in any way which is not permitted under the
                  Approved EU SCCs or the Approved Addendum, such amendment(s)
                  will not be incorporated in this Addendum and the equivalent
                  provision of the Approved EU SCCs will take their place.
                </li>
                <li>
                  If there is any inconsistency or conflict between UK Data
                  Protection Laws and this Addendum, UK Data Protection Laws
                  applies.
                </li>
                <li>
                  If the meaning of this Addendum is unclear or there is more
                  than one meaning, the meaning which most closely aligns with
                  UK Data Protection Laws applies.
                </li>
                <li>
                  Any references to legislation (or specific provisions of
                  legislation) means that legislation (or specific provision) as
                  it may change over time. This includes where that legislation
                  (or specific provision) has been consolidated, re-enacted
                  and/or replaced after this Addendum has been entered into.
                </li>
                <div style="margin-left: -20px; margin-bottom: 15px">
                  Hierarchy
                </div>
                <li>
                  Although Clause 5 of the Approved EU SCCs sets out that the
                  Approved EU SCCs prevail over all related agreements between
                  the parties, the parties agree that, for Restricted Transfers,
                  the hierarchy in Section 10 will prevail.
                </li>
                <li>
                  Where there is any inconsistency or conflict between the
                  Approved Addendum and the Addendum EU SCCs (as applicable),
                  the Approved Addendum overrides the Addendum EU SCCs, except
                  where (and in so far as) the inconsistent or conflicting terms
                  of the Addendum EU SCCs provides greater protection for data
                  subjects, in which case those terms will override the Approved
                  Addendum.
                </li>
                <li>
                  Where this Addendum incorporates Addendum EU SCCs which have
                  been entered into to protect transfers subject to the General
                  Data Protection Regulation (EU) 2016/679 then the Parties
                  acknowledge that nothing in this Addendum impacts those
                  Addendum EU SCCs.
                </li>
                <div style="margin-left: -20px; margin-bottom: 15px">
                  Incorporation of and changes to the EU SCCs
                </div>
                <li>
                  This Addendum incorporates the Addendum EU SCCs which are
                  amended to the extent necessary so that:
                  <ol type="a">
                    <li>
                      together they operate for data transfers made by the data
                      exporter to the data importer, to the extent that UK Data
                      Protection Laws apply to the data exporter’s processing
                      when making that data transfer, and they provide
                      Appropriate Safeguards for those data transfers;
                    </li>
                    <li>
                      Sections 9 to 11 override Clause 5 (Hierarchy) of the
                      Addendum EU SCCs; and
                    </li>
                    <li>
                      this Addendum (including the Addendum EU SCCs incorporated
                      into it) is (1) governed by the laws of England and Wales
                      and (2) any dispute arising from it is resolved by the
                      courts of England and Wales, in each case unless the laws
                      and/or courts of Scotland or Northern Ireland have been
                      expressly selected by the Parties.
                    </li>
                  </ol>
                </li>
                <li>
                  Unless the Parties have agreed alternative amendments which
                  meet the requirements of Section 12, the provisions of Section
                  15 will apply.
                </li>
                <li>
                  No amendments to the Approved EU SCCs other than to meet the
                  requirements of Section 12 may be made.
                </li>
                <li>
                  The following amendments to the Addendum EU SCCs (for the
                  purpose of Section 12) are made.
                  <ol type="a">
                    <li>
                      References to the “Clauses” means this Addendum,
                      incorporating the Addendum EU SCCs;
                    </li>
                    <li>
                      In Clause 2, delete the words:<br />“and, with respect to
                      data transfers from controllers to processors and/or
                      processors to processors, standard contractual clauses
                      pursuant to Article 28(7) of Regulation (EU) 2016/679”;
                    </li>
                    <li>
                      Clause 6 (Description of the transfer(s)) is replaced
                      with:<br />“The details of the transfers(s) and in
                      particular the categories of personal data that are
                      transferred and the purpose(s) for which they are
                      transferred) are those specified in Annex I.B where UK
                      Data Protection Laws apply to the data exporter’s
                      processing when making that transfer.”;
                    </li>
                    <li>
                      Clause 8.7(i) of Module 1 is replaced with:<br />“it is to
                      a country benefitting from adequacy regulations pursuant
                      to Section 17A of the UK GDPR that covers the onward
                      transfer”;
                    </li>
                    <li>
                      Clause 8.8(i) of Modules 2 and 3 is replaced with:<br />“the
                      onward transfer is to a country benefitting from adequacy
                      regulations pursuant to Section 17A of the UK GDPR that
                      covers the onward transfer;”
                    </li>
                    <li>
                      References to “Regulation (EU) 2016/679”, “Regulation (EU)
                      2016/679 of the European Parliament and of the Council of
                      27 April 2016 on the protection of natural persons with
                      regard to the processing of personal data and on the free
                      movement of such data (General Data Protection
                      Regulation)” and “that Regulation” are all replaced by “UK
                      Data Protection Laws”. References to specific Article(s)
                      of “Regulation (EU) 2016/679” are replaced with the
                      equivalent Article or Section of UK Data Protection Laws;
                    </li>
                    <li>
                      References to Regulation (EU) 2018/1725 are removed;
                    </li>
                    <li>
                      References to the “European Union”, “Union”, “EU”, “EU
                      Member State”, “Member State” and “EU or Member State” are
                      all replaced with the “UK”;
                    </li>
                    <li>
                      The reference to “Clause 12(c)(i)” at Clause 10(b)(i) of
                      Module one, is replaced with “Clause 11(c)(i)”;
                    </li>
                    <li>Clause 13(a) and Part C of Annex I are not used;</li>
                    <li>
                      The “competent supervisory authority” and “supervisory
                      authority” are both replaced with the “Information
                      Commissioner”;
                    </li>
                    <li>
                      In Clause 16(e), subsection (i) is replaced with:<br />“the
                      Secretary of State makes regulations pursuant to Section
                      17A of the Data Protection Act 2018 that cover the
                      transfer of personal data to which these clauses apply;”;
                    </li>
                    <li>
                      Clause 17 is replaced with:<br />“These Clauses are
                      governed by the laws of England and Wales.”;
                    </li>
                    <li>
                      Clause 18 is replaced with:<br />“Any dispute arising from
                      these Clauses shall be resolved by the courts of England
                      and Wales. A data subject may also bring legal proceedings
                      against the data exporter and/or data importer before the
                      courts of any country in the UK. The Parties agree to
                      submit themselves to the jurisdiction of such courts.”;
                      and
                    </li>
                    <li>
                      The footnotes to the Approved EU SCCs do not form part of
                      the Addendum, except for footnotes 8, 9, 10 and 11.
                    </li>
                  </ol>
                </li>
                <div style="margin-left: -20px; margin-bottom: 15px">
                  Amendments to this Addendum
                </div>
                <li>
                  The Parties may agree to change Clauses 17 and/or 18 of the
                  Addendum EU SCCs to refer to the laws and/or courts of
                  Scotland or Northern Ireland.
                </li>
                <li>
                  If the Parties wish to change the format of the information
                  included in Part 1: Tables of the Approved Addendum, they may
                  do so by agreeing to the change in writing, provided that the
                  change does not reduce the Appropriate Safeguards.
                </li>
                <li>
                  From time to time, the ICO may issue a revised Approved
                  Addendum which:
                  <ol type="a">
                    <li>
                      makes reasonable and proportionate changes to the Approved
                      Addendum, including correcting errors in the Approved
                      Addendum; and/or
                    </li>
                    <li>reflects changes to UK Data Protection Laws;</li>
                  </ol>
                </li>
                <div style="margin-left: -20px; margin-bottom: 15px">
                  The revised Approved Addendum will specify the start date from
                  which the changes to the Approved<br />
                  Addendum are effective and whether the Parties need to review
                  this Addendum including the Appendix Information. This
                  Addendum is automatically amended as set out in the revised
                  Approved Addendum from the start date specified.
                </div>
                <li>
                  If the ICO issues a revised Approved Addendum under Section
                  18, if any Party selected in Table 4 “Ending the Addendum when
                  the Approved Addendum changes”, will as a direct result of the
                  changes in the Approved Addendum have a substantial,
                  disproportionate and demonstrable increase in:
                  <ol type="a">
                    <li>
                      its direct costs of performing its obligations under the
                      Addendum; and/or
                    </li>
                    <li>its risk under the Addendum</li>
                  </ol>
                  and in either case it has first taken reasonable steps to
                  reduce those costs or risks so that it is not substantial and
                  disproportionate, then that Party may end this Addendum at the
                  end of a reasonable notice period, by providing written notice
                  for that period to the other Party before the start date of
                  the revised Approved Addendum.
                </li>
                <li>
                  The Parties do not need the consent of any third party to make
                  changes to this Addendum, but any changes must be made in
                  accordance with its terms.
                </li>
              </ol>
            </div>
            <div>
              <h1>Schedule 4</h1>
              <p>
                The parties agree that the EU SCCs shall apply to Restricted
                Transfers from the EEA. The EU SCCs shall be deemed entered into
                (and incorporated into this DPA by reference) and completed as
                follows:
              </p>
              <ol>
                <li>
                  Module One (Controller to Controller) shall apply where the
                  Company is processing Agency account data for its own
                  purposes.
                </li>
                <li>
                  Module Two (Controller to Processor) shall apply where the
                  Agency is a Controller of Customer Data and the Company is
                  processing Customer Data.
                </li>
                <li>
                  Module Three (Processor to Processor) shall apply where the
                  Company is a Processor of the Customer Data and the Company
                  uses a Sub-processor to process the Customer Data.
                </li>
                <li>
                  In Clause 7 of the EU SCCs, the optional docking clause will
                  not apply;
                </li>
                <li>
                  In Clause 9 of the EU SCCs Option 2 applies, and the time
                  period for giving notice of Sub-processor changes shall be 30
                  days;
                </li>
                <li>
                  In Clause 11 of the EU SCCs, the optional language shall not
                  apply;
                </li>
                <li>
                  In Clause 17 of the EU SCCs, Option 1 applies and the EU SCCs
                  shall be governed by Irish law;
                </li>
                <li>
                  In Clause 18(b) of the EU SCCs, disputes shall be resolved by
                  the courts of Ireland;
                </li>
                <li>
                  Annex I of the EU SCCs shall be deemed completed with the
                  information set out in Schedule 1 of this DPA;
                </li>
                <li>
                  Annex II of the EU SCCs shall be deemed completed with the
                  information set out in Schedule 2 of this DPA;
                </li>
              </ol>
              The Parties agree that the EU SCCs as amended above in clauses (i)
              to (x), shall be adjusted as set out below where the FDPA applies
              to any Restricted Transfer:
              <ol>
                <li>
                  The Swiss Federal Data Protection and Information Commissioner
                  (“FDPIC”) shall be the sole Supervisory Authority for
                  Restricted Transfers exclusively subject to the FDPA;
                </li>
                <li>
                  Restricted Transfers subject to both the FDPA and the EU GDPR,
                  shall be dealt with by the EU Supervisory Authority named in
                  Schedule 1 of this DPA;
                </li>
                <li>
                  The term 'member state' must not be interpreted in such a way
                  as to exclude data subjects in Switzerland from the
                  possibility of suing for their rights in their place of
                  habitual residence (Switzerland) in accordance with Clause 18I
                  of the EU SCCs;
                </li>
                <li>
                  Where Restricted Transfers are exclusively subject to the
                  FDPA, all references to the GDPR in the EU SCCs are to be
                  understood to be references to the FDPA;
                </li>
                <li>
                  Where Restricted Transfers are subject to both the FDPA and
                  the EU GDPR, all references to the GDPR in the EU SCCs are to
                  be understood to be references to the FDPA insofar as the
                  Restricted Transfers are subject to the FDPA;
                </li>
                <li>
                  The Swiss SCCs also protect the Personal Data of legal
                  entities until the entry into force of the revised FDPA.
                </li>
              </ol>
              The parties agree that the UK SCCs shall apply to Restricted
              Transfers from the UK and the UK SCCs shall be deemed entered into
              (and incorporated into this DPA by reference), completed as
              follows:
              <ol>
                <li>
                  Appendix 1 of the UK SCCs shall be deemed completed with the
                  information set out in Schedule 1 of this DPA; and
                </li>
                <li>
                  Appendix 2 of the UK SCCs shall be deemed completed with the
                  information set out in Schedule 2 of this DPA.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <site-footer></site-footer>
</template>
<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "DataProcessingAgreement",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Reseller Data Processing Agreement - Transpond",
        description:
          "Take a look at the Transpond Reseller Data Processing Agreement",
      },
    };
  },
};
</script>
