<script setup>
import gtmHelpers from "../functions/gtm.js";
</script>
<template>
  <template v-if="showBanner">
    <div class="cookie-message">
      <div class="cookie-message__content">
        <p>We use cookies as described in our <router-link to="/cookiepolicy" title="Cookie Policy" class="text-link">cookie policy</router-link>.</p>
      </div>
      <div class="cookie-message__button">
        <div class="btn btn--primary"
        v-on:click="handleUserConsent(true)"
        >
        Accept
        </div>
        <div class="btn btn--primary cookie_message_button2"
        v-on:click="handleUserConsent(false)"
        >
        Decline
        </div>
      </div>
    </div>
  </template>
</template>

<script>

export default {
  name: "CookieMessage",
  data() {
    return {
      showBanner: true,
    };
  },
  ///component has mounted
  created() {
    //see if they have a cookie
    let hasCookie = gtmHelpers.getConsentState();
    if (hasCookie) {
      this.showBanner = false;
    }
  },

  //functions
  methods: {

    handleUserConsent(consentGiven) {
      gtmHelpers.handleConsent(consentGiven); // Call the consent handler with user's choice
      this.showBanner = false;
    },

    // changeCurrency(newCurr) {
    //   this.currency = newCurr;
    //   this.getPricing();
    // },
  },
}

</script>