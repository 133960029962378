class trackingHelpers {
  static linkToApp(url) {
    if (!url) url = "";
    let trackingQs = ""

    let cookieFinal = "";
    const cookieValue = `; ${document.cookie}`;
    let cookieParts = cookieValue.split(`; mpz=`);
    if (cookieParts.length === 2) cookieFinal = cookieParts.pop().split(';').shift();

    if (cookieFinal) {
      if (url.indexOf("?") > -1) {
        trackingQs = "&mpztid=" + cookieFinal;
      }
      else {
        trackingQs = "?mpztid=" + cookieFinal;
      }
      url += trackingQs;
    }

    try {
      if (localStorage.getItem('ps_xid')) {
        if (url.indexOf("?") > -1) {
          url += "&ps_xid=" + localStorage.getItem('ps_xid');
        }
        else {
          url += "?ps_xid=" + localStorage.getItem('ps_xid');
        }
      }
    } catch (e) {
      console.log(e)
    }

    window.open('https://app.transpond.io/' + url, '_self')
  }

}

export default trackingHelpers;
