<template>
  <div class="toggle">
    <button v-on:click="onSwitch" class="toggle__btn">
      <span
        :class="[
          value === optionTwo ? 'toggle__switch--active' : '',
          'toggle__switch',
        ]"
      ></span>
      <div class="toggle__switch-text-container">
        <span class="toggle__switch-text">
          {{ optionOne }}
        </span>
        <span class="toggle__switch-text">
          {{ optionTwo }}
        </span>
      </div>
    </button>
  </div>
</template>
<script>
export default {
  name: "ToggleSwitch",
  props: ["optionOne", "optionTwo", "onSwitch", "value"],
};
</script>
