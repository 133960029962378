<template>
  <div class="mt-8 mb-10 row middle-xs justify-center">
    <div class="col-xs-12 grow-0 col-sm mb-6 sm:mb-0 text-center">
      <a
        v-if="buttonText"
        href="/pricing"
        class="btn btn--primary btn--mobile-full-width inline-block"
      >
        {{ buttonText }}
      </a>
    </div>
    <div
      v-if="ratingText && ratingText.trim()"
      class="col-xs-12 col-sm-8 mb-10 sm:mb-0"
    >
      <div class="row center-xs start-sm middle-xs">
        <div class="sm:mr-2 mb-2 sm:mb-0">
          <StarRating :rating="4.2"></StarRating>
        </div>
        <div class="col-xs-12 col-sm-6">{{ ratingText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "../StarRating";

export default {
  name: "PageHeaderRating",
  components: {
    StarRating,
  },
  props: {
    buttonText: {
      type: String,
      default: "Button Text",
    },
    ratingText: {
      type: String,
      default: "Rating Text",
    },
  },
};
</script>
