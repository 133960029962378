class gtmHelpers {
    static gtmInstance = null;

    static init(gtmInstance) {
        this.gtmInstance = gtmInstance;
    }

    static handleConsent(consentGiven) {
        if (this.gtmInstance) {
            this.gtmInstance.enabled = consentGiven;
            window.gtag('consent', 'update', {
                ad_storage: consentGiven ? 'granted' : 'denied',
                analytics_storage: consentGiven ? 'granted' : 'denied',
                ad_user_data: consentGiven ? 'granted' : 'denied',
                ad_personalization: consentGiven ? 'granted' : 'denied',
            });

            const d = new Date();
            d.setTime(d.getTime() + 36135 * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = `mpz-cookies-gtmok=${consentGiven ? 1 : 0}; ${expires};path=/`;

        } else {
            console.error("GTM instance not initialized.");
        }
        return;
    }

    static getConsentState = () => {
        let name = "mpz-cookies-gtmok=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        let finalVal = "";
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                finalVal = c.substring(name.length, c.length);
                break;
            }
        }
        let ok = finalVal === "1" || finalVal === 1;
        if (ok) {
            return true;
        } else {
            return false;
        }

    };
}

export default gtmHelpers;
