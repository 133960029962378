<template>
  <section
    :class="[
      'content-section content-section--dark pt-20 pb-20 mt-40'
    ]"
  >
    <div class="container-fluid">
      <div class="row between-xs middle-xs">
        <div class="col-xs-12 col-sm-6 xs:mb-5 sm:mb-0">
          <Image
            v-if="image"
            :file="image"
            class="content-section__image"
            alt=""
          ></Image>
        </div>

        <div class="col-xs-12 col-sm-5 xs:mb-5 sm:mb-0">
          <h3 v-if="title" class="mb-10">{{ title }}</h3>
          <ul v-if="steps" class="steps">
            <li v-for="(step,index) in steps" :key="index">
              <h5>{{ step.title }}</h5>
              <p>{{ step.content }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";

export default {
  name: "SectionStepsTwoColumnRight",
  components: { Image },
  props: {
    title: {
      type: String,
      default: "Title",
    },
    image: {
      type: String,
    },
    steps: {
      type: Array,
    }
  },
};
</script>