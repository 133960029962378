<template>
<div 
  class="image-slider__slider-image">
  <img
    v-bind:src="url"
    v-bind:alt="alt"
    v-bind:title="title"
    loading="lazy"
    decoding="async" />
</div>

</template>
<script>

export default {
  name: "ImageSliderImage",
  props: {
    url: {
      type: String
    },
    alt: {
      type: String
    },
    title: {
      type: String
    }
  }
}
</script>