<template>
  <section
    :class="[
      'content-section content-section--one-column',
      theme == 'dark'
        ? 'content-section--dark pt-20 sm:pt-40'
        : 'mt-20 sm:mt-40',
      overlay
        ? 'content-section--overlay pb-20 sm:pb-0'
        : theme == 'dark'
        ? 'pb-20 sm:pb-40'
        : 'mb-20 sm:mb-40',
    ]"
  >
    <div class="container-fluid">
      <div class="column">
        <div class="first-sm col-xs-12 col-sm-5 mb-6">
          <h4>
            {{ title }}
          </h4>
        </div>
        <template v-if="content">
          <div class="col-xs-12 col-sm-8 mb-10">
            <p class="p--lg">{{ content }}</p>

            <router-link
              v-if="buttonLink"
              :to="{ name: buttonLink }"
              title="{{buttonText}}"
              class="btn btn--secondary mb-10 mt-10"
              >{{ buttonText }}
            </router-link>
          </div>
        </template>

        <div class="col-xs-12 first-xs last-sm mb-10 sm:mb-0">
          <Image
            :file="image"
            :class="[
              { 'content-section__image--rounded': rounded },
              'content-section__image',
            ]"
            alt="Test"
            width="1240"
            :height="height"
          ></Image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";

export default {
  name: "SectionOneColumnLeft",
  components: { Image },
  props: {
    theme: {
      type: String,
    },
    title: {
      type: String,
      default: "Title",
    },
    content: {
      type: String,
      default: "Section Content Goes Here",
    },
    buttonText: {
      type: String,
      default: "Button Text",
    },
    buttonLink: {
      type: String,
      default: "",
    },
    image: {
      type: String,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
      required: false,
    },
    height: {
      type: String,
    },
  },
};
</script>
