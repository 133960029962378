<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <div>
    <navigation></navigation>

    <PageHeader
      v-if="pageLoaded === true"
      title="Ready-made email templates"
      subtitle-text="Beautifully designed templates to help you on your way"
    ></PageHeader>

    <section class="section--white" v-if="pageLoaded === true">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-12">
            <div class="tabs mt-7 mb-7">
              <label for="tabs__mobile-toggle">
                <input
                  id="tabs__mobile-toggle"
                  class="tabs__mobile-toggle"
                  type="checkbox"
                />
                <ul class="tabs__list">
                  <li class="tabs__list-item tabs__list-item--mobile">
                    Filter Templates
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.29289 5.29289C3.68342 4.90237 4.31658 4.90237 4.70711 5.29289L8 8.58579L11.2929 5.29289C11.6834 4.90237 12.3166 4.90237 12.7071 5.29289C13.0976 5.68342 13.0976 6.31658 12.7071 6.70711L8.70711 10.7071C8.31658 11.0976 7.68342 11.0976 7.29289 10.7071L3.29289 6.70711C2.90237 6.31658 2.90237 5.68342 3.29289 5.29289Z"
                        fill="black"
                      />
                    </svg>
                  </li>
                  <li
                    class="tabs__list-item"
                    v-bind:class="{
                      'tabs__list-item--active': 0 == currentCatId,
                    }"
                  >
                    <a v-on:click="changeCat(0)">All</a>
                  </li>
                  <li
                    class="tabs__list-item"
                    v-for="(item, index) in themeData"
                    v-bind:key="index"
                    v-bind:class="{
                      'tabs__list-item--active': item.id == currentCatId,
                    }"
                  >
                    <a v-on:click="changeCat(item.id)">{{ item.name }}</a>
                  </li>
                </ul>
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 mb-30">
            <div class="email-templates__template-gallery">
              <template
                v-for="(item, index) in templateData"
                v-bind:key="index"
              >
                <div class="col-xs-12 col-sm-6 col-md-4 mb-3">
                  <div class="email-templates__template-gallery-item">
                    <img
                      :src="
                        'https://cdn1.ourmailsender.com/siteContent/themes/theme' +
                        item.id +
                        '.jpg'
                      "
                      width="400"
                      height="300"
                      style="aspect-ratio: 400 / 300"
                      v-bind:title="item.name + ' Email Template'"
                      v-bind:alt="
                        'Preview for ' + item.name + ' Email Template'
                      "
                    />
                    <div
                      class="email-templates__template-gallery-item-hover email-templates__template-gallery-item-hover--dark"
                    >
                      <router-link
                        class="btn btn--primary"
                        :to="'/emailtemplate/' + item.id"
                        >Preview template</router-link
                      >
                      <router-link
                        to="Pricing"
                        class="text-link text-link--light"
                      >
                        or get started
                      </router-link>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>

    <site-footer></site-footer>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import SiteFooter from "@/components/SiteFooter";
import axios from "axios";

export default {
  name: "GalleryPage",
  components: {
    MetaInfo,
    PageHeader,
    Navigation,
    SiteFooter,
  },

  data() {
    return {
      templateData: [],
      rawTemplateData: [],
      themeData: {},
      pageLoaded: false,
      currentCatId: 0,
      meta: {
        title: `Email Template Gallery - Transpond`,
        description:
          "Beautiful, free email templates to give you inspiration for your next email campaign",
      },
    };
  },
  created() {
    axios.get("https://api.transpond.io/api/templates").then((response) => {
      this.templateData = response.data.Templates;
      this.rawTemplateData = response.data.Templates;
      this.templateData.forEach((theT) => {
        theT.ThemeGroups.forEach((theTh) => {
          this.themeData[theTh.id] = { id: theTh.id, name: theTh.name };
        });
      });
      this.pageLoaded = true;
    });
  },
  methods: {
    changeCat(catId) {
      this.currentCatId = catId;
      if (catId == 0) {
        this.templateData = this.rawTemplateData;
        return;
      }
      this.templateData = this.rawTemplateData.filter((theTemp) =>
        theTemp.ThemeGroups.find((theThem) => {
          return theThem.id == catId;
        })
      );
    },
  },
};
</script>
