<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Cookie Policy"> </PageHeader>

    <div class="container-fluid">
      <div class="row mb-30">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <p>
              MPZMail Limited (“us”, “we”, or “our”) use cookies on Transpond.io
              (the “Site”). By using the Site, you consent to the use of
              cookies. Our Cookie Policy explains what cookies are, how we use
              cookies, how third parties we may partner with may use cookies on
              the Site, your choices regarding cookies and further information
              about cookies.
            </p>
            <h3>What are cookies</h3>
            <p>
              Cookies are small text files that are placed on your computer by
              websites that you visit. They are widely used in order to make
              websites work, or work more efficiently, as well as to provide
              information to the owners of the Site.
            </p>
            <p>
              Cookies can be “persistent” or “session” cookies. We use
              persistent cookies and session cookies on this Site.
            </p>

            <h3>Persistent Cookies</h3>
            <p>
              A persistent cookie is stored on a user's device in-between
              browser sessions which allows the preferences or actions of a user
              across the Site (or in some cases across different websites) to be
              remembered. We use persistent cookies to save your login
              information for future logins to the Site or Services.
            </p>

            <h3>Session Cookies</h3>
            <p>
              A session cookie allows the Site to link your actions during a
              browser session. Unlike persistent cookies, session cookies are
              deleted from your computer when you log off from the Site and then
              close your browser. We use session cookies to enable certain
              features of the Site or Services, to better understand how you
              interact with the Site or Services and to monitor aggregate usage
              by users and web traffic routing on the Site.
            </p>

            <h3>How do we use cookies</h3>
            <p>
              The table below explains the cookies we use and why we use each of
              them.
            </p>

            <div
              class="terms-table"
              style="margin-top: 20px; margin-bottom: 20px"
            >
              <table cellPadding="10" cellSpacing="0">
                <thead>
                  <th valign="top">Cookie</th>
                  <th valign="top">Type</th>
                  <th valign="top">Purpose</th>
                  <th valign="top">Duration</th>
                </thead>
                <tbody>
                  <tr>
                    <th valign="top">Google Analytics</th>
                    <td valign="top">Tracking cookies</td>
                    <td valign="top">
                      These cookies are used to collect information about how
                      visitors use our Site. We use the information to compile
                      reports and to help us improve the Site. The cookies
                      collect information in an anonymous form, including the
                      number of visitors to the Site, where visitors have come
                      to the Site from and the pages they visited. If you do not
                      allow these cookies we will not be able to include your
                      visit in our statistics. You can read the full Google
                      Analytics privacy policy at:
                      http://www.google.com/policies/privacy/.
                    </td>
                    <td valign="top">2 Years</td>
                  </tr>

                  <tr>
                    <th valign="top">Transpond Tracking</th>
                    <td valign="top">Tracking Cookies</td>
                    <td valign="top">
                      These cookies are used to collect information about how
                      visitors use our Site. We use the information to compile
                      reports and to help us improve the Site. The cookies
                      collect information in an anonymous form, unless a
                      customer has visited the site after following a link in a
                      marketing campaign, in which case we may also link the
                      cookie to a contact profile. We log the number of visitors
                      to the Site, where visitors have come to the Site from,
                      the pages they visited, the geolocation of the visitor,
                      the web browser they use.
                    </td>
                    <td valign="top">No Expiry</td>
                  </tr>

                  <tr>
                    <th valign="top">YouTube</th>
                    <td valign="top">Embedded cookies</td>
                    <td valign="top">
                      We embed videos from our official YouTube channel using
                      YouTube's privacy-enhanced mode. This mode may set cookies
                      on your computer once you click on the YouTube video
                      player, but YouTube will not store personally-identifiable
                      cookie information for playbacks of embedded videos using
                      the privacy-enhanced mode.
                    </td>
                    <td valign="top">6 Months</td>
                  </tr>
                  <tr>
                    <th valign="top">Transpond Session Cookies</th>
                    <td valign="top">Application Cookies and Local Storage</td>
                    <td valign="top">
                      We use a number of cookies and local storage variables to
                      store login and account data, along with your preferences
                      and settings for use on the Transpond Control Panel. These
                      cookies and session storage are essential for the usage of
                      the Control Panel and you will be unable to use the
                      platform without this cookie.
                    </td>
                    <td valign="top">Session</td>
                  </tr>
                  <tr>
                    <th valign="top">Stripe</th>
                    <td valign="top">Payment Cookies</td>
                    <td valign="top">
                      <p>
                        We use Stripe as a payment gateway to handle payments
                        made to and from the Transpond platform. Stripe uses
                        cookies to ensure that the payment system works
                        properly, detect and prevent fraud, and understand how
                        people interact with Stripe. There are some cookies that
                        are essential for Stripe to function properly. These
                        Necessary Cookies provide secure access to the site and
                        enable page navigation. Other categories of cookies
                        include Advertising Cookies, Analytics Cookies, and
                        Preference Cookies.
                      </p>
                      <p>
                        You can find more information in the Stripe Cookies
                        Policy, including how to opt-out or manage your cookie
                        preferences.
                        <a
                          href="https://stripe.com/cookies-policy/legal"
                          target="_blank"
                          rel="noopener noreferrer"
                          >https://stripe.com/cookies-policy/legal</a
                        >
                      </p>
                    </td>
                    <td valign="top">1 Year</td>
                  </tr>
                  <tr>
                    <th valign="top">MapBox</th>
                    <td valign="top">Mapping</td>
                    <td valign="top">
                      <p>
                        We use MapBox to display data on maps from within the
                        platform. Mapbox uses cookies to improve the website
                        experience for visitors.
                      </p>
                      <p>
                        Mapbox uses two types of cookies, session cookies and
                        persistent cookies. Session cookies have a temporary
                        "life" and are set to only remain in the cookie file of
                        your browser until you leave the website. Mapbox uses
                        these on an anonymous aggregate basis to analyze the
                        total number of visitors to their website and app, and
                        which pages they visited.
                      </p>
                      <p>
                        Persistent cookies remain in the cookie file of your
                        browser for much longer (though how long will depend on
                        the lifetime of the specific cookie). Mapbox uses them
                        so that they can remember your computer when it is used
                        to return to help customize your website experience, for
                        example so you do not have to log in again.
                      </p>
                      <p>
                        For more information please check the Mapbox cookie
                        policy here
                        <a
                          href="https://www.mapbox.com/legal/cookies"
                          target="_blank"
                          rel="noopener noreferrer"
                          >https://www.mapbox.com/legal/cookies</a
                        >
                      </p>
                    </td>
                    <td valign="top">3 months</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3>Your choices regarding cookies</h3>
            <p>
              You can set up your browser options, to stop your computer
              accepting cookies or to prompt you before accepting a cookie from
              the websites you visit. If you do not accept cookies, however, you
              may not be able to use the whole of the Site or all functionality
              of the Services.
            </p>
            <p>
              To find out more about cookies, including how to see what cookies
              have been set and how to manage and delete them, visit
              <a
                href="https://www.aboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
                >https://www.aboutcookies.org</a
              >
              or
              <a
                href="https://www.allaboutcookies.org"
                target="_blank"
                rel="noopener noreferrer"
                >www.allaboutcookies.org</a
              >. To opt out of being tracked by Google Analytics across all
              websites visit
              <a
                href="http://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noopener noreferrer"
                >http://tools.google.com/dlpage/gaoptout</a
              >.
            </p>

            <h3>Changes to our Cookie Policy</h3>
            <p>
              Any changes we may make to our Cookie Policy in the future will be
              posted on this page and, where appropriate, notified to you by
              email. Please check back frequently to see any updates or changes
              to our Cookie Policy. This Cookie Policy was last updated on
              December 14, 2021 and replaces any other Cookie Policy previously
              applicable from this date.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "CookiePolicy",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Cookie Policy - Transpond",
        description: "Take a look at the Transpond Cookie Policy",
      },
    };
  },
};
</script>
