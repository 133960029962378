<template>
  <MetaInfo
    :title="meta.title"
    :description="meta.description"
    noindex="noindex"
  ></MetaInfo>
  <navigation></navigation>
  <section class="white-bg">
    <div class="container-fluid pt-20 pb-40">
      <div class="row">
        <div class="col-xs-12 col-md-4 mb-20 md:mb-0">
          <router-link
            to="/emailtemplates"
            class="text-link text-link--arrow-before mb-10"
            >all templates</router-link
          >
          <div class="row">
            <div class="col-xs-12">
              <h1 class="h4">{{ ourTemplate.name }}</h1>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-12">
              <p class="mb-6 p--lg">
                Create your account today and use this template in your next
                campaign.
              </p>
              <a class="btn btn--primary btn--sm" href="/pricing">
                Get started
              </a>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-7 col-md-offset-1">
          <div class="email-templates__example-container">
            <iframe
              :src="templateIframe"
              class="email-templates__example"
              frameborder="0"
              width="540"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import axios from "axios";

export default {
  name: "GalleryDetail",
  components: { MetaInfo, Navigation, SiteFooter },
  data() {
    return {
      ourTemplate: [],
      dataLoaded: false,
      templateIframe: "",
      meta: {
        title: "",
        description: "",
      },
    };
  },

  created() {
    axios
      .get(
        "https://api.transpond.io/api/templates/" +
          this.$route.params.templateId
      )
      .then((response) => {
        this.ourTemplate = response.data;
        this.templateIframe = `https://api.transpond.io/api/preview?key=6:0:0:0:0&tid=${response.data.id}&type=theme&showClicks=false`;
        this.meta.title = this.ourTemplate.name;
        this.meta.description = `Email template for ${this.ourTemplate.name}`;
      });
  },
  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
