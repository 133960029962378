<template>
  <PPCLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeader
      title="The best Mailchimp alternative"
      subtitle-text="Transpond by Capsule is an easy to use Mailchimp alternative that you can get started with quickly. It offers excellent value, and there are no hidden costs. Make sure you’re getting the most from your email marketing."
      class="mb-0 sm:mb-0 no-submenu"
    >
      <template v-slot:image>
        <PageHeaderImage image="feature-hero"></PageHeaderImage>
      </template>

      <div class="row mt-10">
        <div class="col-xs-12">
          <div class="btn-area">
            <router-link
              to="Pricing"
              title="Try free"
              class="btn btn--primary"
            >
              Try free
            </router-link>
            <div class="btn-area__checks">
              <div class="btn-area__check">
                <img :src="require('@/assets/images/check.svg')"  />
                <div class="btn-area__check-details">
                  <p class="btn-area__title">No hidden costs</p>
                  <p class="btn-area__detail p--sm mt-0">All covered in your monthly package</p>
                </div>
              </div>

              <div class="btn-area__check">
                <img :src="require('@/assets/images/check.svg')"  />
                <div class="btn-area__check-details">
                  <p class="btn-area__title">Excellent Customer Support team</p>
                  <p class="btn-area__detail p--sm mt-0">Replies to enquiries within 24 hours, Monday to Friday</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageHeader>

    <LogoCloud
      title="Feature-rich integrations including 2-way automations for:"
      :logos="[
        {
          title: 'Capsule CRM',
          image: 'integration-capsule',
        },
        {
          title: 'Stripe',
          image: 'integration-stripe',
        },
        {
          title: 'Google Sheets',
          image: 'integration-sheets',
        },
        {
          title: 'Pipedrive',
          image: 'integration-pipedrive',
        },
        {
          title: 'Microsoft Dynamics 365',
          image: 'integration-dynamics',
        },
      ]"
    ></LogoCloud>

    <SectionTwoColumnRight
      theme="dark"
      content="Transpond’s intuitive drag and drop email designer is perfect for creating campaigns that get noticed and help your business grow. Start from a template or create something from scratch. Built in list cleaning helps you proactively protect your sender reputation."
      title="Campaign Builder"
      image="campaign-builder"
    ></SectionTwoColumnRight>

    <SectionCardsTwoColumn
      titleLeft="Transactional emails"
      contentLeft="Keep contacts informed with perfectly timed emails. By keeping them informed and engaged, you can keep customers happy and drive real loyalty."
      imageLeft="transactional-email-feature-orange"
      titleRight="Website tracking"
      contentRight="Discover the journeys your contacts take after they’ve clicked on links in your communications, and set up actions to ensure you’re making the most of every marketing opportunity."
      imageRight="website-tracking-feature"
    ></SectionCardsTwoColumn>

    <SectionOneColumnCenter
      id="automations"
      title="Automations"
      content="Access a range of powerful marketing automations, so you can focus on continuing to grow your business. It’s like having a new member of your team."
      theme="dark"
      image="automation-examples"
      :smallImg="true"
      class="mt-40"
    ></SectionOneColumnCenter>

    <SectionCardsTwoColumn
      titleLeft="Analytics"
      contentLeft="See how contacts engage with your campaigns and how you can maximize every opportunity with powerful analytics."
      imageLeft="analytics-signup-forms-feature"
      titleRight="Signup forms"
      contentRight="Attract new contacts to market to by ensuring they always have a way to pass their details to you."
      imageRight="signup-forms-feature"
    ></SectionCardsTwoColumn>

  </PPCLayout>
</template>

<script>
import PPCLayout from "@/layouts/PPCLayout.vue";
import PageHeader from "@/components/Header/PageHeader";
import PageHeaderImage from "@/components/Header/PageHeaderImage";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionCardsTwoColumn from "@/components/ContentSections/SectionCardsTwoColumn";
import SectionOneColumnCenter from "@/components/ContentSections/SectionOneColumnCenter";
import LogoCloud from "@/components/LogoCloud";

export default {
  name: "MailchimpAlternative",
  components: {
    PPCLayout, PageHeader, PageHeaderImage, SectionTwoColumnRight, SectionCardsTwoColumn, SectionOneColumnCenter, LogoCloud
  },
  data() {
    return {
      meta: {
        title: "Mailchimp Alternative - Transpond",
        description:
          "Transpond by Capsule is an easy to use Mailchimp alternative that you can get started with quickly. It offers excellent value, and there are no hidden costs. Make sure you’re getting the most from your email marketing.",
      },
    };
  },
  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>