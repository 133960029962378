<template>
  <section
    :class="[
      'content-section',
      theme == 'dark'
        ? 'content-section--dark pt-20 sm:pt-40'
        : 'mt-20 sm:mt-40'
    ]"
  >
    <div class="container-fluid">
      <div class="row between-xs">
        <div class="col-xs-12 col-sm-6 xs:mb-5 sm:mb-0">
          <div class="card sm:p-16 xs:p-10">
            <Image
              v-if="imageLeft"
              :file="imageLeft"
              class="content-section__image mb-16"
              :alt="titleLeft"
            ></Image>
            <h5 v-if="titleLeft" class="mb-2">{{ titleLeft }}</h5>
            <p v-if="contentLeft">{{ contentLeft }}</p>
            <Image
              v-if="logoLeft"
              :file="logoLeft"
              class="card__logo mt-8"
              width="140"
              height="32"
              alt=""
            ></Image>
          </div>
        </div>  

        <div class="col-xs-12 col-sm-6">
          <div class="card sm:p-16 xs:p-10">
            <Image
              v-if="imageRight"
              :file="imageRight"
              class="content-section__image mb-16"
              alt=""
            ></Image>
            <h5 v-if="titleRight" class="mb-2">{{ titleRight }}</h5>
            <p v-if="contentRight">{{ contentRight }}</p>
            <Image
              v-if="logoRight"
              :file="logoRight"
              class="card__logo mt-8"
              width="140"
              height="32"
              alt=""
            ></Image>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";

export default {
  name: "SectionCardsTwoColumn",
  components: { Image },
  props: {
    theme: {
      type: String,
    },
    titleLeft: {
      type: String,
      default: "Heading Goes Here",
    },
    contentLeft: {
      type: String,
      default: "Section Content Goes Here",
    },
    titleRight: {
      type: String,
      default: "Heading Goes Here",
    },
    contentRight: {
      type: String,
      default: "Section Content Goes Here",
    },
    imageLeft: {
      type: String,
    },
    imageRight: {
      type: String,
    },
    logoLeft: {
      type: String,
    },
    logoRight: {
      type: String,
    }
  },
};
</script>
