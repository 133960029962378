class textHelpers {
  static stringToSlug(str) {
    if (str && str.length > 0) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    } else {
      return "";
    }
  }

  static slugToString(str) {
    if (str && str.length > 0) {
      str = str.replace(/-/g, " ");
      return str;
    } else {
      return "";
    }
  }

  static cleanHtml(str) {
    if (str && str.length > 0) {
      var strippedString = str.replaceAll("<p>&nbsp;</p>", "");
      strippedString = strippedString.replaceAll(
        "<p><strong>&nbsp;</strong></p>",
        ""
      );
      return strippedString;
    } else {
      return "";
    }
  }
}

module.exports = textHelpers;
