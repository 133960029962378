<template>
  <nav>
    <div class="container-fluid">
      <div class="row pt-5 pb-5 between-xs middle-xs">
        <div class="col">
          <router-link
            to="/"
            title="Home"
            class="text-link text-link--arrow-before"
            >Transpond Home</router-link
          >
        </div>
        <div class="col-xs hidden-xs hidden-xvs hidden-vs">
          <div class="row end-xs">
            <a
              class="text-link text-link--bare mr-4"
              v-on:click="TrackingHelpers.linkToApp('')"
              title="Log in"
              >Log in</a
            >
      
              <button v-on:click="TrackingHelpers.linkToApp('register?welcome=true')" class="btn btn--primary btn--sm" title="Sign Up">
                Create Transpond account
              </button>
        
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>

import TrackingHelpers from "@/functions/tracking";


export default {
  setup()
  {
    return { TrackingHelpers }
  }
}
</script>
