<template>
  <div class="pricing-slider">
    <input
      v-on:input="handleChange"
      class="pricing-slider__input"
      type="range"
      :min="min"
      :value="0"
      :max="max"
      step="1"
    />

    <span id="sliderCounter" class="counter"
      >{{
        count >= 200001 ? `200k+` : `${(count.toLocaleString())}`
      }}
    </span>
  </div>
</template>

<script>
export default {
  name: "SliderSelector",
  props: {
    step: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
    },
    counter: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getClosestOption(value) {
      return Math.round(value / this.step) * this.step;
    },
    getPercentage(value, min, max) {
      return ((value - min) * 100) / (max - min);
    },
    calcSliderMargin(percent) {
      const width = 30;
      return -(width * (percent - 50)) / 50;
    },
    calcTooltipMargin(percent) {
      const unit = 16;
      return (1 + percent / 50) * unit;
    },
    handleChange(event) {
      const closest = this.getClosestOption(event.target.value);
      const percent = this.getPercentage(
        event.target.value,
        this.min,
        this.max
      );
      const track = document.querySelector('input[type="range"]');
      const counter = document.getElementById("sliderCounter");
      track.style.background = `linear-gradient(90deg, rgba(0, 0, 0) calc(${percent}% + ${this.calcSliderMargin(
        percent
      )}px), rgba(223,219,217,1) calc(${percent}% + ${this.calcSliderMargin(
        percent
      )}px)`;
      counter.style.left = `calc(${percent}% - ${this.calcTooltipMargin(
        percent
      )}px)`;
      this.$emit("setSubscriberCount", closest);
    },
  },
};
</script>
