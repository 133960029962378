<template>
  <ul class="sidebar-menu">
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 1 ? 'sidebar-menu__item--active' : '']">
      <a href="/Privacy">Privacy Policy</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 2 ? 'sidebar-menu__item--active' : '']">
      <a href="/Terms">Customer Terms</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 14 ? 'sidebar-menu__item--active' : '']">
      <a href="/acceptableuse">Acceptable Use</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 3 ? 'sidebar-menu__item--active' : '']">
      <a href="/Dpa">Data Processing Agreement</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 4 ? 'sidebar-menu__item--active' : '']">
      <a href="/subprocessors">Sub-processors</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 5 ? 'sidebar-menu__item--active' : '']">
      <a href="/dpa_schedule1">Schedule 1</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 6 ? 'sidebar-menu__item--active' : '']">
      <a href="/dpa_schedule2">Schedule 2</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 17 ? 'sidebar-menu__item--active' : '']">
      <a href="/dpa_schedule3">Schedule 3</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 7 ? 'sidebar-menu__item--active' : '']">
      <a href="/dpa_restrictedtransfers">Restricted Transfers</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 13 ? 'sidebar-menu__item--active' : '']">
      <a href="/dpa_cancellation">Cancellation</a>
    </li>

    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 16 ? 'sidebar-menu__item--active' : '']">
      <a href="/reseller_agreement">Reseller Agreement</a>
    </li>

    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 18 ? 'sidebar-menu__item--active' : '']">
      <a href="/affiliate_terms">Affiliate Program Terms</a>
    </li>

    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 15 ? 'sidebar-menu__item--active' : '']">
      <a href="/reseller_dpa">Reseller DPA</a>
    </li>

    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 12 ? 'sidebar-menu__item--active' : '']">
      <a href="/gdprcontactlocations">GDPR Contact Locations</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 10 ? 'sidebar-menu__item--active' : '']">
      <a href="/cookiepolicy">Cookie Policy</a>
    </li>
    <li class="sidebar-menu__item"
      v-bind:class="[activeTab == 11 ? 'sidebar-menu__item--active' : '']">
      <a href="/thirdparties">Third Parties</a>
    </li>
  </ul>
</template>


<script>
export default {
  data() {
    return {
      activeTab: 0,
    };
  },
  created() {
    let location = window.location.pathname.toLowerCase().replace(/\//, "");
    switch (location) {
      case "terms": {
        this.activeTab = 2;
        break;
      }
      case "privacy": {
        this.activeTab = 1;
        break;
      }
      case "dpa": {
        this.activeTab = 3;
        break;
      }
      case "dpa_restrictedtransfers": {
        this.activeTab = 7;
        break;
      }
      case "dpa_schedule1": {
        this.activeTab = 5;
        break;
      }
      case "dpa_schedule2": {
        this.activeTab = 6;
        break;
      }
      case "dpa_schedule3": {
        this.activeTab = 17;
        break;
      }
      case "dpa_cancellation": {
        this.activeTab = 13;
        break;
      }
      case "subprocessors": {
        this.activeTab = 4;
        break;
      }
      case "gdprcontactlocations": {
        this.activeTab = 12;
        break;
      }
      case "cookiepolicy": {
        this.activeTab = 10;
        break;
      }
      case "acceptableuse": {
        this.activeTab = 14;
        break;
      }
      case "thirdparties": {
        this.activeTab = 11;
        break;
      }
      case "reseller_dpa": {
        this.activeTab = 15;
        break;
      }
      case "reseller_agreement": {
        this.activeTab = 16;
        break;
      }
      case "affiliate_terms": {
        this.activeTab = 18;
        break;
      }
    }
  },
  methods: {
  }
};
</script>
