<template>
  <section
    :class="[
      'content-section',
      theme == 'dark' ? 'content-section--dark pt-20 mt-40' : 'mt-20',
      overlay
        ? 'content-section--overlay pb-20 sm:pb-0'
        : theme == 'dark'
        ? 'pb-20'
        : 'mb-20',
    ]"
  >
    <div class="container-fluid">
      <div class="row between-xs middle-xs">
        <div class="first-sm col-xs-12 col-md-5">
          <span v-if="kicker" class="heading-kicker mb-2">{{ kicker }}</span>
          <h4 class="mb-6">
            {{ title }}
          </h4>

          <div v-if="accordion" class="accordion">
            <AccordionItem
              v-for="item in accordion"
              v-bind:key="item.name"
              class="accordion__item--white"
              :active="item.name === selected"
              @setSelected="() => setSelected(item)"
            >
              {{ item.name }}
              <template v-slot:content>
                {{ item.content }}
              </template>
            </AccordionItem>
          </div>
        </div>
        <div class="first-xs col-xs-12 col-md-6 mb-10 sm:mb-0">
          <Image
            :file="image"
            class="content-section__image"
            alt="Test"
            width="610"
            height="456"
          ></Image>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";
import AccordionItem from "../Accordion/AccordionItem";

export default {
  name: "SectionTwoColumnLeft",
  components: { Image, AccordionItem },
  props: {
    theme: {
      type: String,
    },
    title: {
      type: String,
      default: "Title",
    },
    kicker: {
      type: String,
    },
    accordion: {
      type: Array,
    },
    image: {
      type: String,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    selectedItem: {
      type: String,
    },
  },
  data() {
    return {
      selected: this.selectedItem,
    };
  },
  methods: {
    setSelected(item) {
      this.selected = item.name;
    },
  },
};
</script>
