<template>
  <MetaInfo :title="pageTitle" :description="pageDescription"></MetaInfo>

  <div>
    <Navigation></Navigation>
    <slot />
    <footerCTA class="mt-40"></footerCTA>
    <PPCFooter></PPCFooter>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/NavigationPPC";
import FooterCTA from "@/components/FooterCTA";
import PPCFooter from "@/components/PPCFooter";

export default {
  name: "DefaultLayout",

  components: {
    MetaInfo,
    Navigation,
    FooterCTA,
    PPCFooter,
  },

  props: {
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
};
</script>