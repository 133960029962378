<template>
  <ul class="tabs__list tabs__list--underline mb-10">
    <li 
      v-for="title in tabTitles" 
      :key="title" 
      class="tabs__list-item"
      :class="{'tabs__list-item--active': title == selectedTitle }"
      @click="selectedTitle = title">
      {{ title }}
    </li>
  </ul>
  <slot></slot>
</template>

<script>
import { computed } from "vue";

export default {
  name: "ContentTabs",
  data() {
    return {
      tabTitles: [],
      selectedTitle: ""
    }
  },
  created() {
    this.tabTitles = this.$slots.default().map((tab) => tab.props.title);
    this.selectedTitle = this.tabTitles[0];
  },
  provide() {
    return {
        selectedTab: computed(() => this.selectedTitle)
      }
    }
  }
</script>