<template>
  <section
    class="mt-30 mb-20"
  >
    <div class="container-fluid">
      <div class="row center-xs">
        <div v-if="title" class="col-xs-12">
          <h4 class="h4--sm mb-2">{{ title }}</h4>
        </div>

        <div class="logo-cloud col-xs-12 pt-10 pb-10">
          <img 
            v-for="logo in logos"
            v-bind:key="logo.title"
            :src="require('@/assets/images/'+ logo.image +'.svg')" 
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>


export default {
  name: "LogoCloud",
  props: {
    title: {
      type: String,
    },
    logos: {
      type: Array,
      required: true,
    },
  },
}
</script>