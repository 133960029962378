<template>
  <template v-for='index in numberRating' :key='index'>
    <StarFull class="mr-2"></StarFull>
  </template>
  <StarHalf v-if="hasDecimal"></StarHalf>
</template>
<script>
  import StarFull from "@/components/Svgs/StarFull";
  import StarHalf from "@/components/Svgs/StarHalf";

  export default {
    name: "StarRating",
    components: {
      StarFull,
      StarHalf
    },
    props: {
      rating: {
        type: Number,
        required: true
      }
    },
    computed: {
      numberRating() {
        if(Number.isInteger(this.rating)){
          return this.rating
        }else {
          return parseInt(this.rating);
        }
      },
      hasDecimal() {
        return !Number.isInteger(this.rating);
      }
    }
  }
</script>