<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Deliver the best customer experience"
      subtitleText="Conversations unifies live chat and email so that you can respond to incoming enquiries better and faster than ever before."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
      theme="pink"
      image="conversations-hero"
      title="How can Conversations help your business?"
      :steps="[
        {
          title: 'Customer Support',
          content:
            'Get your customers the help they need, immediately. Transpond provides a single inbox for managing all support queries across email and web chat.',
        },
        {
          title: 'Sales',
          content:
            'Responding to inquiries within five minutes increases your chance of conversion by 98%. Transpond lets you chat with prospects while they are on your website, improving lead velocity.',
        },
      ]"
    ></SectionHero>

    <SectionTwoColumnRight
    kicker="Live Chat"
    title="Level up your website experience with live chat"
    content="Connect visitors with the right team instantly. For instance, support for inquiries, and sales for leads. Customize your live chat to match your brand and greet customers and prospects with targeted messages. Use AI to engage fast and deliver the answers they need, now."
    image="live-chat-feature"
  ></SectionTwoColumnRight>

    <SectionCardsTwoColumn
    titleLeft="AI Assistant: Your sales and support rep"
    contentLeft="Transpond’s AI Assistant gets to know your company and product inside out, giving your customers accurate answers to their questions. It’s your round-the-clock support agent, delivering exceptional customer service even after hours. Let your team focus on high-impact tasks while AI Assistant handles the rest."
    imageLeft="conversation-ai-chat"
    titleRight="One inbox. One single source of truth."
    contentRight="Get a single view of your queries from live chat and email. Direct conversations to the right team with multiple inboxes, and help your team respond fast and consistently with a library of Saved Replies."
    imageRight="conversation-unified-inbox"
  ></SectionCardsTwoColumn>

  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionCardsTwoColumn from "@/components/ContentSections/SectionCardsTwoColumn";


export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnRight,
    SectionCardsTwoColumn,
  },
  data() {
    return {
      meta: {
        title: "Campaign Builder - Transpond",
        description:
          "Create stunning emails for real engagement. Transpond’s intuitive drag and drop email designer is perfect for creating campaigns that get noticed and help your business grow. Start from a template or create something from scratch.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
