<template>

  <div class="blog__social-share">
    <a :href="twitterShareUrl" class="blog__social-share-icon-wrapper">
      <twitterLogo
      class="blog__social-share-icon"
      ></twitterLogo>
    </a>
    <a :href="facebookShareUrl" class="blog__social-share-icon-wrapper">
      <facebookLogo
      class="blog__social-share-icon"
      ></facebookLogo>
    </a>
    <a :href="linkedinShareUrl" class="blog__social-share-icon-wrapper">
      <linkedinLogo
      class="blog__social-share-icon"
      ></linkedinLogo>
    </a>
  </div>

</template>

<script>

import twitterLogo from "../Svgs/TwitterIcon";
import facebookLogo from "../Svgs/FacebookIcon";
import linkedinLogo from "../Svgs/LinkedinIcon";

export default {
  name: "blogSocialShare",
  components: {
    twitterLogo,
    facebookLogo,
    linkedinLogo
  },
  props: {
    currentPageUrl: {
      type: String,
      default: "",
    },
    pageTitle: {
      type: String,
      default: "",
    },
    postSummary: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      twitterUrl: "https://twitter.com/intent/tweet?text=#shareText#%20via%20%40Transpond.io%20#socialUrl#",
      facebookUrl: "https://www.facebook.com/sharer/sharer.php?u=#socialUrl#",
      linkedinUrl: "https://www.linkedin.com/shareArticle?mini=true&url=#socialUrl#&title=#socialTitle#&summary=#shareText#&source=LinkedIn",
      twitterShareUrl: "",
      facebookShareUrl: "",
      linkedinShareUrl: ""
    }
  },
  mounted() {
    this.twitterShareUrl = this.replaceUrlTokens(this.twitterUrl);
    this.facebookShareUrl = this.replaceUrlTokens(this.facebookUrl);
    this.linkedinShareUrl = this.replaceUrlTokens(this.linkedinUrl);
  },
  methods: {
    replaceUrlTokens(url) {
      var tempLink = url;
      tempLink = tempLink.replace("#shareText#", this.postSummary);
      tempLink = tempLink.replace("#socialUrl#", this.currentPageUrl);
      tempLink = tempLink.replace("#socialTitle#", this.pageTitle);
      return tempLink;
    }
  }
}

</script>