<template>
  <MetaInfo :title="pageTitle" :description="pageDescription"></MetaInfo>

  <div class="whitelabel-layout">
    <Navigation theme="dark"></Navigation>
    <slot />

    <PPCFooter></PPCFooter>
  </div>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/WhiteLabelNav";
import PPCFooter from "@/components/PPCFooter";

export default {
  name: "WhiteLabelLayout",

  components: {
    MetaInfo,
    Navigation,
    PPCFooter,
  },

  props: {
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
};
</script>
